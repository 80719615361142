<template>
    <div style="display: content">
        <v-row no-gutters>
            <v-col>
                <v-tabs v-model="tab">
                    <v-tab
                        key="open"
                        @click="$emit('openClick')"
                    >Open</v-tab>
                    <v-tab
                        key="closed"
                        @click="$emit('closeClick')"
                    >Closed</v-tab>
                </v-tabs>
            </v-col>
            <v-col v-if="showSearch" class="d-flex align-middle justify-end pt-2 ma-2">
                <v-tooltip left open-delay="750">
                    <template v-slot:activator="{on, attrs}">
                        <v-icon 
                            @click="showSearchInput = !showSearchInput" 
                            class="pa-1 pb-1"
                            v-on="on"
                            v-bind="attrs"
                        >
                            mdi-magnify
                        </v-icon>
                    </template>
                    <span>Search Tickets</span>
                </v-tooltip>
            </v-col>
            <v-slide-x-reverse-transition>
                <v-col v-show="showSearchInput" class="mt-3 mr-2">
                    <v-text-field
                        v-model="searchQuery"
                        label="Search"
                        hide-details
                        outlined
                        dense
                        autofocus
                    ></v-text-field>
                </v-col>
            </v-slide-x-reverse-transition>
        </v-row>
        <v-tabs-items v-model="tab">
            <v-tab-item key="open">
                <slot name="open" />
            </v-tab-item>
            <v-tab-item key="closed">
                <slot name="closed" />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>
<script>
export default {
    name: "JiraIssueTabs",
    props: {
        showSearch: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            tab: 'open',
            showSearchInput: false,
            searchQuery: null
        };
    },
    computed: {
    },
    methods: {
        
    },
    watch: {
        searchQuery(v) {
            this.$emit('searchQuery', v);
        }
    }
};
</script>