<template>
    <v-card>
        <v-card-title>{{ title }}</v-card-title>
        <v-card-subtitle class="pb-0 mb-0 text-h4">{{ convertToReadableTime({ms: getTotalTime()}) || 'No Data' }}</v-card-subtitle>
        <v-card-subtitle class="pb-0 mb-0 pt-0 caption" v-if="stat.total" v-text="`${stat.total} ${stat.total === 1 ? 'request' : 'requests'}`" />
        <v-card-actions class="pa-0 ma-0">
            <v-spacer></v-spacer>

            <v-btn
                icon
                @click="show = !show"
            >
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
        </v-card-actions>
        <v-expand-transition>
            <div v-show="show">
                <v-divider></v-divider>
                <v-card-text>
                    <v-row v-for="(ob, key, i) in stat.transitions" :key="i" no-gutters class="pb-2">
                        <v-col cols="6">
                            <v-chip
                                :color="getHRStatusColor(getHRStatusTitle(key))"
                                label
                                small
                            >
                                {{ getHRStatusTitle(key) }}
                            </v-chip>
                        </v-col>
                        <v-col style="text-align: center"><v-icon>mdi-arrow-right</v-icon></v-col>
                        <v-col style="text-align: right">
                            {{ convertToReadableTime({ms: ob}) || 'No data' }}
                        </v-col>
                    </v-row>
                </v-card-text>
            </div>
        </v-expand-transition>
    </v-card>
              
</template>
<script>
import { ref } from '@vue/composition-api';
import { getHRStatusTitle, getHRStatusColor } from '@/util/common';
//import dateTimeHelper from '@/composables/common/dateTime';
export default {
    name: 'OnboardingTimeWidget',
    props: {
        title: {type: String},
        stat: {
            type: Object,
            default() { return {} }
        }
    },
    components: {},
    setup(props){
        //  Data
        const show = ref(false);
        //  Methods
        const getTotalTime = () => {
            let total = 0;
            total = Object.values(props.stat.transitions).reduce((a, b) => a + b, 0)
            return total;
        }
        return {
            show,
            getTotalTime,
            getHRStatusTitle,
            getHRStatusColor
        }
    }
};
</script>