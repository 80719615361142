<template>
    <v-main>
        <v-container>
            <v-row no-gutters>
                <v-col class="text-h4">
                    New Hires
                </v-col>
                <v-col class="d-flex justify-end align-center mr-2">
                    <NewHireDialog
                        ref="newHireDialog"
                        @pendingAtt="pendingAtt"
                    />
                </v-col>
            </v-row>
            <JiraIssueTabs
                @closeClick="loadNewHires('completed')"
                @searchQuery="searchQuery = $event"
            >
                <template v-slot:open>
                    <NewHireList
                        :set="activeNewHires"
                        :headers="newHireHeaders"
                        :loading="loadingNewHires"
                        :searchQuery="searchQuery"
                        @reloadData="$store.dispatch('population/loadNewHires')"
                        @commentAdded="$store.dispatch('population/loadNewHires')"
                        @editReq="editReq"
                    />
                </template>
                <template v-slot:closed>
                    <NewHireList
                        :set="completedNewHires"
                        :headers="newHireHeaders"
                        :loading="loadingNewHires"
                        :options="closedActionOptions"
                        :searchQuery="searchQuery"
                        @commentAdded="$store.dispatch('population/loadNewHires', {completed: true})"
                        @editReq="editReq"
                    />
                </template>
            </JiraIssueTabs>
        </v-container>
        <AttachmentConfirmationDialog 
            ref="confirmDialog"  
            v-if="showConfirmationDialog"
            v-model="showConfirmationDialog"
            @confirmGoBack="confirmGoBack"
            @confirmCancel="confirmCancel"
        />
    </v-main>
</template>
<script>
    import {mapGetters, mapActions} from 'vuex';
    import NewHireDialog from '@/components/population/newhire/NewHireDialog';
    import NewHireList from '@/components/population/newhire/NewHireList';
    import JiraIssueTabs from '@/components/population/JiraIssueTabs';
    import AttachmentConfirmationDialog from '@/components/population/attachments/AttachmentConfirmationDialog';

    export default {
        data() {
            return {
                tab: 'active',
                loadingNewHires: false,
                newHireHeaders: [
                    {text: 'Ticket #', value: 'key', width: 95},
                    {text: 'Type', value: 'issue_type_short'},
                    {text: 'Title', value: 'title'},
                    {text: 'Candidate', value: 'candidate'},
                    {text: 'Status', value: 'status'},
                    {text: 'Actions', value: 'actions', sortable: false, width: 220}
                ],
                closedActionOptions: {
                    allowCancel: false,
                    allowEdit: false
                },
                showSearch: false,
                searchQuery: null,
                showConfirmationDialog: false
            }
        },
        mounted() {
            this.loadNewHires();
        },
        computed: {
            ...mapGetters({
                activeNewHires: 'population/getActiveNewHires',
                completedNewHires: 'population/getCompletedNewHires'
            })
        },
        components: {
            NewHireDialog,
            NewHireList,
            JiraIssueTabs,
            AttachmentConfirmationDialog
        },
        methods: {
            ...mapActions({}),
            editReq(item) {
                this.$refs.newHireDialog.editReq(item);
            },
            async loadNewHires(type='open') {
                this.loadingNewHires = true;
                if (type === 'open') {
                    await this.$store.dispatch('population/loadNewHires')
                } else {
                    await this.$store.dispatch('population/loadNewHires', {completed: true})
                }
                this.loadingNewHires = false;
            },
            pendingAtt(){
                this.showConfirmationDialog = true;
            },
            confirmGoBack(){
                this.$refs.newHireDialog.dialog = true;
                this.showConfirmationDialog = false;
            },

            confirmCancel(){
                this.showConfirmationDialog = false;
                this.$refs.newHireDialog.resetDialog();
            }
        }
    }
</script>
