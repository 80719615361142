<template>
    <v-container>
        <LocationWidget
            v-model="newCandidate.workLocation"
            ref="locWidg"
            hide-details
            dense
            :userInitials="newCandidateInitials"
            @resetValidation="$emit('resetValidation')"
        >
            <template #alert="{ icon, alertType }">
                <v-alert
                    :type="alertType"
                    outlined
                    dense
                >
                    <div style="text-align: center; padding-bottom: 15px" v-if="icon">
                        <div class="overline">
                            Move the <img width="20" height="20" :src="icon" style="vertical-align: middle" /> icon on the floor plan below to the candidate's work location
                        </div>
                        <span class="caption grey--text">You may zoom in and resize the icon as needed</span>
                    </div>
                </v-alert>
            </template>
        </LocationWidget>
    </v-container>
</template>
<script>

import { computed, watch } from "@vue/composition-api";
import LocationWidget from "@/components/space/LocationWidget";

export default {
    name: "WorkLocationForm",
    components: {
        LocationWidget
    },
    setup(_, context) {
        const store = context.root.$store;

        const newCandidate = computed(() => store.getters['population/getNewCandidate']);
        const step = computed(() => store.getters['getStep']);
        const newCandidateInitials = computed(() => store.getters['population/getNewCandidateInitials']);
        const buildingList = computed(() => store.getters['population/getBuildingList']);

        
        const checkLegacy = () => {
            if ('site' in newCandidate.value.workLocation) return true;
            return false;
        }

        const convertLegacy = () => {
            const wl = newCandidate.value.workLocation;
            let ret = {}
            if (wl.site == 'cuimc') {
                ret = {
                    building: {
                        id: wl.building.id,
                        short_name: wl.building.shortName,
                    },
                    floor: wl.floor,
                    room: wl.room,
                    annotation: wl.floorPlan.annotation
                }
                ret.annotation.floor_plan = {
                    id: wl.floorPlan.id,
                    url: wl.floorPlan.url
                }
            } else {
                ret = {
                    building: {
                        id: 23,
                        name: "Remote / Other",
                        short_name: "Remote / Other"
                    }
                }
            }
            let building = buildingList.value.find(x => x.id === wl.building.id);
            ret.building.name = building.name;
            return ret;
        }
        
        watch(step, (val) => {
            if (parseInt(val) === 2 && newCandidate.value.curStep) {
                if (Object.keys(newCandidate.value.workLocation).length) {
                    if (checkLegacy()) {
                        let newData = convertLegacy();
                        store.dispatch('population/setNewCandidateWorkLocation', newData);
                    }
                    context.refs.locWidg.reloadWithLocation(newCandidate.value.workLocation);
                }
            }
        });

        return {
            newCandidate,
            newCandidateInitials,
        }
    },
}
</script>