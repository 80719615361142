<template>
    <v-form
        ref="form"
        v-model="formValid"
        lazy-validation
    >
        <v-container>
            <!-- Division -->

            <v-select
                v-model="newTermRequest.division"
                :items="authorizedDivisionList"
                item-text="name"
                label="Division"
                :rules="$rules.required"
                return-object
            ></v-select>

            <!-- UNI -->

            <v-text-field
                v-model="newTermRequest.cuUni"
                label="CU UNI"
                :rules="$rules.uni"
                append-icon="search"
                @change="searchUni"
                ref="uni"
                validate-on-blur
            ></v-text-field>

            <v-alert v-if="newTermRequest.foundInLdap === false" dense outlined type="error">
                Could not find <strong>{{ newTermRequest.uni }}</strong> in CU directory, please enter below:
            </v-alert>


            <v-row>

                <!-- First Name -->

                <v-col>
                    <v-text-field
                        v-model="newTermRequest.firstName"
                        label="First name"
                        :readonly="newTermRequest.foundInLdap"
                        :disabled="!!newTermRequest.foundInLdap"
                        :rules="$rules.required"
                        ref="fn"
                    />
                </v-col>

                <!-- Last Name -->

                <v-col>
                    <v-text-field
                        v-model="newTermRequest.lastName"
                        label="Last name"
                        :readonly="newTermRequest.foundInLdap"
                        :disabled="!!newTermRequest.foundInLdap"
                        :rules="$rules.required"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-textarea
                        v-model="newTermRequest.description"
                        label="Description"
                    />
                </v-col>
            </v-row>

            <!-- Employee Type -->
            
            <v-select
                v-model="newTermRequest.typeOfEmployee"
                :items="termTypes"
                item-text="name"
                label="Employee Type"
                :rules="$rules.required"
            ></v-select>

            <!-- Termination / Transfer Type -->

            <v-select
                v-model="newTermRequest.termTransferType"
                :items="termTransferOptions"
                :rules="$rules.required"
                label="Termination / Transfer type"
            ></v-select>

            <!-- Transfer to Division -->

            <v-select
                v-if="newTermRequest.termTransferType === 'dept'"
                v-model="newTermRequest.toDivision"
                :items="divisionList"
                item-text="name"
                label="Transfer to division"
                :rules="$rules.required"
                return-object
            />

            <!-- Transfer to Department -->

            <v-select
                v-if="newTermRequest.termTransferType === 'org'"
                v-model="newTermRequest.toDepartment"
                :items="departmentList"
                item-text="name"
                label="Transfer to department"
                :rules="$rules.required"
                return-object
            />
            
            <!-- Termination Date -->
            
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="computedDateFormatted"
                        label="Termination date"
                        prepend-icon="event"
                        :rules="$rules.required"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="newTermRequest.termDate"
                    no-title
                    @input="menu = false"
                ></v-date-picker><!-- :min="new Date().toISOString().split('T')[0]" -->
            </v-menu>

            <!-- Attachments -->

            <!-- <v-file-input
                v-model="newTermRequest.attachments.att1"
                @change="handleAttachment"
                label="Attachment"
            ></v-file-input> -->
            <AttachmentAlert
                :attachments="newTermRequest.attachments.jira"
                :pendingAttachments="newTermRequest.pendingAttachments"
                :attachmentTypes="hrTicketType.attachments"
                class="mt-4"
                ref="attAlert"
                @validityChange="validAttach = arguments[0]"
            />
            <AttachmentList
                :attachments="newTermRequest.attachments.jira"
                :item="{key: newTermRequest.editRequest}"
                :attachmentTypes="hrTicketType.attachments"
                :isValidPending="validAttach"
                @deleteAttachment="updateJiraAttachments"
                @changeAttachmentType="updateJiraAttachments"
            />
            <AttachmentInput
                :attachmentTypes="hrTicketType.attachments"
                @pendingAttachments="handlePendingAttachments"
                @validityChange="$emit('validityChange', true)"
                ref="attInput"
            />
        </v-container>
    </v-form>
</template>
<script>
import AttachmentList from '@/components/population/attachments/AttachmentList';
import AttachmentInput from '@/components/population/attachments/AttachmentInput';
import AttachmentAlert from '@/components/population/attachments/AttachmentAlert';

import { mapGetters, mapActions } from "vuex";

export default {
    name: "GeneralForm",
    data() {
        return {
            loading: false,
            formValid: true,
            // uni: '',
            menu: false,
            validAttach: true
        };
    },
    components: {
        AttachmentList,
        AttachmentInput,
        AttachmentAlert
    },
    computed: {
        ...mapGetters({
            authorizedDivisionList: 'population/getAuthorizedDivisionList',
            newTermRequest: 'population/getNewTermRequest',
            divisionList: 'population/getDivisionList',
            departmentList: 'population/getDepartmentList',
            termTypes: 'population/getTermTypes'
        }),
        computedDateFormatted() {
            return this.formatDate(this.newTermRequest.termDate);
        },
        hrTicketType() {
            if (this.newTermRequest.hrTicketType) {
                return this.newTermRequest.hrTicketType;
            }
            return {attachments: {}};
        }
    },
    methods: {
        ...mapActions({
        }),
        async searchUni() {
            this.$refs.fn.blur(); // remove focus of firstName
            await this.$store.dispatch("population/searchUni", {uni: this.newTermRequest.cuUni});
            if (this.newTermRequest.editRequest){
                await this.$store.dispatch('population/loadWorkAssets', {uni: this.newTermRequest.cuUni, key: this.newTermRequest.editRequest});
            }else{
                await this.$store.dispatch('population/loadWorkAssets', {uni: this.newTermRequest.cuUni});
            }
            this.$set(this.newTermRequest, 'cuUni', this.newTermRequest.cuUni); // hack to refresh the ui
            if (this.$refs.uni.valid) this.$emit('resetValidation');
            if (!this.newTermRequest.foundInLdap) this.$refs.fn.focus(); // focus firstName if no results
        
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${month}/${day}/${year}`;
        },
        async resetForm() {
            await this.$store.dispatch('population/resetNewTermRequest');
            this.formValid = true;
            this.validAttach = true;
            this.$refs.form.resetValidation();
            this.$refs.attInput.reset();
        },
        // handleAttachment(e) {
        //     this.$store.dispatch("population/setNewTermRequestAttachments", {
        //         att1: e
        //     });
        // }
        
        /*
        ATTACHMENT STUFF
        */
        handlePendingAttachments(payload) {
            this.newTermRequest.pendingAttachments = payload;
        },
        async updateJiraAttachments(payload) {
            this.newTermRequest.attachments.jira = payload.jiraAttachments;
            await this.$nextTick(() => {
                this.$refs.attAlert.validate();
            });
        },
    },
    watch: {
        formValid(val){
            this.$emit('validityChange', val);
        }
    }
};
</script>