<template>
    <v-form
        ref="form"
        v-model="formValid"
        lazy-validation
    >
        <v-container>
            <v-row v-for="(item, i) in newTermRequest.workAssets" :key="i">
                <v-card class="ma-1" width="100%">
                    <v-list-item>
                        <v-list-item-content class="align-self-start">
                            <v-list-item-title class="mb-2">
                                <v-icon v-if="item.type.includes('Computer')">mdi-desktop-tower-monitor</v-icon>
                                <v-icon v-else-if="item.type.includes('Printer')">mdi-printer</v-icon>
                                <v-icon v-else>mdi-label-multiple</v-icon>
                                {{ item.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ item.spec }}</v-list-item-subtitle>
                            <v-list-item-action>
                                <v-radio-group
                                    v-model="newTermRequest.workAssets[i].disposition"
                                    :rules="$rules.required"
                                    row
                                >
                                    <v-radio label="Reassign" value="reassign" />
                                    <v-radio label="Employee will keep" value="employeeKeep" />
                                    <v-radio label="Discard / Retire" value="discard" />
                                </v-radio-group>
                            </v-list-item-action>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-row>
        </v-container>
    </v-form>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "WorkAssetsForm",
    data() {
        return {
            formValid: true,
            loading: false,
        };
    },
    components: {},
    computed: {
        ...mapGetters({
            newTermRequest: "population/getNewTermRequest",
        }),
    },
    methods: {
        ...mapActions({}),
    },
    watch: {
        formValid(val){
            this.$emit('validityChange', val);
        }
    }
};
</script>