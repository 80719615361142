<template>
    <v-container>
        <div class="text-h5">Communications</div>
        <v-row>
            <v-col>
                <div class="text-h6 font-weight-light mt-2">{{ commList.suggest ? 'Suggested' : 'Selected' }} Lists</div>
                <v-treeview
                    v-model="newCandidate.onboardingTasks.commSubs"
                    selection-type="independent"
                    item-disabled="locked"
                    :items="selectedList"
                    selectable
                    return-object
                />
                <div class="text-h6 font-weight-light mt-2" style="cursor: pointer" @click="showOther = !showOther">
                    Other Lists ({{ otherLists.length }})
                    <v-btn
                        icon
                        color="grey"
                    >
                        <v-icon v-text="showOther ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
                    </v-btn>
                </div>
                <v-expand-transition>
                    <v-treeview
                        v-show="showOther"
                        v-model="otherSubs"
                        selection-type="independent"
                        :items="otherLists"
                        selectable
                        return-object
                    />
                </v-expand-transition>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api';

export default {
    name: 'CommunicationsForm',
    setup(_, { root }){
        const store = root.$store;

        //  Data
        const otherSubs = ref([]);
        const showOther = ref(false);

        //  Getters
        const newCandidate = computed(() => store.getters['population/getNewCandidate']);
        const commList = computed(() => store.getters['population/getCommList']);

        const commListStaff = computed(() => commList.value.list.find(x => x.name === 'Staff'));

        const selectedList = computed(() => {
            // Staff is ALWAYS selected and mandatory
            let selected = [{...commListStaff.value, locked: true}]

            return selected.concat(newCandidate.value.onboardingTasks.commSubs.filter(x => x.id !== commListStaff.value.id));
        });

        const otherLists = computed(() => {
            return commList.value.list.filter(c => !selectedList.value.some(c2 => c2.id === c.id));
        });

        watch(otherSubs, (val) => {
            newCandidate.value.onboardingTasks.commSubs = newCandidate.value.onboardingTasks.commSubs.concat(val)
        });

        return {
            newCandidate,
            commList,
            otherSubs,
            selectedList,
            otherLists,
            showOther,
        }
    }
    // },
    // data() {
    //     return {
    //         subs: [],
    //         defaultSubs: [{id: 1, name: 'Staff', locked: true}],
    //         defaultSubsSelected: [1]
    //     }
    // },
    // computed: {
    //     ...mapGetters({
    //         newCandidate: "population/getNewCandidate",
    //         commList: "population/getCommList"
    //     }),
    // },
    // methods: {},
    // async mounted() {
    //     await this.$store.dispatch("population/loadCommLists");
    //     this.newCandidate.onboardingTasks.commSubs = this.commList.filter(x => this.newCandidate.onboardingSetup.comms.sub.includes(x.name))
    // }
    
};
</script>