<template>
    <v-expansion-panel>
        <v-expansion-panel-header>Tickets</v-expansion-panel-header>
        <v-expansion-panel-content v-if="issueChecklist.tickets">
            <v-row v-for="(i, k) in issueChecklist.tickets" :key="k">
                <v-col class="ma-0 pt-0" cols="12">
                    <h3 class="ma-1 pb-2">{{ k }}</h3>
                    <v-data-table
                        :headers="headers"
                        :items="i"
                        class="elevation-1 ma-0 pa-0"
                        :items-per-page="5"
                    >
                        <template v-slot:item.status="{ item }">
                            <v-chip
                                :color="getHRStatusColor(item.status)"
                                label
                                small
                            >
                                {{ item.status }}
                            </v-chip>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <JiraCommentDialog :item="item" @commentAdded="$emit('reload')" />
                            <JiraAttachmentDialog :item="item" @reload="$emit('reload')" />
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-expansion-panel-content>
        <v-expansion-panel-content v-else>
            <v-alert class="mt-4" outlined type="info">
                Could not find any tickets
            </v-alert>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>

import { mapGetters } from "vuex";
import { getHRStatusColor } from '@/util/common';
import JiraCommentDialog from '@/components/population/JiraCommentDialog';
import JiraAttachmentDialog from '@/components/population/JiraAttachmentDialog';

export default {
    name: "TicketsPanel",
    components: {
        JiraCommentDialog,
        JiraAttachmentDialog
    },
    props: {
    },
    data(){
        return {
            headers: [
                {text: 'Ticket #', value: 'key', width: 95},
                {text: 'Summary', value: 'summary'},
                {text: 'Status', value: 'status', width: 175},
                {text: 'Actions', value: 'actions', sortable: false, width: 95}
            ],
        };
    },
    computed: {
        ...mapGetters({
            issueChecklist: 'population/getChecklist'
        }),
    },
    methods: {
        getHRStatusColor: getHRStatusColor
    },
    mounted(){
    }
}
</script>