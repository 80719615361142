<template>
    <v-container v-if="!loading && hasIssues">
        <v-row v-for="(values, name, i) in set" :key="i" class="mb-6">
            <v-col
                class="text-h5 grey--text text--darken-1 font-weight-light"
                v-text="name"
            />
            <v-col class="ma-0 pt-0" cols="12">
                <v-data-table
                    :headers="portalView.list_fields.headers"
                    :items="values"
                    :search="searchQuery"
                    class="elevation-1 ma-0 pa-0"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:item="props">
                        <tr :id="props.item.key === searchHighlight ? 'hlme': false" :class="{'hl-row': props.item.key === searchHighlight}">
                            <td v-for="(field, i2) in portalView.list_fields.headers" :key="i2">
                                <template v-if="field.value === 'status'">
                                    <v-tooltip 
                                        top 
                                        open-delay="750"
                                        max-width="300"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                :color="getHRStatusColor(props.item.status)"
                                                label
                                                small
                                                v-text="props.item.status"
                                            />
                                        </template>
                                        <span> {{ getStatusInfo(props.item) }} </span>
                                    </v-tooltip>
                                </template>
                                <template v-else-if="field.value === 'actions'">
                                    <PortalActions
                                        :item="props.item"
                                        :actions="portalView.list_fields.actions"
                                        :filter="filter"
                                        @viewIssue="viewIssue"
                                        @reload="$emit('reload')"
                                    />
                                </template>
                                <template v-else>
                                    {{ props.item[field.value] }}
                                </template>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else-if="!loading && !hasIssues && portalView.name">
        <v-alert class="mt-6" outlined type="info">
            Could not find any {{ portalView.name.toLowerCase() }}
        </v-alert>
    </v-container>
    <v-container style="height: 400px" v-else>
        <v-row class="fill-height text-center" align-content="center" justify="center">
            <v-col cols="5">
                <div class="text-h6 font-weight-light">Loading {{ portalName.toLowerCase() }}...</div>
                <v-progress-linear
                    indeterminate
                    rounded
                    height="6"
                ></v-progress-linear>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import {mapGetters} from 'vuex';
    import { getHRStatusColor } from '@/util/common';

    import PortalActions from '@/components/population/portal/PortalActions';

    export default {
        name: 'PortalList',
        props: {
            loading: Boolean,
            set: Object,
            searchQuery: {
                type: String,
                default: null
            },
            filter: {
                type: Object,
                default() {
                    return {}
                }
            },
            portalName: String
        },
        components: {
            PortalActions
        },
        computed: {
            ...mapGetters({
                portalView: 'populationPortalView/getPortalView',
            }),
            hasIssues() {
                return !this.isEmptyObj(this.set);
            },
            searchHighlight() {
                return this.$route.query.hl;
            },
        },
        data() {
            return {}
        },
        methods: {
            getHRStatusColor: getHRStatusColor,
            async scrollHighlight() {
                await this.$nextTick();
                await this.$nextTick();
                await this.$nextTick();
                this.$vuetify.goTo('#hlme');
            },
            viewIssue(edit, item) {
                this.$emit('viewIssue', edit, item)
            },
            getStatusInfo(item) {
                if (item.config) {
                    return item.config[item.status] ? item.config[item.status] : 'TBD'
                }
                return 'TBD'
            }
        },
        watch: {
            searchHighlight() {
                this.scrollHighlight();
            },
            set() {
                if (this.searchHighlight) this.scrollHighlight();
            }
        }
    }
</script>
<style scoped>
.hl-row {
    background-color: rgba(255, 255, 0, 0.5);
}
</style>