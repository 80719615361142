import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,{staticClass:"pt-0",attrs:{"dense":""}},[(_vm.showTitle)?_c(VSubheader,{staticClass:"overline",domProps:{"textContent":_vm._s(_vm.attachmentTitle)}}):_vm._e(),_c(VListItemGroup,[(_vm.filteredAttachments)?_vm._l((_vm.filteredAttachments),function(att,i){return _c(VListItem,{key:i,attrs:{"ripple":false}},[_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(_vm.attachmentIcon(att.filename))},on:{"dblclick":function($event){return _vm.getAttachment(att)}}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(att.filename)},on:{"dblclick":function($event){return _vm.getAttachment(att)}}})],1),_c(VListItemAction,[_c(VRow,{attrs:{"no-gutters":""}},[(_vm.loadingAttachment === att.id)?_c(VCol,[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary","size":"20","width":"2"}})],1):_c(VCol,[(_vm.attachmentTypeList.length > 1)?_c(VMenu,{key:att.id,attrs:{"absolute":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VChip,_vm._g({attrs:{"small":""}},on),[_vm._v(_vm._s(_vm.getFileAttachmentType(att.filename)))])]}}],null,true),model:{value:(att.menu),callback:function ($$v) {_vm.$set(att, "menu", $$v)},expression:"att.menu"}},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.attachmentTypeList.filter(function (x) { return x[1].attachment_name !== _vm.getFileAttachmentType(att.filename); })),function(attType,t){return _c(VListItem,{key:t,on:{"click":function($event){return _vm.changeAttachmentType(att, attType)}}},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(attType[1].attachment_name)}})],1)}),1)],1):_vm._e()],1),_c(VCol,{staticClass:"d-flex align-center"},[(_vm.config.attachmentWarning !== att.id)?_c(VIcon,{staticClass:"ml-2",attrs:{"color":"red","left":""},on:{"click":function($event){_vm.config.attachmentWarning = att.id}}},[_vm._v("mdi-close-circle")]):_vm._e(),_c('div',{class:_vm.config.attachmentWarning === att.id ? 'd-flex': 'd-none'},[_c(VBtn,{staticClass:"mr-1",attrs:{"color":"red white--text","loading":_vm.config.loading,"disabled":_vm.config.loading,"x-small":""},on:{"click":function($event){return _vm.deleteAttachment(att)}}},[_vm._v("Delete")]),_c(VBtn,{attrs:{"x-small":""},on:{"click":function($event){_vm.config.attachmentWarning = null}}},[_vm._v("Cancel")])],1)],1)],1)],1)],1)}):[_c(VListItem,[_c(VListItemContent,[_vm._v(" There are no files attached to this request ")])],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }