<template>
    <v-card v-if="stat">
        <div class="pa-3">
            <h5 class="text-uppercase text" v-text="title" />
            <v-row no-gutters>
                <v-col v-if="stat.today !== undefined">
                    <div class="text-h4">{{ stat.today }}</div>
                    <div class="overline grey--text">Today</div>
                </v-col>
                <v-col v-if="stat.week !== undefined">
                    <div class="text-h4">{{ stat.week }}</div>
                    <div class="overline grey--text">Week</div>
                </v-col>
                <v-col v-if="stat.month !== undefined">
                    <div class="text-h4">{{ stat.month }}</div>
                    <div class="overline grey--text">Month</div>
                </v-col>
                <v-col v-if="stat.total !== undefined">
                    <div class="text-h4">{{ stat.total }}</div>
                    <div class="overline grey--text">total</div>
                </v-col>
                
            </v-row>
            <template v-if="last">
                <v-divider class="my-2"></v-divider>
                <template v-if="stat.last">
                    <v-icon class="mr-2" small>mdi-clock</v-icon>
                    <span class="caption grey--text font-weight-light">{{ lastText }}  {{ getDateDistanceUnix(stat.last.date) }} ago by {{ stat.last.user }}</span>
                </template>
                <template v-else>
                    <span class="caption grey--text font-weight-light" v-text="lastNoResults" />
                </template>
            </template>
            <ApexChart
                v-if="stat.chart"
                type="bar"
                :options="chart.options"
                :series="chart.series"
            />
        </div>
    </v-card>
</template>
<script>
import { computed } from '@vue/composition-api';
import dateTimeHelper from '@/composables/common/dateTime';

export default {
    name: 'DayWeekMonthWidget',
    props: {
        title: {type: String},
        last: {type: Boolean, default: true},
        lastText: {
            type: String,
            default: 'last request'
        },
        lastNoResults: {
            type: String,
            default: 'No last request'
        },
        stat: {
            type: Object,
            default() { return {} }
        }
    },

    components: {},


    setup(props){
        //  Computed
        const chart = computed(() => {
            if (!props.stat.chart) return {};
            return {
                options: {
                    chart: {
                        toolbar: {
                            show: false
                        }
                    },
                    tooltip: {
                        enabled: false
                    },
                    xaxis: {
                        categories: props.stat.chart.categories
                    }
                },
                series: [{
                    data: props.stat.chart.series
                }]
            }
        }); 
        
        //  Methods
        const { getDateDistanceUnix } = dateTimeHelper();

        return {
            getDateDistanceUnix,
            chart
        }
    },
};
</script>