import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c(VContainer,_vm._l((_vm.newTermRequest.workAssets),function(item,i){return _c(VRow,{key:i},[_c(VCard,{staticClass:"ma-1",attrs:{"width":"100%"}},[_c(VListItem,[_c(VListItemContent,{staticClass:"align-self-start"},[_c(VListItemTitle,{staticClass:"mb-2"},[(item.type.includes('Computer'))?_c(VIcon,[_vm._v("mdi-desktop-tower-monitor")]):(item.type.includes('Printer'))?_c(VIcon,[_vm._v("mdi-printer")]):_c(VIcon,[_vm._v("mdi-label-multiple")]),_vm._v(" "+_vm._s(item.name)+" ")],1),_c(VListItemSubtitle,[_vm._v(_vm._s(item.spec))]),_c(VListItemAction,[_c(VRadioGroup,{attrs:{"rules":_vm.$rules.required,"row":""},model:{value:(_vm.newTermRequest.workAssets[i].disposition),callback:function ($$v) {_vm.$set(_vm.newTermRequest.workAssets[i], "disposition", $$v)},expression:"newTermRequest.workAssets[i].disposition"}},[_c(VRadio,{attrs:{"label":"Reassign","value":"reassign"}}),_c(VRadio,{attrs:{"label":"Employee will keep","value":"employeeKeep"}}),_c(VRadio,{attrs:{"label":"Discard / Retire","value":"discard"}})],1)],1)],1)],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }