<template>
    <div>
        <v-card
            v-if="pendingAttachments.length"
            outlined
            color="orange"
            class="mb-6"
        >
            <div
                class="orange white--text font-weight-medium pa-2"
                v-text="`${pluralize('Attachment', pendingAttachments.length)} Pending Upload`"
            />

            <v-list
                dense
            >
                <v-list-item-group>
                    <v-list-item
                        v-for="(att, i) in pendingAttachments"
                        :key="i"
                        :ripple="false"
                    >
                        <v-list-item-icon>
                            <v-icon
                                v-text="attachmentIcon(att.filename)"
                            />
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="att.filename"
                            />
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row
                                class="d-flex align-center"
                                no-gutters
                            >
                                <v-col>
                                    <v-menu
                                        v-if="attachmentTypeList.length > 1"
                                        v-model="att.menu"
                                        :key="`att${att.id}`"
                                        absolute
                                        offset-y
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                small
                                            >{{ att.type.attachment_name }}</v-chip>
                                        </template>
                                        <v-list
                                            dense
                                        >
                                            <v-list-item 
                                                v-for="(attType, t) in attachmentTypeList.filter(x => x.attachment_name !== att.type.attachment_name)" 
                                                :key="`att_t${t}`"
                                                @click="changePendingAttachmentType(att, attType)"
                                            >
                                                <v-list-item-title
                                                    v-text="attType.attachment_name"
                                                />
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                                <v-col class="d-flex align-center">
                                    <v-icon
                                        v-if="deletePendingAttachmentWarning !== att.id"
                                        class="ml-2"
                                        color="red"
                                        @click="deletePendingAttachmentWarning = att.id"
                                        left
                                    >
                                        mdi-close-circle
                                    </v-icon>
                                    <div
                                        :class="deletePendingAttachmentWarning === att.id ? 'd-flex': 'd-none'"
                                    >
                                        <v-btn
                                            color="error"
                                            class="mr-1"
                                            @click="deletePendingAttachment(att)"
                                            x-small
                                            dark
                                        >Remove</v-btn>
                                        <v-btn
                                            @click="deletePendingAttachmentWarning = null"
                                            x-small
                                        >Cancel</v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
        <v-select
            v-if="attachmentTypeList.length > 1"
            v-model="attachmentType"
            :items="attachmentTypeList"
            label="Attachment type"
            item-text="attachment_name"
            prepend-icon="mdi-paperclip"
            dense
            return-object
        />
        <v-file-input
            label="Add a new attachment"
            ref="fileInput"
            :rules="rules"
            @change="handleAttachment"
            dense
        />
    </div>
</template>
<script>
import { computed, ref, watch, onMounted, toRefs } from '@vue/composition-api';

import stringHelper from '@/composables/common/string';
import attachmentHelper from '@/composables/common/attachment';

export default {
    name: 'AttachmentInput',
    props: {
        rules: {
            type: Array,
            default() { return [] }
        },
        attachmentTypes: {
            type: Object,
            default() { return {} }
        },
        defaultAttachmentType: {
            type: String,
            default: null
        }
    },
    emits: ['pendingAttachments', 'validityChange'],
    components: {},
    setup(props, { root, emit, refs }){

        const { pluralize } = stringHelper();

        const { attachmentIcon } = attachmentHelper();
        
        // Data
        const attachmentType = ref(null);
        const pendingAttachments = ref([]);
        const deletePendingAttachmentWarning = ref(null);
        
        //  Refs
        const { defaultAttachmentType } = toRefs(props);

        //  Computed
        const attachmentTypeList = computed(()=> {
            let list = [{attachment_name: 'Uncategorized'}];
            if (Object.entries(props.attachmentTypes || {}).length) {
                list = list.concat(Object.entries(props.attachmentTypes).map(x => x[1].attachment_name ? x[1] : x))
            }
            return list;
        });

        const defaultInitialAttachmentType = computed(() => {
            const k = Object.keys(props.attachmentTypes);
            return k.length === 1 ? props.attachmentTypes[k[0]] : attachmentTypeList.value[0];
        });

        //  Methods
        const handleAttachment = (e) => {
            if (e) {
                const att = new File([e], e.name, {type: e.type});
                // TODO: if the attachment type is uncategorized and we can match it via regex, set to the
                //       appropriate attachmentType
                pendingAttachments.value.push({
                    id: `attachment${pendingAttachments.value.length}`,
                    filename: att.name,
                    file: att,
                    type: attachmentType.value
                });
                attachmentType.value = attachmentTypeList.value[0];
                refs.fileInput.reset();
                refs.fileInput.blur();
                emit('pendingAttachments', pendingAttachments.value);
                emit('validityChange');
            }
        };

        const deletePendingAttachment = (att) => {
            pendingAttachments.value = pendingAttachments.value.filter(x => x.id !== att.id);
            deletePendingAttachmentWarning.value = null;
            emit('pendingAttachments', pendingAttachments.value);
        };
        
        const changePendingAttachmentType = (att, attType) => {
            let attItem = pendingAttachments.value.find(x => x.id === att.id);
            root.$set(attItem, 'type', attType);
            emit('pendingAttachments', pendingAttachments.value);
        };
        
        const reset = () => {
            pendingAttachments.value = [];
        };
        
        //  Watch
        watch(defaultAttachmentType, (newval) => {
            if (newval) {
                attachmentType.value = attachmentTypeList.value.find(x => x.attachment_name === newval);
            }
        });
        
        //  Mounted
        onMounted(() => {
            attachmentType.value = defaultInitialAttachmentType.value;
        });
        
        return {
            attachmentType,
            pendingAttachments,
            deletePendingAttachmentWarning,
            attachmentTypeList,
            defaultInitialAttachmentType,
            handleAttachment,
            deletePendingAttachment,
            changePendingAttachmentType,
            reset,
            pluralize,
            attachmentIcon
        }

    },
};
</script>