<template>
    <v-dialog
        v-model="config.showDialog"
        max-width="900px"
        scrollable
        persistent
    >
        <template v-slot:activator="{ on: dialog, attrs }">
            <v-tooltip bottom open-delay="750">
                <template v-slot:activator="{ on: tooltip}">
                    <v-badge
                        :value="item.commentCount"
                        :content="item.commentCount"
                        class="mr-2"
                        offset-x="12"
                        offset-y="12"
                        overlap
                        style="cursor: pointer"
                        @click.native.stop="loadComments"
                    >
                        <v-icon
                            v-bind="attrs"
                            v-on="{...tooltip, ...dialog}"
                            @click="loadComments"
                        >mdi-message-text</v-icon>
                    </v-badge>
                </template>
                <span v-text="tooltipText" />
            </v-tooltip>
        </template>

        <v-card>
            <v-card-title
                class="headline"
            >
                {{ item.key }} Comments
            </v-card-title>
            <v-card-text v-if="comments.length">
                <CommentList
                    created-value="created"
                    comment-value="body"
                    :comments="comments"
                >
                    <template v-slot:author="{ comment }">
                        {{ comment.author.fullname }}
                    </template>
                </CommentList>
            </v-card-text>
            <v-card-text v-else-if="!comments.length && config.loading">
                <v-row class="fill-height text-center" align-content="center" justify="center" style="height: 200px">
                    <v-col cols="8">
                        <div class="text-h6 font-weight-light">Loading comments...</div>
                        <v-progress-linear
                            indeterminate
                            rounded
                            height="3"
                        ></v-progress-linear>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-else-if="comments === false">
                <v-row class="fill-height text-center" align-content="center" justify="center" style="height: 200px">
                    <v-col cols="8">
                        <div class="text-h6 font-weight-light">You are not authorized to view comments.</div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-else>
                <v-row class="fill-height text-center" align-content="center" justify="center" style="height: 200px">
                    <v-col cols="8">
                        <div class="text-h6 font-weight-light">There are no comments yet on this issue.</div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text class="mt-4">
                <v-form
                    v-model="config.formValid"
                    ref="form"
                    lazy-validation
                >
                    <MentionWidget
                        ref="comment"
                        :limit="7"
                    />
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="config.showDialog = false">Close</v-btn>
                <v-btn
                    color="blue darken-1"
                    :loading="config.loading"
                    :disabled="!config.formValid || config.loading"
                    @click="addComment"
                    text
                >Add Comment</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from 'axios';


import { ref, reactive, computed } from '@vue/composition-api';

import jiraHelper from '@/composables/common/jira';

import MentionWidget from '@/components/common/MentionWidget';
import CommentList from '@/components/common/CommentList';

export default {
    name: 'JiraCommentDialog',
    props: {
        item: {
            type: Object
        },
        tooltipText: {
            type: String,
            default: 'Comments'
        }
    },
    components: {
        MentionWidget,
        CommentList
    },
    emits:['commentAdded'],
    setup(props, { root, refs, emit }){
        const store = root.$store;


        //  Data
        const config = reactive({
            showDialog: false,
            loading: false,
            formValid: true,

        });

        const comments = ref([]);
        const newComment = ref('');
        
        // Getters
        const user = computed(() => store.getters['user']);
        const approverDivs = computed(() => store.getters['population/getApproverDivs']);
        
        const hasApproverPerm = computed(() => {
            return approverDivs.value.find(x => x.long_name == props.item.division) ? true : false
        })
        //  Methods
        const reset = () => {
            newComment.value = '';
        };

        const loadComments = async () => {
            reset();
            config.showDialog = true;
            config.loading = true;
            
            // check if user has permissions to view comments in portal views
            if (['leave-requests', 'compensation-requests'].includes(root.$route.params.view) && !hasApproverPerm.value) {
                if (!user.value.groups.includes(`portal-${root.$route.params.view}-comment`)) {
                    comments.value = false;
                    config.loading = false;
                    return;
                }
            }

            const response = await axios.get(`/jira/load_comments/${props.item.key}/`);

            if (response.data.error) {
                store.commit('setErrorSnack', response.data.message);
            } else {
                comments.value = response.data.comments;
            }
            config.loading = false;
        }


        const addComment = async () => {
            if (refs.form.validate()) {
                config.loading = true;

                const response = await axios.post(`/jira/add_comment/${props.item.key}/`, {
                    comment: refs.comment.getComment(),
                    issue_type: props.item.issue_type_full
                });

                if (response.data.error) {
                    store.commit('setErrorSnack', response.data.message);
                } else {
                    store.commit('setSuccessSnack', `Successfully added comment`);
                    reset();
                    emit('commentAdded', true);
                    refs.comment.clear();
                    refs.form.resetValidation();
                    config.showDialog = false;
                }
                config.loading = false;
            }
        }
        

        const { loadAttachment } = jiraHelper();

        const focusAddComment = () => {
            
            root.$nextTick(() => {
                refs.comment.focus();
            });
        }

        //  Created
        window.globalFn.loadAttachment = loadAttachment.value;

        return {
            config,
            comments,
            newComment,
            reset,
            loadComments,
            addComment,
            loadAttachment,
            focusAddComment
        }
    }
};
window.globalFn = {}
</script>