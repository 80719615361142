<template>
    <v-main>
        <v-container v-if="portalView">
            <v-row no-gutters>
                <v-col class="text-h4" v-text="portalName" />
                <v-col class="d-flex justify-end align-center mt-0 pt-0 pb-0 mt-0 mr-3">
                    <PortalCreateEditIssueDialog
                        ref="portalIssueDlg"
                        :labelSingular="viewNameSingular"
                        :showDlgButton="!loading"
                    />
                </v-col>
            </v-row>
            <JiraIssueTabs
                @openClick="reload({slug: portalView.slug})"
                @closeClick="reload({slug: portalView.slug, filter: {resolution: 'closed'}})"
                @searchQuery="searchQuery = $event"
            >
                <template v-slot:open>
                    <PortalList
                        :set="portalViewIssues.open"
                        :loading="loading || loadingIssues"
                        :searchQuery="searchQuery"
                        :filter="filter"
                        :portalName="portalName"
                        @viewIssue="viewIssue"
                        @reload="reload({slug: portalView.slug})"
                    />
                </template>
                <template v-slot:closed>
                    <PortalList
                        :set="portalViewIssues.closed"
                        :loading="loading || loadingIssues"
                        :searchQuery="searchQuery"
                        :filter="filter"
                        :portalName="portalName"
                        @viewIssue="viewIssue"
                        @reload="reload({slug: portalView.slug, filter: {resolution: 'closed'}})"
                    />
                </template>
            </JiraIssueTabs>
        </v-container>
    </v-main>
</template>
<script>
    import {mapGetters, mapActions} from 'vuex';
    import PortalList from '@/components/population/portal/PortalList';
    import PortalCreateEditIssueDialog from '@/components/population/portal/PortalCreateEditIssueDialog';
    import JiraIssueTabs from '@/components/population/JiraIssueTabs';

    export default {
        components: {
            PortalList,
            PortalCreateEditIssueDialog,
            JiraIssueTabs
        },
        computed: {
            ...mapGetters({
                portalView: 'populationPortalView/getPortalView',
                portalViewIssues: 'populationPortalView/getPortalViewIssues',
                user: 'user'
            }),
            viewNameSingular() {
                return this.portalView.name && this.portalView.name.endsWith('s') ? this.portalView.name.slice(0, -1) : this.portalView.name;
            },
            portalName() {
                return this.$route.params.view.split('-').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
            },
        },
        methods: {
            ...mapActions({}),
            async viewIssue(edit, item) {
                this.$refs.portalIssueDlg.viewIssue(edit, item);
            },
            async reload(payload) {
                this.loadingIssues = true;
                //this.$set(this, 'filter', payload.filter);
                this.filter = payload.filter;
                await this.$store.dispatch('populationPortalView/loadPortalView', payload);
                this.loadingIssues = false;
            }
        },
        data() {
            return {
                loading: true,
                loadingIssues: false,
                filter: null,
                searchQuery: null,
            }
        },
        async mounted() {
            await this.reload({slug: this.$route.params.view});

            if (!Object.keys(this.portalView).length) {
                this.$router.push({name: 'home'});
            }
            document.title = `${this.portalView.name} - Surgery Connect`;
            this.loading = false;
        }
    }
</script>