<template>
    <v-container v-if="!loading && Object.entries(set).length">
        <v-row v-for="(values, name, i) in set" :key="i" class="mb-6">
            <v-col
                class="text-h5 grey--text text--darken-1 font-weight-light"
                v-text="name"
            />
            <v-col class="ma-0 pt-0" cols="12">
                <v-data-table
                    :headers="headers"
                    :items="values"
                    :search="searchQuery"
                    class="elevation-1 ma-0 pa-0"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:item="props">
                        <tr :id="props.item.key === searchHighlight ? 'hlme': false" :class="{'hl-row': props.item.key === searchHighlight}">
                            <td>{{ props.item.key }}</td>
                            <td>{{ props.item.summary }}</td>
                            <td>
                                <v-tooltip 
                                    top 
                                    open-delay="750"
                                    max-width="300"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-chip
                                            v-on="on"
                                            :color="getHRStatusColor(props.item.status)"
                                            label
                                            small
                                            v-text="props.item.status"
                                        />
                                    </template>
                                    <span> {{ getStatusInfo(props.item) }} </span>
                                </v-tooltip>
                            </td>
                            <td>
                                <TermActions
                                    :item="props.item"
                                    :options="options"
                                    @editReq="$emit('editReq', props.item)"
                                    @commentAdded="$emit('commentAdded')"

                                />
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else-if="!loading && !Object.entries(set).length">
        <v-alert class="mt-4" outlined type="info">
        Could not find any terminations
        </v-alert>
    </v-container>
    <v-container style="height: 400px" v-else>
        <v-row class="fill-height text-center" align-content="center" justify="center">
            <v-col cols="5">
                <div class="text-h6 font-weight-light">Loading terminations...</div>
                <v-progress-linear
                    indeterminate
                    rounded
                    height="6"
                ></v-progress-linear>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import { getHRStatusColor } from '@/util/common';

import TermActions from '@/components/population/term/TermActions';

export default {
    name: 'TermList',
    props: {
        set: {
            type: Object,
            default() {
                return {}
            }
        },
        headers: Array,
        loading: Boolean,
        options: {
            type: Object,
            default() {
                return {}
            }
        },
        searchQuery: {
            type: String,
            default: null
        }
    },
    data() {
        return {
        };
    },
    components: {
        TermActions
    },
    computed: {
        ...mapGetters({
            getNewTermRequest: 'population/getNewTermRequest',
        }),
        newTermRequest: {
            get() {
                return this.getNewTermRequest;
            },
            set(payload) {
                return this.setNewTermRequest(payload);
            }
        },
        searchHighlight() {
            return this.$route.query.hl;
        }
    },
    methods: {
        ...mapActions({
            setNewTermRequest: 'population/setNewTermRequest'
        }),
        getHRStatusColor: getHRStatusColor,
        async scrollHighlight() {
            await this.$nextTick();
            await this.$nextTick();
            this.$vuetify.goTo('#hlme');
        },
        getStatusInfo(item) {
            if (item.config) {
                return item.config[item.status] ? item.config[item.status] : 'TBD'
            }
            return 'TBD'
        }
    },
    watch: {
        searchHighlight() {
            this.scrollHighlight();
        },
        set() {
            if (this.searchHighlight) this.scrollHighlight();
        }
    },
    async mounted(){
        let response = await axios.post(`/population/load_hr_ticket_type/`, {issueType: 'Termination'});
        this.newTermRequest.hrTicketType = response.data;
    }
};
</script>
<style scoped>
.hl-row {
    background-color: rgba(255, 255, 0, 0.5);
}
</style>