import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"900px","scrollable":"","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"bottom":"","open-delay":"750"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBadge,{staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"value":_vm.item.commentCount,"content":_vm.item.commentCount,"offset-x":"12","offset-y":"12","overlap":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.loadComments.apply(null, arguments)}}},[_c(VIcon,_vm._g(_vm._b({on:{"click":_vm.loadComments}},'v-icon',attrs,false),Object.assign({}, tooltip, dialog)),[_vm._v("mdi-message-text")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.tooltipText)}})])]}}]),model:{value:(_vm.config.showDialog),callback:function ($$v) {_vm.$set(_vm.config, "showDialog", $$v)},expression:"config.showDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.item.key)+" Comments ")]),(_vm.comments.length)?_c(VCardText,[_c('CommentList',{attrs:{"created-value":"created","comment-value":"body","comments":_vm.comments},scopedSlots:_vm._u([{key:"author",fn:function(ref){
var comment = ref.comment;
return [_vm._v(" "+_vm._s(comment.author.fullname)+" ")]}}],null,false,4079458030)})],1):(!_vm.comments.length && _vm.config.loading)?_c(VCardText,[_c(VRow,{staticClass:"fill-height text-center",staticStyle:{"height":"200px"},attrs:{"align-content":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"8"}},[_c('div',{staticClass:"text-h6 font-weight-light"},[_vm._v("Loading comments...")]),_c(VProgressLinear,{attrs:{"indeterminate":"","rounded":"","height":"3"}})],1)],1)],1):(_vm.comments === false)?_c(VCardText,[_c(VRow,{staticClass:"fill-height text-center",staticStyle:{"height":"200px"},attrs:{"align-content":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"8"}},[_c('div',{staticClass:"text-h6 font-weight-light"},[_vm._v("You are not authorized to view comments.")])])],1)],1):_c(VCardText,[_c(VRow,{staticClass:"fill-height text-center",staticStyle:{"height":"200px"},attrs:{"align-content":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"8"}},[_c('div',{staticClass:"text-h6 font-weight-light"},[_vm._v("There are no comments yet on this issue.")])])],1)],1),_c(VCardText,{staticClass:"mt-4"},[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.config.formValid),callback:function ($$v) {_vm.$set(_vm.config, "formValid", $$v)},expression:"config.formValid"}},[_c('MentionWidget',{ref:"comment",attrs:{"limit":7}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.config.showDialog = false}}},[_vm._v("Close")]),_c(VBtn,{attrs:{"color":"blue darken-1","loading":_vm.config.loading,"disabled":!_vm.config.formValid || _vm.config.loading,"text":""},on:{"click":_vm.addComment}},[_vm._v("Add Comment")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }