<template>
    <v-main>
        <v-container>
            <v-row>
                <v-col class="text-h4">
                    Terminations
                </v-col>
                <v-col class="d-flex justify-end align-center mt-0 pt-0 pb-0 mt-0 mr-2">
                    <NewTermDialog
                        ref="newTermDialog"
                        @pendingAtt="pendingAtt"
                    />
                </v-col>
            </v-row>
            <JiraIssueTabs
                @click="loadTerms('completed')"
                @searchQuery="searchQuery = $event"
            >
                <template v-slot:open>
                    <TermList
                        :set="activeTerms"
                        :headers="termHeaders"
                        :loading="loadingTerms"
                        :searchQuery="searchQuery"
                        @commentAdded="$store.dispatch('population/loadTerms')"
                        @editReq="editReq"
                    />
                </template>
                <template v-slot:closed>
                    <TermList
                        :set="completedTerms"
                        :headers="termHeaders"
                        :loading="loadingTerms"
                        :searchQuery="searchQuery"
                        :options="closedActionOptions"
                        @commentAdded="$store.dispatch('population/loadTerms', {completed: true})"
                    />
                </template>
            </JiraIssueTabs>
        </v-container>
        <AttachmentConfirmationDialog 
                ref="confirmDialog"  
                v-if="showConfirmationDialog"
                v-model="showConfirmationDialog"
                @confirmGoBack="confirmGoBack"
                @confirmCancel="confirmCancel"
        />
    </v-main>
</template>
<script>
    import {mapGetters, mapActions} from 'vuex';

    import { getHRStatusColor } from '@/util/common';

    import NewTermDialog from "@/components/population/term/NewTermDialog";
    import TermList from '@/components/population/term/TermList';
    import JiraIssueTabs from '@/components/population/JiraIssueTabs';
    import AttachmentConfirmationDialog from '@/components/population/attachments/AttachmentConfirmationDialog';

    export default {
        data() {
            return {
                tab: 'active',
                loadingTerms: false,
                termHeaders: [
                    {text: 'Ticket #', value: 'key', width: 95},
                    {text: 'Summary', value: 'summary'},
                    {text: 'Status', value: 'status'},
                    {text: 'Actions', value: 'actions', sortable: false}
                ],
                showSearch: false,
                searchQuery: null,
                closedActionOptions: {
                    allowCancel: false,
                    allowEdit: false
                },
                showConfirmationDialog: false
            }
        },
        async mounted() {
            this.loadTerms();
        },
        computed: {
            ...mapGetters({
                activeTerms: 'population/getActiveTerms',
                completedTerms: 'population/getCompletedTerms'
            })
        },
        components: {
            NewTermDialog,
            TermList,
            JiraIssueTabs,
            AttachmentConfirmationDialog
        },
        methods: {
            ...mapActions({}),
            hrStatusColor: getHRStatusColor,
            async loadTerms(type='open') {
                this.loadingTerms = true;
                if (type === 'open') {
                    await this.$store.dispatch('population/loadTerms')
                } else {
                    await this.$store.dispatch('population/loadTerms', {completed: true})
                }
                this.loadingTerms = false;
            },
            async editReq(item){
                this.$refs.newTermDialog.editReq(item)
            },
            pendingAtt(){
                this.showConfirmationDialog = true;
            },
            confirmGoBack(){
                this.$refs.newTermDialog.dialog = true;
                this.showConfirmationDialog = false;
            },

            confirmCancel(){
                this.showConfirmationDialog = false;
                this.$refs.newTermDialog.reset();
            }
        }
    }
</script>