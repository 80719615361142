<template>
    <div style="display: contents">
        <template v-if="item.type === 'text'">
            <v-text-field
                v-model="portalViewIssue[item.name]"
                :label="item.label"
                :rules="getRules(item)"
                :validate-on-blur="!!item.validate_on_blur"
            />
        </template>
        <template v-else-if="item.type === 'text_uni'">
            <v-text-field
                v-model="portalViewIssue[item.name]"
                :rules="$rules.uni"
                validate-on-blur
                :label="item.label"
                @change="searchUni($event)"
            />
        </template>
        <template v-else-if="item.type === 'select_division'">
            <v-select
                v-model="portalViewIssue[item.name]"
                :items="authDivList"
                item-text="name"
                :label="item.label"
                :rules="getRules(item)"
                return-object
            ></v-select>
        </template>
        <template v-else-if="item.type === 'date'">
            <v-menu
                ref="menu"
                v-model="dateMenu[item.name]"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value="formatDate(portalViewIssue[item.name])"
                        :label="item.label"
                        prepend-icon="event"
                        :rules="getRules(item)"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="portalViewIssue[item.name]"
                    no-title
                    @input="dateMenu[item.name] = false"
                ></v-date-picker>
            </v-menu>
        </template>
        <template v-else-if="item.type === 'textarea'">
            <v-textarea
                v-model="portalViewIssue[item.name]"
                :label="item.label"
                :rules="getRules(item)"
                :rows="item.rows ? item.rows : undefined"
            />
        </template>
        <template v-else-if="item.type === 'boolean_checkbox'">
            <v-checkbox
                v-model="portalViewIssue[item.name]"
                :label="item.label"
                hide-details
            ></v-checkbox>
        </template>
        <template v-else-if="item.type === 'select'">
            <v-select
                v-model="portalViewIssue[item.name]"
                :items="item.options"
                :label="item.label"
                :rules="getRules(item)"
            ></v-select>
        </template>
        <template v-else-if="item.type === 'currency'">
            <v-currency-field
                v-model="portalViewIssue[item.name]"
                :label="item.label"
                :rules="getRules(item)"
                prefix="$"
            />
        </template>
        <template v-else-if="item.type === 'attachments'">
            <AttachmentAlert
                :attachments="portalViewIssue.attachments"
                :pendingAttachments="portalViewIssue.pendingAttachments"
                :attachmentTypes="attachmentTypes"
                class="mt-4"
                ref="attAlert"
            />
            <AttachmentList
                :attachments="portalViewIssue.attachments"
                :attachmentTypes="attachmentTypes"
                :item="{key: portalViewIssue.edit_issue}"
                @deleteAttachment="updateJiraAttachments"
                @changeAttachmentType="updateJiraAttachments"
            />
            <AttachmentInput
                :attachmentTypes="attachmentTypes"
                @pendingAttachments="handlePendingAttachments"
            />
        </template>
    </div>
</template>
<script>
import axios from 'axios';
import { mapGetters, mapActions } from "vuex";
import AttachmentList from '@/components/population/attachments/AttachmentList';
import AttachmentInput from '@/components/population/attachments/AttachmentInput';
import AttachmentAlert from '@/components/population/attachments/AttachmentAlert';

export default {
    name: "PortalIssueFormItem",
    data() {
        return {
            dateMenu: {}
        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    components: {
        AttachmentList,
        AttachmentInput,
        AttachmentAlert
    },
    computed: {
        ...mapGetters({
            portalView: 'populationPortalView/getPortalView',
            portalViewIssue: 'populationPortalView/getPortalViewIssue',
            authorizedDivisionList: 'population/getAuthorizedDivisionList',
            approverDivs: 'population/getApproverDivs',
            user: 'user'
        }),
        fields() {
            return this.portalView.form_fields ? this.portalView.form_fields.fields : [];
        },
        attachmentTypes() {
            // TODO: change this to allow for different attachment rules per hr ticket type,
            //       for now set the attachment type to index 0
            return this.portalView.hr_ticket_types[0].attachments;
        },
        authDivList() {
            if (this.user.groups.includes(`portal-${this.portalView.slug}-requester`) && this.user.groups.includes(`portal-${this.portalView.slug}-create`)) {
                return this.authorizedDivisionList
            } 
            return this.approverDivs;
        }
    },
    methods: {
        ...mapActions({
        }),
        async createReq() {
            /*
            await this.$store.dispatch('population/resetNewTermRequest');
            this.$refs.genForm.resetForm();
            this.$refs.genForm.$refs.form.resetValidation();
            this.$refs.form.resetValidation();
            */
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

        reset(){
            this.dialog = false;
        },
        getRules(item) {
            let rules = [];
            if (item.required) rules.push(this.$rules.required[0]);
            const validators = item.validators;
            if (validators) {
                for (const v of validators) {
                    if (v === 'email') {
                        rules.push(this.$rules.email[1]);
                    } else if (v.lt_field && this.portalViewIssue[v.lt_field]) {
                        // determine type of target field
                        if (this.portalViewIssue[v.lt_field].match(/^\d{4}-\d{2}-\d{2}$/)) {
                            // date
                            rules.push(f => new Date(f) < new Date(this.portalViewIssue[v.lt_field]) || `Must be less than ${this.portalViewIssue[v.lt_field]}`);
                        } else {
                            // number?
                            rules.push(f => f < this.portalViewIssue[v.lt_field] || `Must be less than ${this.portalViewIssue[v.lt_field]}`)
                        }
                    } else if (v.gt_field && this.portalViewIssue[v.gt_field]) {
                        // determine type of target field
                        if (this.portalViewIssue[v.gt_field].match(/^\d{4}-\d{2}-\d{2}$/)) {
                            // date
                            rules.push(f => new Date(f) > new Date(this.portalViewIssue[v.gt_field]) || `Must be more than ${this.portalViewIssue[v.gt_field]}`);
                        } else {
                            // number?
                            rules.push(f => f > this.portalViewIssue[v.gt_field] || `Must be more than ${this.portalViewIssue[v.gt_field]}`)
                        }
                    }
                }
            }
            return rules;
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${month}/${day}/${year}`;
        },
        async searchUni(uni) {
            const response = await axios.get(`/population/search_uni/${uni}/?search_employees=true`);
            if (!response.data.noResults) {
                this.$set(this.portalViewIssue, 'first_name', response.data.firstName);
                this.$set(this.portalViewIssue, 'last_name', response.data.lastName);
            }
        },
        handlePendingAttachments(payload) {
            this.$set(this.portalViewIssue, 'pendingAttachments', payload);
        },
        async updateJiraAttachments(payload) {
            this.portalViewIssue.attachments = payload.jiraAttachments;
            await this.$nextTick(() => {
                this.$refs.attAlert.validate();
            });
        },
    }
};
</script>