import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showDlgButton)?_c(VDialog,{attrs:{"persistent":"","max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","outlined":"","disabled":!_vm.createPerm},domProps:{"textContent":_vm._s(("Create " + _vm.labelSingular))},on:{"click":_vm.createReq}},'v-btn',attrs,false),on))]}}],null,false,3532653524),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{ref:"form",attrs:{"disabled":_vm.formDisabled,"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline",domProps:{"textContent":_vm._s(_vm.title)}})]),(!_vm.loading)?_c(VCardText,{staticClass:"px-8"},_vm._l((_vm.fields),function(f,i){return _c(VRow,{key:i,attrs:{"no-gutters":""}},[(f.cols)?_vm._l((f.cols),function(f2,i2){return _c(VCol,{key:i2,class:{'mr-4' : i2 !== f.cols.length - 1 }},[_c('PortalIssueFormItem',{attrs:{"item":f2}})],1)}):[_c(VCol,[_c('PortalIssueFormItem',{attrs:{"item":f}})],1)]],2)}),1):_c(VCardText,[_c(VContainer,{staticStyle:{"height":"400px"}},[_c(VRow,{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"5"}},[_c(VProgressLinear,{attrs:{"indeterminate":"","rounded":"","height":"6"}})],1)],1)],1)],1),(!_vm.loading)?_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.reset}},[_vm._v("Close")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":"","loading":_vm.formLoading,"disabled":!_vm.formValid || _vm.formLoading || _vm.formDisabled},domProps:{"textContent":_vm._s(_vm.actionLabel)},on:{"click":function($event){$event.stopPropagation();return _vm.submit.apply(null, arguments)}}})],1):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }