<template>
    <v-dialog v-model="dialog" persistent max-width="800px" eager>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                @click="createReq"
                outlined
            >
                Create New Hire
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">{{ title }}</span>
            </v-card-title>
            <v-card-text v-if="dialogLoading">
                <v-progress-linear
                    indeterminate
                    rounded
                    height="6"
                    class="mt-4"
                ></v-progress-linear>
            </v-card-text>
            <v-card-text v-else>
                <v-alert
                    v-if="showGenericAlert"
                    dense
                    outlined
                    :type="genericAlert.type"
                    v-html="genericAlert.body"
                >
                </v-alert>
                <v-alert
                    v-if="showFinApprovalMsg"
                    dense
                    outlined
                    type="warning"
                >
                    <ul class="ml-2">
                        <li v-if="showFinApprovalMsg"><strong>This request is pending divisional approval</strong></li>
                    </ul>
                    
                </v-alert>
                <v-alert
                    v-if="showError"
                    dense
                    outlined
                    type="error"
                    :class="{'shake' : animated}"
                >
                    <ul class="ml-2">
                        <li
                            v-for="(msg, index) in invalidMessages"
                            :key="index">
                                <strong>{{ msg }}</strong>
                        </li>
                    </ul>
                </v-alert>
                <v-select
                    v-model="newHireRequest.employeeType"
                    :items="employeeTypes"
                    item-text="name"
                    label="New Hire Type"
                    @change="changeEmployeeType"
                    return-object
                    :disabled="this.newHireRequest.editRequest && this.newHireRequest.status != 'Division Review'"
                />
                <NewHireForm
                    ref="form"
                    @validityChange="changeValidity"
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cancel">Close</v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    :loading="loading"
                    :disabled="!formValid || !newHireRequest.employeeType || loading"
                    @click="submit"
                >{{ action }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";

import NewHireForm from "@/components/population/newhire/NewHireForm";

export default {
    name: "NewHireDialog",
    data() {
        return {
            dialog: false,
            loading: false,
            dialogLoading: false,
            formValid: true,
            showFinApprovalMsg: false,
            invalidMessages: [],
            showError: false,
            showGenericAlert: false,
            genericAlert: {},
            attachmentMsg: '',
            selectedAttachments: [],
            animated: false
        };
    },
    components: {
        NewHireForm
    },
    computed: {
        ...mapGetters({
            authorizedDivisionList: 'population/getAuthorizedDivisionList',
            employeeTypes: 'population/getEmployeeTypes',
            newHireRequest: 'population/getNewHireRequest'
        }),
        title() {
            if (!this.dialogLoading) {
                return this.newHireRequest.editRequest ? `Edit New Hire (${this.newHireRequest.editRequest})` : 'Create New Hire';
            } else {
                return `Loading ${this.dialogLoading.item.key}...`;
            }
        },
        action() {
            return this.newHireRequest.editRequest ? 'Update' : 'Create';
        }
    },
    methods: {
        async submit() {
            this.invalidMessages = []
            this.showError = false;
            
            const isValid = await this.$refs.form.validate();
            const validPos = await this.$refs.form.$refs.positionForm.validate();
            const validSpec = await this.$refs.form.$refs.specIndicatorsForm.validate();
            const validFin = await this.$refs.form.$refs.finForm.validate();
            const validAddressContactForm = await this.$refs.form.$refs.addressContactForm.validate();
            const validRoleActivity = await this.$refs.form.$refs.roleActivityForm.validate();
            const itForm = await this.$refs.form.$refs.itForm.validate();

            // const attachValid = await this.$refs.form.$refs.attachmentForm.validate();
            let validAttach = this.newHireRequest.validAttach = this.$refs.form.$refs.attAlert.isValidPending;
            // allow invalid attachments for creation of ANY type for now
            if (this.newHireRequest.employeeType.name !== 'UNI Request' && (!this.newHireRequest.status || this.newHireRequest.status == 'Division Review')) {
                validAttach = true;
            }
            
            const hideFields = this.$refs.form.hideFields;
            const showFinancial = this.$refs.form.showFinancial;

            let switchTab = 99;
            
            if (!validPos) {
                this.invalidMessages.push('Position Summary incomplete');
                switchTab = Math.min(switchTab, 0);
            }
            if (!validSpec) {
                this.invalidMessages.push('Special Indicators incomplete');
                switchTab = Math.min(switchTab, 1);
            }
            if (!validFin) {
                this.invalidMessages.push('Financial incomplete');
                if(!hideFields.includes('specIndicators')){
                    switchTab = Math.min(switchTab, 3);
                }else if(hideFields.includes('specIndicators')){
                    switchTab = Math.min(switchTab, 2);
                }
            }
            if (!validAttach) {
                this.invalidMessages.push('Attachments incomplete');
                if((showFinancial && hideFields.includes('specIndicators')) || 
                    (!showFinancial && !hideFields.includes('specIndicators')) ){
                    switchTab = Math.min(switchTab, 2);
                }else if(showFinancial && !hideFields.includes('specIndicators')){ 
                    switchTab = Math.min(switchTab, 3);
                }else if (!showFinancial && hideFields.includes('specIndicators')){
                    switchTab = Math.min(switchTab, 1);
                }
            }
            if (!validAddressContactForm) {
                this.invalidMessages.push('Visitor Address and Emergency Contact incomplete')
            }
            if (!validRoleActivity) {
                this.invalidMessages.push('Role and Activities incomplete')
            }
            if (!itForm) {
                this.invalidMessages.push('IT incomplete')
            }

            let validList = [isValid, validPos, validSpec, validFin, validAttach, validAddressContactForm, validRoleActivity, itForm];

            if (validList.every(x => x == true)) {
                this.loading = true;
                
                await this.$store.dispatch('population/submitNewHireRequest');
                // check if newHireRequest has been reset
                if (!this.newHireRequest.employeeType) {
                    this.resetDialog()
                }
                this.loading = false;
            } else {
                this.showError = true;
                this.formValid = false;
                this.$refs.form.tab = switchTab;
                this.animated = true;
                setTimeout(() => {
                    this.animated = false;
                }, 1000)
                
            }
        },
        changeValidity(value) {
            this.formValid = value;
        },
        changeEmployeeType(val) {
            // reset some stuff
            this.resetGenericAlert();
            this.$refs.form.resetFields();
            // check the authorized division length, setting default if length == 1
            if (this.authorizedDivisionList.length === 1 && !this.newHireRequest.division) {
                this.newHireRequest.division = this.authorizedDivisionList[0];
            }
            const fields = val.hr_ticket_type?.fields;
            if (fields) {
                if (fields.genericAlert) this.setGenericAlert(fields.genericAlert);
                if (fields.hideFields) this.$refs.form.setHideFields(fields.hideFields);
                if (fields.showFields) this.$refs.form.setShowFields(fields.showFields);
            } else {
                this.$refs.form.resetFields();
            }

        },
        cancel(){
            if (this.newHireRequest.pendingAttachments.length > 0 && this.newHireRequest.editRequest){
                this.$emit('pendingAtt')
                this.dialog = false;
                return;
            }
            this.resetDialog();
        },
        resetDialog(){
            this.dialog = false;
            this.invalidMessages = [];
            this.showError = false;
            this.formValid = true;
            this.showFinApprovalMsg = false;
            this.resetGenericAlert();
            this.selectedAttachments = [];
        },
        async editReq(item) {
            this.$refs.form.resetForm(item.needsApproval);
            this.dialogLoading = {item: item};
            this.dialog = true;
            await this.$store.dispatch('population/loadNewHireRequest', item.key);
            this.dialogLoading = false;
            this.$nextTick(() => {
                this.changeEmployeeType(this.employeeTypes.find(x => x.name === item.issue_type_short) || {name: item.issue_type});
                //this.setAttachmentMsg(item.needsAttachment)
                this.showFinApprovalMsg = !this.$refs.form.showFinancial && this.$refs.form.finNeedsApproval;
                // this.$refs.form.hasAttachment = this.$refs.form.originalAttachment = item.needsAttachment;
            });
        },
        createReq() {
            this.$refs.form.resetForm();
        },
        setGenericAlert(payload) {
            this.showGenericAlert = true;
            this.genericAlert = {...this.genericAlert, ...payload};
        },
        resetGenericAlert() {
            this.genericAlert = {}
            this.showGenericAlert = false;
        },
        setAttachmentMsg(payload){
            let needed = [];
            for(let prop in payload){
                if (prop == 'status') continue;
                let attachment_name = payload[prop].attachment_name;
                if (payload[prop].status === false){
                    if (payload[prop].required === true) {
                        needed.push(attachment_name);
                    } else {
                        let conditional = payload[prop].required.conditional;
                        if (conditional.field_name === 'status' && conditional.field_value === 'Interviewing') continue;
                        if (this.newHireRequest[conditional.field_name] === conditional.field_value) {
                            needed.push(attachment_name);
                        }
                    }
                }
            }
            this.attachmentMsg = needed;
        },
        selectAttachment(t, add) {
            if (add) {
                this.selectedAttachments.push(this.newHireRequest.employeeType.attachments[t].attachment_name);
            } else {
                this.selectedAttachments = this.selectedAttachments.filter(x => x !== this.newHireRequest.employeeType.attachments[t].attachment_name);
            }
        },
    },
    mounted() {
        this.$store.dispatch('population/loadEmployeeTypes');
    }
};
</script>