import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"headers":_vm.cmpHeaders,"items":_vm.items,"item-key":_vm.itemKey,"value":_vm.value,"show-select":_vm.showSelect,"dense":"","disable-pagination":"","hide-default-footer":""},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([(_vm.disableSelection)?{key:"header.data-table-select",fn:function(){return undefined},proxy:true}:null,{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c(VSimpleCheckbox,{attrs:{"value":isSelected,"readonly":_vm.disableSelection,"disabled":_vm.disableSelection,"ripple":false},on:{"input":function($event){return select($event)}}})]}},(_vm.divisionOptions.length)?{key:"item.division_name",fn:function(ref){
var item = ref.item;
return [_c(VRow,[_c(VCol,{staticClass:"d-flex align-center grey--text"},[_vm._v(" "+_vm._s(item.old_division_name || item.division_name)+" ")]),_c(VCol,{staticClass:"d-flex align-center",attrs:{"cols":"1"}},[_c(VIcon,[_vm._v("mdi-arrow-right")])],1),_c(VCol,[_c(VSelect,{staticClass:"mt-2 mb-2 caption font-weight-bold",attrs:{"item-text":"long_name","item-value":"id","value":0,"items":_vm.cmpDivisionOptions,"dense":"","return-object":"","hide-details":""},on:{"change":function($event){return _vm.updateDivision($event, item)}}})],1)],1)]}}:null,{key:"item.comm_groups",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"label-group"},_vm._l((item.comm_groups),function(g,i){return _c('div',{key:i,staticClass:"white--text rounded grey darken-2 pl-1 pr-1 text-uppercase",domProps:{"textContent":_vm._s(g.name)}})}),0)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }