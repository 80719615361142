import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"850px","persistent":"","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"bottom":"","open-delay":"750"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.loadChecklist(_vm.item)}}},'v-icon',attrs,false),Object.assign({}, tooltip, dialog)),[_vm._v("mdi-format-list-checkbox")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltipText))])])]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline mb-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(!_vm.loading)?_c(VCardText,[_c(VExpansionPanels,{attrs:{"multiple":""},model:{value:(_vm.panels),callback:function ($$v) {_vm.panels=$$v},expression:"panels"}},[(_vm.$route.path === '/hr/newhires')?_c('HRChecklist'):_vm._e(),_c('TicketsPanel',{on:{"reload":function($event){return _vm.loadChecklist(_vm.item)}}})],1)],1):_vm._e(),(_vm.loading)?_c(VCardText,[_c(VRow,{staticClass:"fill-height text-center",staticStyle:{"height":"200px"},attrs:{"align-content":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"8"}},[_c('div',{staticClass:"text-h6 font-weight-light"},[_vm._v("Loading checklist...")]),_c(VProgressLinear,{attrs:{"indeterminate":"","rounded":"","height":"3"}})],1)],1)],1):_vm._e(),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.reset}},[_vm._v("Close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }