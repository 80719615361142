export function getHRStatusColor(status) {
    switch (status) {
        case 'Interviewing':
            return 'primary'
        case 'Onboarding':
            return 'green lighten-2'
        case 'Canceled':
        case 'Complete':
            return 'success'
        default:
            return 'orange'
    }
}

export function getHRStatusTitle(status) {
    status = status.match(/[A-Za-z][a-z]*/g) || [];

    status = status.map(capitalize).join(' ');

    return status
}

export function capitalize(s) {
    s = s.toLowerCase().replace('cfo', 'CFO').replace('hr', 'HR').replace('psrc', 'PSRC').replace('nyp', 'NYP').replace('servcorp', 'ServCorp').replace('hrcules', 'HRCULES');
    return s.charAt(0).toUpperCase() + s.substring(1);
}