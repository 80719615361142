<template>
    <div style="display: contents">
        <template v-for="(action, i2) in actions">
            <template v-if="action.name === 'edit' && !filter.resolution">
                <v-tooltip bottom open-delay="750" :key="i2">
                    <template v-slot:activator="{on, attrs}">
                        <v-icon
                            class="mr-2"
                            @click.stop="viewOrEdit"
                            v-on="on"
                            v-bind="attrs"
                        >
                            {{ viewEditIcon }}
                        </v-icon>
                    </template>
                    <span v-text="action.tooltip" />
                </v-tooltip>
            </template>
            <template v-if="action.name === 'comments'">
                <JiraCommentDialog
                    :item="item"
                    :key="i2"
                    :tooltipText="action.tooltip"
                    @commentAdded="$emit('reload')"
                />
            </template>
            <template v-if="action.name === 'attachments'">
                <JiraAttachmentDialog
                    :item="item"
                    :key="i2"
                    :tooltipText="action.tooltip"
                    @reload="$emit('reload')"
                />
            </template>
            <template v-if="action.name === 'transitions' && (!action.allow_transitions_from || action.allow_transitions_from.includes(item.status)) && transitionPerm">
                <JiraTransitionDialog
                    :item="item"
                    :key="i2"
                    @reload="$emit('reload')"
                />
            </template>
            <template v-if="action.name === 'cancel' && !filter.resolution && cancelPerm">
                <CancelDialog
                    :item="item"
                    :key="i2"
                    :tooltipText="action.tooltip"
                    @reloadData="$emit('reload')"
                />
            </template>
        </template>
    </div>
</template>
<script>
    import JiraAttachmentDialog from '@/components/population/JiraAttachmentDialog';
    import JiraCommentDialog from '@/components/population/JiraCommentDialog';
    import JiraTransitionDialog from "@/components/population/JiraTransitionDialog";
    import CancelDialog from '@/components/population/newhire/CancelDialog';
    import { mapGetters } from "vuex";

    export default {
        name: 'PortalActions',
        props: {
            item: {
                type: Object,
                default() {
                    return {}
                }
            },
            filter: {
                type: Object,
                default() {
                    return {}
                }
            },
            actions: {
                type: Array,
                default() {
                    return []
                }
            }
        },
        components: {
            JiraAttachmentDialog,
            JiraCommentDialog,
            JiraTransitionDialog,
            CancelDialog
        },
        computed: {
            ...mapGetters({
                user: 'user',
                portalView: 'populationPortalView/getPortalView',
                approverDivs: 'population/getApproverDivs'
            }),
            slug() {
                return this.portalView.slug
            },
            userGroups() {
                return this.user.groups
            },
            cancelPerm() {
                if (this.hasApproverPerm) return true

                return this.userGroups.includes(`portal-${this.slug}-requester`) && this.userGroups.includes(`portal-${this.slug}-cancel`)
            },
            commentPerm() {
                if (this.hasApproverPerm) return true

                return this.userGroups.includes(`portal-${this.slug}-requester`) && this.userGroups.includes(`portal-${this.slug}-comment`)
            },
            transitionPerm() {
                if (this.hasApproverPerm) return true

                return this.userGroups.includes(`portal-${this.slug}-requester`) && this.userGroups.includes(`portal-${this.slug}-transition`)
            },
            editPerm() {
                if (this.hasApproverPerm) return true

                return this.userGroups.includes(`portal-${this.slug}-requester`) && this.userGroups.includes(`portal-${this.slug}-edit`)
            },
            hasApproverPerm() {
                return this.approverDivs.some(x => x.long_name == this.item.division) ? true : false
            },
            viewEditIcon() {
                return this.editPerm ? 'mdi-pencil' : 'mdi-eye'
            }
        },
        data() {
            return {}
        },
        methods: {
            viewOrEdit() {
                if (this.editPerm) this.$emit('viewIssue', true, this.item);
                else this.$emit('viewIssue', false, this.item);
            }
        },
    }
</script>