<template>
    <div>
    <v-dialog v-model="dialog" persistent max-width="800px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                v-text="title"
                @click="createReq"
                outlined
            />
        </template>
        <v-form ref="form" v-model="formValid" lazy-validation>
            <v-card>
                <v-card-title>
                    <span class="headline">{{ title }}</span>
                </v-card-title>
                <v-card-text v-if="dialogLoading">
                    <v-progress-linear
                        indeterminate
                        rounded
                        height="6"
                        class="mt-4"
                    />
                </v-card-text>
                <v-card-text v-show="!dialogLoading">
                    <v-alert
                        v-if="showError"
                        dense
                        outlined
                        type="error"
                        :class="{'shake' : animated}"
                    >
                        <ul class="ml-2">
                            <li
                                v-for="(msg, index) in errorMsgs"
                                :key="index">
                                    <strong>{{ msg }}</strong>
                            </li>
                        </ul>
                    </v-alert>
                    <v-tabs v-model="tab">
                        <v-tab key="general">General</v-tab>
                        <v-tab key="sysAccess">System Access</v-tab>
                        <v-tab key="workAssets" v-if="Object.keys(newTermRequest.workAssets).length > 0">Work Assets</v-tab>
                        <v-tab key="notes">Notes</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item key="general">
                            <GeneralForm @resetValidation="resetValidation" ref="genForm" @validityChange="changeValidity" />
                        </v-tab-item>
                        <v-tab-item key="sysAccess" eager>
                            <SysAccessForm ref="sysForm" @validityChange="changeValidity" />
                        </v-tab-item>
                        <v-tab-item v-if="Object.keys(newTermRequest.workAssets).length > 0" key="workAssets" eager>
                            <WorkAssetsForm ref="assetsForm" @validityChange="changeValidity" />
                        </v-tab-item>
                        <v-tab-item key="notes">
                            <!-- Notes -->
                            <v-textarea
                                v-model="newTermRequest.notes"
                                label="Notes"
                            ></v-textarea>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="cancel">Close</v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        :loading="loading"
                        @click.stop="showSummary"
                        :disabled="!formValid || dialogLoading || loading"
                    >
                        Continue
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
    <TermSummary 
        v-model="showSummaryDialog" 
        @cancelSummary="cancelSummary"
        @submitTerm="submitTerm"    
        v-if="showSummaryDialog"
        ref="summary"
        :action="action"
    />
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import GeneralForm from '@/components/population/term/GeneralForm';
import SysAccessForm from '@/components/population/term/SysAccessForm';
import WorkAssetsForm from '@/components/population/term/WorkAssetsForm';
import TermSummary from '@/components/population/term/TermSummary';

export default {
    name: "NewTermDialog",
    data() {
        return {
            dialog: false,
            loading: false,
            formValid: true,
            tab: null,
            showSummaryDialog: false,
            errorMsgs: [],
            showError: false,
            dialogLoading: false
        };
    },
    components: {
        GeneralForm,
        SysAccessForm,
        WorkAssetsForm,
        TermSummary
    },
    computed: {
        ...mapGetters({
            getNewTermRequest: 'population/getNewTermRequest',
        }),
        title() {
            if (!this.dialogLoading) {
                return this.newTermRequest.editRequest ? `Edit Termination (${this.newTermRequest.editRequest})` : 'Create Termination';
            } else {
                return `Loading...`
            }
        },
        action() {
            return this.newTermRequest.editRequest ? 'Update' : 'Create';
        },
        newTermRequest: {
            get() {
                return this.getNewTermRequest;
            },
            set(payload) {
                return this.setNewTermRequest(payload);
            }
        }
    },
    methods: {
        ...mapActions({
            setNewTermRequest: 'population/setNewTermRequest'
        }),
        async submitTerm() {
            // const isValid = await this.$refs.form.validate();
            // if (isValid) {
            this.$refs.summary.toggleLoading();
            await this.$store.dispatch('population/submitNewTermRequest');

            // check if newTermRequest has been reset
            if (!this.newTermRequest.cuUni) {
                this.reset();
            }
            this.$refs.summary.toggleLoading(); 
        },
        async createReq() {
            await this.$store.dispatch('population/resetNewTermRequest');
            this.$refs.genForm.resetForm();
            this.$refs.form.resetValidation();
            this.formValid = true;
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        async showSummary(){
            // const isValid = await this.$refs.form.validate();
            const validation = await this.validateForm();
            let tabNum = 99;
            this.errorMsgs = [];
            this.showError = false;
            for (let form in validation) {
                if (!validation[form].valid){
                    tabNum = Math.min(validation[form].tabNum, tabNum)
                    this.errorMsgs.push(validation[form].errorMsg)
                }
            }
            if (!this.errorMsgs.length){
                this.openSummary();
            } else {
                this.showError = true;
                this.tab = tabNum;
                this.formValid = false;
                this.animated = true;
                setTimeout(() => {
                    this.animated = false;
                }, 1000)
            }
        },
        cancelSummary(){
            this.showSummaryDialog = false;
            this.dialog = true;
        },
        openSummary(){
            this.dialog = false;
            this.showSummaryDialog = true;
        },
        cancel(){
            if(this.newTermRequest.pendingAttachments.length > 0 && this.newTermRequest.editRequest){
                this.$emit('pendingAtt');
                this.dialog = false;
                return;
            }
            this.reset()
        },
        reset(){
            this.dialog = false;
            this.showSummaryDialog = false;
            this.showError = false;
            this.tab = 0;
            this.errorMsgs = [];
            this.loading = false;
            this.dialogLoading = false;
            this.$refs.genForm.resetForm();
            this.formValid = true;
        },
        async validateForm(){
            this.loading = true;
            let validAsset = null;
            const validGen = await this.$refs.genForm.$refs.form.validate();
            const validSys = await this.$refs.sysForm.$refs.form.validate();
            validAsset = this.newTermRequest.workAssets.length > 0 ? await this.$refs.assetsForm.$refs.form.validate() : true;
            let validAttach = this.newTermRequest.validAttach = this.$refs.genForm.$refs.attAlert.isValidPending;
            // if (!this.newTermRequest.status){
            //     validAttach = true;
            // }
            const ret = {
                gen: {
                    valid: validGen,
                    tabNum: 0,
                    errorMsg: 'General incomplete'
                },
                sys: {
                    valid: validSys,
                    tabNum: 1,
                    errorMsg: 'System Access incomplete'
                },
                asset: {
                    valid: validAsset,
                    tabNum: 3,
                    errorMsg: 'Work Asset Dispostion incomplete'
                },
                attachment:{
                    valid: validAttach,
                    tabNum: 0,
                    errorMsg: 'Attachments incomplete'
                }
            }
            this.loading = false;
            return ret;
        },
        changeValidity(val){
            this.formValid = val;
        },
        async editReq(item){
            this.dialogLoading = true;
            this.dialog = true;
            await this.$store.dispatch('population/loadNewTermRequest', item.key);
            await this.$refs.genForm.searchUni();
            this.formValid = true;
            this.dialogLoading = false;
        }
    },
    mounted() {
        this.$store.dispatch('population/initTermForm');
    }
};
</script>