import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('div',{staticClass:"text-h5"},[_vm._v("Communications")]),_c(VRow,[_c(VCol,[_c('div',{staticClass:"text-h6 font-weight-light mt-2"},[_vm._v(_vm._s(_vm.commList.suggest ? 'Suggested' : 'Selected')+" Lists")]),_c(VTreeview,{attrs:{"selection-type":"independent","item-disabled":"locked","items":_vm.selectedList,"selectable":"","return-object":""},model:{value:(_vm.newCandidate.onboardingTasks.commSubs),callback:function ($$v) {_vm.$set(_vm.newCandidate.onboardingTasks, "commSubs", $$v)},expression:"newCandidate.onboardingTasks.commSubs"}}),_c('div',{staticClass:"text-h6 font-weight-light mt-2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showOther = !_vm.showOther}}},[_vm._v(" Other Lists ("+_vm._s(_vm.otherLists.length)+") "),_c(VBtn,{attrs:{"icon":"","color":"grey"}},[_c(VIcon,{domProps:{"textContent":_vm._s(_vm.showOther ? 'mdi-chevron-up' : 'mdi-chevron-down')}})],1)],1),_c(VExpandTransition,[_c(VTreeview,{directives:[{name:"show",rawName:"v-show",value:(_vm.showOther),expression:"showOther"}],attrs:{"selection-type":"independent","items":_vm.otherLists,"selectable":"","return-object":""},model:{value:(_vm.otherSubs),callback:function ($$v) {_vm.otherSubs=$$v},expression:"otherSubs"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }