<template>
    <v-dialog v-model="dialog" persistent max-width="800px" v-if="showDlgButton">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                v-text="`Create ${labelSingular}`"
                @click="createReq"
                outlined
                :disabled="!createPerm"
            />
        </template>
        <v-form 
            ref="form"
            v-model="formValid" 
            :disabled="formDisabled"
            lazy-validation
        >
            <v-card>
                <v-card-title>
                    <span class="headline" v-text="title" />
                </v-card-title>
                <v-card-text
                    v-if="!loading"
                    class="px-8"
                >
                    <v-row v-for="(f, i) in fields" :key="i" no-gutters>
                        <template v-if="f.cols">
                            <v-col v-for="(f2, i2) in f.cols" :key="i2" :class="{'mr-4' : i2 !== f.cols.length - 1 }">
                                <PortalIssueFormItem :item="f2" />
                            </v-col>
                        </template>
                        <template v-else>
                            <v-col>
                                <PortalIssueFormItem :item="f" />
                            </v-col>
                        </template>
                    </v-row>
                </v-card-text>
                <v-card-text v-else>
                    <v-container style="height: 400px">
                        <v-row class="fill-height" align-content="center" justify="center">
                            <v-col cols="5">
                                <v-progress-linear
                                    indeterminate
                                    rounded
                                    height="6"
                                ></v-progress-linear>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions v-if="!loading">
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="reset">Close</v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        :loading="formLoading"
                        @click.stop="submit"
                        :disabled="!formValid || formLoading || formDisabled"
                        v-text="actionLabel"
                    />
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapGetters, mapActions } from "vuex";
import PortalIssueFormItem from '@/components/population/portal/PortalIssueFormItem';

export default {
    name: "PortalCreateEditIssueDialog",
    data() {
        return {
            dialog: false,
            loading: false,
            formLoading: false,
            formValid: true,
            errorMsgs: [],
            showError: false,
            formDisabled: false,
        };
    },
    props: {
        labelSingular: String,
        showDlgButton: {
            type: Boolean,
            default: false
        }
    },
    components: {
        PortalIssueFormItem
    },
    computed: {
        ...mapGetters({
            portalView: 'populationPortalView/getPortalView',
            portalViewIssue: 'populationPortalView/getPortalViewIssue',
            authorizedDivisionList: 'population/getAuthorizedDivisionList',
            user: 'user',
            approverDivs: 'population/getApproverDivs'
        }),
        title() {
            if (this.loading) {
                return `Loading ${this.labelSingular}...`;
            } else {
                return this.portalViewIssue.edit_issue ? `Edit ${this.labelSingular} (${this.portalViewIssue.edit_issue})` : `Create ${this.labelSingular}`;
            }
        },
        actionLabel() {
            return !this.portalViewIssue.edit_issue ? 'Create': 'Update';
        },
        fields() {
            return this.portalView.form_fields ? this.portalView.form_fields.fields : [];
        },
        userGroups() {
            return this.user.groups;
        },
        slug() {
            return this.portalView.slug
        },
        createPerm() {
            if (this.hasApproverPerm) return true

            return this.userGroups.includes(`portal-${this.slug}-requester`) && this.userGroups.includes(`portal-${this.slug}-create`)
        },
        hasApproverPerm() {
            if (this.approverDivs.length !== 0) return true
            
            return false
        }
    },
    methods: {
        ...mapActions({
        }),
        async createReq() {
            if (this.$refs.form) {
                this.$refs.form.reset();
                this.formDisabled = false;
            }
            await this.$store.dispatch('populationPortalView/resetPortalViewIssue');
        },

        reset(){
            this.dialog = false;
            this.disableForm = false;
        },

        async searchUni(uni) {
            const response = await axios.get(`/population/search_uni/${uni}/?search_employees=true`);
            if (!response.data.noResults) {
                this.$set(this.portalViewIssue, 'first_name', response.data.firstName);
                this.$set(this.portalViewIssue, 'last_name', response.data.lastName);
            }
        },

        async submit() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                this.formLoading = true;
                await this.$store.dispatch('populationPortalView/submitPortalIssue');
                // check if portal issue has been reset
                if (!this.portalViewIssue.division) {
                    this.dialog = false;
                }
                this.formLoading = false;
            }
        },

        // async editIssue(item) {
        //     this.dialog = true;
        //     this.loading = true;
        //     await this.$store.dispatch('populationPortalView/loadPortalViewIssue', {key: item.key, slug: this.portalView.slug});
        //     this.loading = false;
        // },
        
        async viewIssue(edit, item) {
            this.dialog = true;
            this.loading = true;
            if (!edit) this.formDisabled = true;
            await this.$store.dispatch('populationPortalView/loadPortalViewIssue', {key: item.key, slug: this.portalView.slug});
            this.loading = false;
        }
    },
    async mounted() {
        await this.$store.dispatch('population/loadAuthorizedDivisionList');
    }
};
</script>