<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="350"
  >
    <v-card>
      <v-card-title class="headline">
        Are you sure?
      </v-card-title>
      <v-card-text>You have attachments that are pending upload. Are you sure you want to cancel?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          text
          @click="$emit('confirmGoBack')"
        >
          Go back
        </v-btn>
        <v-btn
          color="red darken-1"
          text
          @click="$emit('confirmCancel')"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import { mapGetters, mapActions } from "vuex";

export default {
    name: "ConfirmationDialog",
    props: {
      value: Boolean,
    },
    data() {
      return {
        dialog: false
      };
    },
    computed: {
      ...mapGetters({}),
      show: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit('confirmCancel', value);
        }
      }
    },
    methods: {
        ...mapActions({}),
    },
};
</script>