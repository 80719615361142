<template>
    <div style="display: contents">
        <v-tooltip bottom open-delay="750">
            <template v-slot:activator="{on, attrs}">
                <v-badge
                    :value="!item.termUser"
                    color="orange"
                    icon="mdi-exclamation-thick"
                    offset-x="22"
                    offset-y="13"
                    style="cursor: pointer"
                    bordered
                    v-if="options.allowEdit !== false"
                    @click.native.stop="$emit('editReq')"
                >
                    <v-icon
                        class="mr-2"
                        v-on="on"
                        v-bind="attrs"
                        @click.stop="$emit('editReq')"
                        
                    >
                        mdi-pencil
                    </v-icon>
                </v-badge>
            </template>
            <span v-if="!item.termUser">Edit (Incomplete)</span>
            <span v-else>Edit</span>
        </v-tooltip>
        <JiraCommentDialog :item="item" @commentAdded="$emit('commentAdded')" />
        <JiraAttachmentDialog :item="item" @reload="$store.dispatch('population/loadTerms')" />
        <ChecklistDialog 
            v-if="item.status === 'Pending Termination'" 
            :item="item" 
            type="term" 
        />
    </div>
</template>
<script>
import JiraCommentDialog from '@/components/population/JiraCommentDialog';
import JiraAttachmentDialog from '@/components/population/JiraAttachmentDialog';
import ChecklistDialog from '@/components/population/checklist/ChecklistDialog';

export default {
    name: 'TermActions',
    props: {
        item: {
            type: Object,
            default() {
                return {}
            }
        },
        options: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {};
    },
    components: {
        JiraCommentDialog,
        JiraAttachmentDialog,
        ChecklistDialog
    },
    computed: {},
    methods: {},
};
</script>