import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"bottom":"","open-delay":"750"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.loadTransitions(_vm.item)}}},'v-icon',attrs,false),Object.assign({}, tooltip, dialog)),[_vm._v("mdi-arrow-right-circle")])]}}],null,true)},[_c('span',[_vm._v("Transition ticket")])])]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[(_vm.nextTransition)?_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Transition: "+_vm._s(_vm.nextTransition.name))]),_c(VCardText,[_vm._v(" Are you sure you want to transition "+_vm._s(_vm.item.key)+" to "),_c('strong',[_vm._v(_vm._s(_vm.nextTransition.to.name))]),_vm._v("? ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("Close")]),_c(VBtn,{attrs:{"color":"blue darken-1","loading":_vm.loading,"disabled":_vm.loading,"text":""},on:{"click":function($event){return _vm.transitionReq(_vm.item, _vm.nextTransition)}}},[_vm._v("Transition")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }