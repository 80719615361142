import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"350"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" Are you sure? ")]),_c(VCardText,[_vm._v("You have attachments that are pending upload. Are you sure you want to cancel?")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.$emit('confirmGoBack')}}},[_vm._v(" Go back ")]),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.$emit('confirmCancel')}}},[_vm._v(" Cancel ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }