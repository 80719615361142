<template>
    <v-main>
        <v-container align-content-center>
            <v-row>
                <v-col>
                    <div class="text-h4">New Candidate</div>
                    <div class="text-h6 font-weight-light">{{ newCandidate.title }} in {{ stripDivNum(newCandidate.division.name) }}</div>
                </v-col>
                <v-col 
                    v-if="autoSaving"
                    class="d-flex align-center justify-center"
                >
                    <v-progress-circular indeterminate :width="3" color="primary"></v-progress-circular>
                </v-col>
                <v-col class="d-flex align-center justify-end">
                    <v-chip
                        :color="getHRStatusColor(newCandidate.status)"
                        label
                        large
                        outlined
                        v-text="newCandidate.status"
                    />
                </v-col>
            </v-row>
        </v-container>
        
        <v-stepper
            :non-linear="editableSteps"
            alt-label
            v-model="step"
        >
            <v-stepper-header>
                <v-stepper-step
                    :complete="step > 1 || (newCandidate.status !== 'Interviewing' && !(newCandidate.status === 'HR Review' && ['Faculty', 'Fellow'].indexOf(newCandidate.hireType) > -1))"
                    step="1"
                    :editable="editableSteps"
                    edit-icon="$complete"
                >Candidate Info</v-stepper-step>
                <v-divider />
                <v-stepper-step
                    :complete="step > 2"
                    step="2"
                    :editable="editableSteps"
                    edit-icon="$complete"
                >Work Location</v-stepper-step>
                <v-divider />
                <v-stepper-step
                    :complete="step > 3"
                    step="3"
                    :editable="editableSteps"
                    edit-icon="$complete"
                >Onboarding</v-stepper-step>
                <v-divider />
                <v-stepper-step
                    :complete="step > 4"
                    step="4"
                    :editable="editableSteps"
                    edit-icon="$complete"
                >Communications</v-stepper-step>
                <v-divider />
                <v-stepper-step
                    step="5"
                    :editable="editableSteps"
                >Summary</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-form ref="form" v-model="formValid" lazy-validation>
                    <v-stepper-content step="1">
                        <v-container v-if="newCandidate.status"><!-- <<< hack to prevent form from showing before data is loaded -->
                            <v-form ref="canInfoForm" v-model="canInfoFormValid" lazy-validation>
                                <v-alert
                                    v-if="newCandidate.status === 'Interviewing' && !isDivisionApprover"
                                    outlined
                                    type="warning">
                                    Only an approver can submit a new candidate to Finance / HR
                                </v-alert>
                                <CandidateInfoForm
                                    ref="candidateInfoForm"
                                    :show-financial="showFinancial"
                                    @changeEditMode="canInfoEditMode = !canInfoEditMode"
                                    @changeAutoSaving="autoSaving = !autoSaving"
                                    :initialLoad="loadingInitialData"
                                    :isValid="canInfoFormValid"
                                />
                                <v-alert v-if="showStepOneHelpMsg" type="success" class="ma-3" outlined>
                                    Candidate ({{ newCandidate.firstName }} {{ newCandidate.lastName }}) has been submitted to
                                    <v-chip
                                        :color="getHRStatusColor(newCandidate.status)"
                                        label
                                        small
                                        v-text="newCandidate.status"
                                    />
                                    and you may continue with new candidate workflow below:
                                </v-alert>
                                <v-row>
                                    <v-col class="text-center">
                                        <v-btn
                                            v-if="newCandidate.status === 'Interviewing' && isDivisionApprover"
                                            class="mr-3"
                                            color="primary"
                                            :disabled="!canInfoFormValid || !isDivisionApprover"
                                            :loading="loading"
                                            @click="submitStepOne"
                                        >Submit to Finance / HR</v-btn>
                                        <v-btn
                                            v-else-if="newCandidate.status === 'Interviewing'"
                                            class="mr-3"
                                            color="primary"
                                            :disabled="!canInfoFormValid"
                                            :loading="loading"
                                            @click="submitStepOne(true)"
                                        >Save Candidate Information</v-btn>
                                        <v-btn
                                            v-if="canInfoEditMode"
                                            class="mr-3"
                                            :disabled="!formValid"
                                            :loading="loading"
                                            @click="submitStepOne(true)"
                                        >Save Changes</v-btn>
                                        <v-btn
                                            v-if="newCandidate.status !== 'Interviewing' && !canInfoEditMode"
                                            color="primary"
                                            :loading="loading"
                                            @click="$store.dispatch('setNextStep')"
                                        >Continue</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                        <v-container>
                            <WorkLocationForm
                                ref="workLocationForm"
                                @resetValidation="$refs.form.resetValidation()"
                            />
                            <StepperButtons
                                :disabled="!formValid"
                                :validateForm="validateSaveForm"
                                :alt-click="preOnboarding"
                            />
                        </v-container>
                    </v-stepper-content>
                    <v-stepper-content step="3">
                        <v-container>
                            <OnboardingForm />
                            <StepperButtons
                                :disabled="!formValid"
                                :validateForm="validateSaveForm"
                            />
                        </v-container>
                    </v-stepper-content>
                    <v-stepper-content step="4">
                        <v-container>
                            <CommunicationsForm />
                            <StepperButtons
                                :disabled="!formValid"
                                :validateForm="validateSaveForm"
                            />
                        </v-container>
                    </v-stepper-content>
                    <v-stepper-content step="5">
                        <v-container>
                            <CandidateSummary />
                            <StepperButtons :show-next="false">
                                <template v-slot:right v-if="showFinancial">
                                    <v-btn
                                        color="primary"
                                        @click="saveNewCandidate()"
                                        :disabled="loading"
                                        :loading="loading"
                                    >Save</v-btn>
                                </template>
                            </StepperButtons>
                        </v-container>
                    </v-stepper-content>
                </v-form>
            </v-stepper-items>
        </v-stepper>
        <v-overlay :value="loadingInitialData">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <RecruitTypeDialog 
            ref="recruitTypeDialog"  
            v-if="showRecruitTypeDialog"
            v-model="showRecruitTypeDialog"
            @setRecruitType="setRecruitType"
        />
    </v-main>
</template>
<script>
import axios from 'axios';
import { mapGetters, mapActions } from "vuex";

import { getHRStatusColor } from "@/util/common";


import CandidateInfoForm from "@/components/population/new_candidate/CandidateInfoForm";
import WorkLocationForm from "@/components/population/new_candidate/WorkLocationForm";
import OnboardingForm from "@/components/population/new_candidate/OnboardingForm";
import CommunicationsForm from "@/components/population/new_candidate/CommunicationsForm";
import CandidateSummary from "@/components/population/new_candidate/CandidateSummary";
import StepperButtons from "@/components/StepperButtons";
import RecruitTypeDialog from "@/components/population/new_candidate/RecruitTypeDialog";

export default {
    name: "NewCandidate",
    components: {
        CommunicationsForm,
        WorkLocationForm,
        CandidateInfoForm,
        OnboardingForm,
        CandidateSummary,
        StepperButtons,
        RecruitTypeDialog
    },
    data() {
        return {
            canInfoFormValid: true,
            formValid: true,
            loading: false,
            loadingInitialData: true,
            canInfoEditMode: false,
            showStepOneHelpMsg: false,
            showRecruitTypeDialog: false,
            autoSaving: false,
        };
    },
    computed: {
        ...mapGetters({
            getStep: "getStep",
            newCandidate: "population/getNewCandidate",
            workLocation: 'population/getWorkLocation',
            divPerms: 'population/getDivPerms'
        }),
        editableSteps() {
            return this.newCandidate.curStep === 5 || this.step === 5;
        },
        step: {
            get() {
                return this.getStep;
            },
            set(n) {
                this.setStep(n);
            } 
        },
        isHiringManager() {
            if (this.newCandidate.isHiringManager) {
                return true;
            }
            return false;
        },
        isDivisionApprover() {
            if (this.newCandidate.division) {
                return this.divPerms.approvers.some(x => x.id === this.newCandidate.division.id);
            }
            return false;
        },
        showFinancial() {
            if (this.isDivisionApprover || this.isHiringManager) {
                return true;
            }

            return false;
        }
    },
    watch: {
        'newCandidate.workLocation.annotation.floor_plan'() {
            if (!this.formValid && this.newCandidate.workLocation.annotation && this.newCandidate.workLocation.annotation.floor_plan) {
                this.validateSaveForm(false); // re-validate form
            }
        },
        // 'newCandidate.workLocation.building.name'(val) {
        //     if (val === 'Remote / Other') {
        //         this.$store.dispatch('population/resetNewCandidateWorkLocation', {site: 'other'});
        //     }
        // }
    },
    methods: {
        ...mapActions(['setStep']),
        getHRStatusColor: getHRStatusColor,
        validateSaveForm(saveToDb=true) {
            // hijack validation to ensure there is some movement of annotation on floor plan
            // TODO: figure out how to run $refs.form.validate() before this to handle normal form items first
            // if (
            //     this.step === 2 &&
            //     this.newCandidate.workLocation.building.name !== 'Remote / Other' &&
            //     this.newCandidate.workLocation.annotation.floor_plan &&
            //     !this.newCandidate.workLocation.annotation.bbox
            // ) 
            if (
                this.step === 2 &&
                this.newCandidate.workLocation.building.name !== 'Remote / Other' &&
                !this.newCandidate.workLocation.annotation
            ) 
            {
                this.formValid = false;
                if (!this.$refs.workLocationForm.$refs.locWidg.validate()) {
                    this.$store.commit('setErrorSnack', 'Candidate work location must be updated on the floor plan');
                    return false;
                }
            }

            const isValid = this.$refs.form.validate();

            if (isValid && saveToDb) {
                this.$store.dispatch('population/newCandidateSaveStep');
            }
            
            return isValid;
        },

        async preOnboarding() {
            // reset validation
            this.$refs.form.resetValidation();
            if (this.newCandidate.workLocation.site !== "other") {
                await this.$store.dispatch('population/loadEndpointsInLocation', {
                    buildingId: this.newCandidate.workLocation.building.id,
                    floor: this.newCandidate.workLocation.floor,
                    room: this.newCandidate.workLocation.room,
                });
            }
        },

        async submitStepOne(editsOnly=false) {
            if (this.$refs.candidateInfoForm.timeout) clearTimeout(this.$refs.candidateInfoForm.timeout);

            const isValid = this.$refs.canInfoForm.validate();

            if (isValid) {
                this.loading = true;
                if (typeof editsOnly === 'boolean') this.newCandidate.editsOnly = editsOnly;

                const prevStatus = this.newCandidate.status;

                const hasPendingAttachments = this.newCandidate.pendingAttachments;

                if (this.newCandidate.recruitSrc !== 'ext' && !this.newCandidate.recruitType) {
                    let url = `/population/get_existing_employee/`
                    let params = {
                        first_name: this.newCandidate.firstName,
                        last_name: this.newCandidate.lastName,
                    }

                    if (this.newCandidate.middleName) params.middle_name = this.newCandidate.middleName
                    let request = await axios.get(url, { params });

                    if (request.data.error) {
                        this.$store.commit('setErrorSnack', `${request.data.message}`);
                        this.loading = false;
                        return;
                    }

                    if (request.data.existing) {
                        this.showRecruitTypeDialog = true;
                        return;
                    }
                }

                if (!this.newCandidate.startingSalary) {
                    this.newCandidate.startingSalary = 0;
                }
                
                await this.$store.dispatch("population/submitNewCandidate");

                if (this.newCandidate.saved) {
                    // the save was successful
                    if (prevStatus !== this.newCandidate.status) {
                        // show additional help messages
                        this.showStepOneHelpMsg = true;
                    }

                    if (hasPendingAttachments != this.newCandidate.pendingAttachments) {
                        this.$refs.candidateInfoForm.attachmentsUploaded();
                    }

                    if (this.canInfoEditMode) {
                        this.$refs.candidateInfoForm.changeEditMode();
                    }
                }
                this.loading = false;
            }
        },
        async saveNewCandidate() {
            this.loading = true;
            await this.$store.dispatch('population/newCandidateSaveStep', {finishSave: true});
            if (this.newCandidate.finishSave) {
                this.$store.commit('setSuccessSnack', `Successfully saved candidate for ${this.newCandidate.jiraKey}`);
                this.$router.push({'name': 'newHires'})
            }
            this.loading = false;
        },
        setRecruitType(payload) {
            this.$store.dispatch('population/setNewCandidateRecruitType', payload);
            this.showRecruitTypeDialog = false;
            this.submitStepOne(this.canInfoEditMode)
        }
    },
    async mounted() {
        await this.$store.dispatch(
            "population/loadNewCandidate", {jiraKey: this.$route.params.jiraKey, maxSteps: 5}
        );
        // try to load endpoints in room if empty on mount() and curStep > 2
        if (this.newCandidate.curStep > 5) this.newCandidate.curStep = 5;
        if (this.newCandidate.curStep > 2 && !Object.keys(this.workLocation.endpointsInRoom).length) {
            this.preOnboarding();
        }
        if ((this.newCandidate.status === 'HR Review' && ['Faculty', 'Fellow'].indexOf(this.newCandidate.hireType) > -1) || 
            (this.newCandidate.status === 'HR Clearance' && this.newCandidate.hireType == 'Resident')
        ) {
            if (!this.newCandidate.curStep) {
                this.canInfoEditMode = true;
                this.$refs.candidateInfoForm.editMode = true;
            }
        }
        // reset any validation errors
        this.$refs.form.resetValidation();
        this.$refs.canInfoForm.resetValidation();
        if (!this.divPerms.approvers.length && !this.divPerms.requesters.length) {
            await this.$store.dispatch('population/loadAuthorizedDivisionList');
        }
        this.loadingInitialData = false;
    },
    beforeDestroy() {
        this.$store.dispatch('population/resetNewCandidate')
    }
};
</script>