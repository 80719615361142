import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.title))]),_c(VCardSubtitle,{staticClass:"pb-0 mb-0 text-h4"},[_vm._v(_vm._s(_vm.convertToReadableTime({ms: _vm.getTotalTime()}) || 'No Data'))]),(_vm.stat.total)?_c(VCardSubtitle,{staticClass:"pb-0 mb-0 pt-0 caption",domProps:{"textContent":_vm._s(((_vm.stat.total) + " " + (_vm.stat.total === 1 ? 'request' : 'requests')))}}):_vm._e(),_c(VCardActions,{staticClass:"pa-0 ma-0"},[_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c(VIcon,[_vm._v(_vm._s(_vm.show ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)],1),_c(VExpandTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c(VDivider),_c(VCardText,_vm._l((_vm.stat.transitions),function(ob,key,i){return _c(VRow,{key:i,staticClass:"pb-2",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VChip,{attrs:{"color":_vm.getHRStatusColor(_vm.getHRStatusTitle(key)),"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.getHRStatusTitle(key))+" ")])],1),_c(VCol,{staticStyle:{"text-align":"center"}},[_c(VIcon,[_vm._v("mdi-arrow-right")])],1),_c(VCol,{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.convertToReadableTime({ms: ob}) || 'No data')+" ")])],1)}),1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }