import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"contents"}},[_c(VTooltip,{attrs:{"bottom":"","open-delay":"750"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.options.allowEdit !== false)?_c(VBadge,{staticStyle:{"cursor":"pointer"},attrs:{"value":_vm.item.needsApproval,"color":"orange","icon":"mdi-exclamation-thick","offset-x":"22","offset-y":"13","bordered":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('editReq')}}},[(_vm.options.allowEdit !== false)?_c(VIcon,_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('editReq')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()],1):_vm._e()]}}])},[(_vm.item.needsApproval)?_c('span',[_vm._v("Edit (Pending Approval)")]):_c('span',[_vm._v("Edit")])]),_c('JiraCommentDialog',{attrs:{"item":_vm.item},on:{"commentAdded":function($event){return _vm.$emit('commentAdded')}}}),_c('JiraAttachmentDialog',{attrs:{"item":_vm.item},on:{"reload":function($event){return _vm.$store.dispatch('population/loadNewHires')}}}),_c('TimelineDialog',{attrs:{"item":_vm.item}}),((_vm.item.status === 'Division Review' || _vm.item.status === 'Open') && !_vm.item.needsApproval && _vm.item.meta.validAttach)?_c('TransitionDialog',{attrs:{"item":_vm.item}}):_vm._e(),(_vm.item.status === 'Onboarding')?_c('ChecklistDialog',{attrs:{"item":_vm.item,"type":"hr"}}):_vm._e(),(_vm.isPreOnboardingStatus)?_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{ name: 'newCandidate', params: {'jiraKey': _vm.item.key}}}},[_c(VTooltip,{attrs:{"bottom":"","open-delay":"750"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.item.candidateInfo.hasCandidate)?_c(VBadge,{staticStyle:{"cursor":"pointer"},attrs:{"value":_vm.candidateNeedsApproval,"color":"orange","icon":"mdi-exclamation-thick","offset-x":"20","offset-y":"13","bordered":""}},[_c(VIcon,_vm._g(_vm._b({staticClass:"mr-2"},'v-icon',attrs,false),on),[_vm._v(" mdi-account-arrow-right ")])],1):(_vm.hasNoCandidate)?_c(VBadge,{staticStyle:{"cursor":"pointer"},attrs:{"value":_vm.hasNoCandidate,"color":"blue darken-1","icon":"mdi-plus","offset-x":"20","offset-y":"13","bordered":""}},[_c(VIcon,_vm._g(_vm._b({staticClass:"mr-2"},'v-icon',attrs,false),on),[_vm._v(" mdi-account-arrow-right ")])],1):_c(VIcon,_vm._g(_vm._b({staticClass:"mr-2"},'v-icon',attrs,false),on),[_vm._v(" mdi-account-arrow-right ")])]}}],null,false,131755200)},[(_vm.hasNoCandidate)?_c('span',[_vm._v("Onboarding Form (Incomplete)")]):(_vm.candidateNeedsApproval)?_c('span',[_vm._v("Onboarding Form (Needs Approval)")]):_c('span',[_vm._v("Onboarding form")])])],1):_vm._e(),(_vm.options.allowCancel !== false)?_c('CancelDialog',{attrs:{"item":_vm.item},on:{"reloadData":function($event){return _vm.$emit('reloadData')}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }