import { VAlert } from 'vuetify/lib/components/VAlert';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading && Object.entries(_vm.set).length)?_c(VContainer,_vm._l((_vm.set),function(values,name,i){return _c(VRow,{key:i,staticClass:"mb-6"},[_c(VCol,{staticClass:"text-h5 grey--text text--darken-1 font-weight-light",domProps:{"textContent":_vm._s(_vm.divNameOnly(name))}}),_c(VCol,{staticClass:"d-flex align-center",attrs:{"cols":"auto"}},[_c(VChip,{attrs:{"color":"grey","small":"","outlined":""},domProps:{"textContent":_vm._s(_vm.divPerms.requesters.some(function (x) { return name.includes(x.long_name); }) ? 'Requester' : 'Approver')}})],1),_c(VCol,{staticClass:"ma-0 pt-0",attrs:{"cols":"12"}},[_c(VDataTable,{staticClass:"elevation-1 ma-0 pa-0",attrs:{"headers":_vm.headers,"items":values,"search":_vm.searchQuery,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{class:{'hl-row': props.item.key === _vm.searchHighlight},attrs:{"id":props.item.key === _vm.searchHighlight ? 'hlme': false}},[_c('td',[_vm._v(_vm._s(props.item.key))]),_c('td',[_vm._v(_vm._s(props.item.issue_type))]),_c('td',[_vm._v(_vm._s(props.item.title))]),_c('td',[_vm._v(_vm._s(props.item.candidate.join(' ')))]),_c('td',[_c(VTooltip,{attrs:{"top":"","open-delay":"750","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VChip,_vm._g({attrs:{"color":_vm.getHRStatusColor(props.item.status),"label":"","small":""},domProps:{"textContent":_vm._s(props.item.status)}},on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getStatusInfo(props.item))+" ")])])],1),_c('td',[_c('NewHireActions',{attrs:{"options":_vm.options,"item":props.item},on:{"editReq":function($event){return _vm.$emit('editReq', props.item)},"commentAdded":function($event){return _vm.$emit('commentAdded')},"reloadData":function($event){return _vm.$emit('reloadData')}}})],1)])]}}],null,true)})],1)],1)}),1):(!_vm.loading && !Object.entries(_vm.set).length)?_c(VContainer,[_c(VAlert,{staticClass:"mt-4",attrs:{"outlined":"","type":"info"}},[_vm._v(" Could not find any new hires ")])],1):_c(VContainer,{staticStyle:{"height":"400px"}},[_c(VRow,{staticClass:"fill-height text-center",attrs:{"align-content":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"5"}},[_c('div',{staticClass:"text-h6 font-weight-light"},[_vm._v("Loading new hires...")]),_c(VProgressLinear,{attrs:{"indeterminate":"","rounded":"","height":"6"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }