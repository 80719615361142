<template>
    <v-expansion-panel>
        <v-expansion-panel-header> 
            <div>
                <v-icon v-if="issueChecklist.hr_completed" left color="green">mdi-check-circle-outline</v-icon> Core Checklist
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-row v-for="i in issueChecklist.checklist" :key="i.name">
                <v-col cols="1">
                    <v-checkbox
                        v-model="i.value"
                        class="defCursor ml-3 mt-0 pt-0"
                        color="success"
                        :ripple="false"
                        :disabled="!i.value"
                        hide-details
                        readonly
                    />
                </v-col>
                <v-col class="ma-0 pb-0">
                    <h3 :class="`${!i.value ? 'grey--text' : ''}`">{{ i.name }}</h3>
                    <template v-if="i.author">
                        <span :title="parseISO(i.time)">{{ getDateDistance(i.time) }} ago</span> by {{ i.author }}
                    </template>
                </v-col>
            </v-row>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { formatDistanceToNow, parseISO } from 'date-fns';

export default {
    name: "HRChecklist",
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters({
            issueChecklist: 'population/getChecklist'
        }),
    },
    watch:{
    },
    methods: {
        ...mapActions({
        }),
        getDateDistance(dt) {
            return formatDistanceToNow(parseISO(dt));
        },
        parseISO: parseISO,
    },
};
</script>
