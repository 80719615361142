import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.pendingAttachments.length)?_c(VCard,{staticClass:"mb-6",attrs:{"outlined":"","color":"orange"}},[_c('div',{staticClass:"orange white--text font-weight-medium pa-2",domProps:{"textContent":_vm._s(((_vm.pluralize('Attachment', _vm.pendingAttachments.length)) + " Pending Upload"))}}),_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,_vm._l((_vm.pendingAttachments),function(att,i){return _c(VListItem,{key:i,attrs:{"ripple":false}},[_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(_vm.attachmentIcon(att.filename))}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(att.filename)}})],1),_c(VListItemAction,[_c(VRow,{staticClass:"d-flex align-center",attrs:{"no-gutters":""}},[_c(VCol,[(_vm.attachmentTypeList.length > 1)?_c(VMenu,{key:("att" + (att.id)),attrs:{"absolute":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VChip,_vm._g({attrs:{"small":""}},on),[_vm._v(_vm._s(att.type.attachment_name))])]}}],null,true),model:{value:(att.menu),callback:function ($$v) {_vm.$set(att, "menu", $$v)},expression:"att.menu"}},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.attachmentTypeList.filter(function (x) { return x.attachment_name !== att.type.attachment_name; })),function(attType,t){return _c(VListItem,{key:("att_t" + t),on:{"click":function($event){return _vm.changePendingAttachmentType(att, attType)}}},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(attType.attachment_name)}})],1)}),1)],1):_vm._e()],1),_c(VCol,{staticClass:"d-flex align-center"},[(_vm.deletePendingAttachmentWarning !== att.id)?_c(VIcon,{staticClass:"ml-2",attrs:{"color":"red","left":""},on:{"click":function($event){_vm.deletePendingAttachmentWarning = att.id}}},[_vm._v(" mdi-close-circle ")]):_vm._e(),_c('div',{class:_vm.deletePendingAttachmentWarning === att.id ? 'd-flex': 'd-none'},[_c(VBtn,{staticClass:"mr-1",attrs:{"color":"error","x-small":"","dark":""},on:{"click":function($event){return _vm.deletePendingAttachment(att)}}},[_vm._v("Remove")]),_c(VBtn,{attrs:{"x-small":""},on:{"click":function($event){_vm.deletePendingAttachmentWarning = null}}},[_vm._v("Cancel")])],1)],1)],1)],1)],1)}),1)],1)],1):_vm._e(),(_vm.attachmentTypeList.length > 1)?_c(VSelect,{attrs:{"items":_vm.attachmentTypeList,"label":"Attachment type","item-text":"attachment_name","prepend-icon":"mdi-paperclip","dense":"","return-object":""},model:{value:(_vm.attachmentType),callback:function ($$v) {_vm.attachmentType=$$v},expression:"attachmentType"}}):_vm._e(),_c(VFileInput,{ref:"fileInput",attrs:{"label":"Add a new attachment","rules":_vm.rules,"dense":""},on:{"change":_vm.handleAttachment}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }