<template>
    <v-container>
        <div class="text-h5">Proposed Start Date</div>
        <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="computedDateFormatted"
                    label="Date"
                    :rules="waitRules"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                />
            </template>
            <v-date-picker
                v-model="newCandidate.proposedHireDate"
                no-title
                @input="menu2 = false"
                :show-current="false"
            />
        </v-menu>

        <template v-if="newCandidate.onboardingSetup.it">
            <div class="text-h5">IT Setup</div>
            <div class="mb-6">
                <v-row v-if="newCandidate.onboardingSetup.it.desktop">
                    <v-col>
                        <v-select
                            v-model="newCandidate.onboardingTasks.it.desktopSetup"
                            prepend-icon="mdi-desktop-tower-monitor"
                            :items="setupOptions.desktop"
                            :rules="waitRules"
                            label="Desktop"
                            @change="resetNewCandidateDesktopSetup"
                            hide-details
                        ></v-select>
                    </v-col>

                    <v-col v-if="newCandidate.onboardingTasks.it.desktopSetup === 'existing' && workLocation.endpointsInRoom.computers && workLocation.endpointsInRoom.computers.length > 1">
                        <v-select
                            v-model="newCandidate.onboardingTasks.it.assignedDesktop"
                            :items="workLocation.endpointsInRoom.computers"
                            :rules="waitRules"
                            :label="workLocationStr('Desktops in ')"
                            hide-details
                            return-object
                            item-value="name"
                            item-text="name"
                        >
                            <template v-slot:selection="data">
                                {{ data.item.name }}
                                <div v-if="data.item.spec"> - {{ data.item.spec }}</div>
                            </template>
                            <template v-slot:item="data">
                                {{ data.item.name }}
                                <div v-if="data.item.spec"> - {{ data.item.spec }}</div>
                            </template>
                        </v-select>
                    </v-col>

                    <v-col v-if="newCandidate.onboardingTasks.it.desktopSetup === 'existing' && ((newCandidate.onboardingTasks.it.assignedDesktop && newCandidate.onboardingTasks.it.assignedDesktop.value === 'other')|| !workLocation.endpointsInRoom.computers || !workLocation.endpointsInRoom.computers.length || workLocation.endpointsInRoom.computers.length === 1)">
                        <SearchEndpoints
                            v-model="newCandidate.onboardingTasks.it.otherDesktop"
                            :rules="waitRules"
                            endpoint-type="computers"
                            label="Search for desktop by tag #"
                            :hasExistingValue="newCandidate.onboardingTasks.it.otherDesktop"
                        />
                    </v-col>

                    <v-col v-if="newCandidate.onboardingTasks.it.desktopSetup === 'new'">
                        <v-select
                            v-model="newCandidate.onboardingTasks.it.newDesktop"
                            :items="deviceOptions.computer"
                            :rules="waitRules"
                            label="Desktop Type"
                            hide-details
                            return-object
                        >
                            <template v-slot:selection="data">
                                {{ data.item.name }}
                            </template>
                            <template v-slot:item="data">
                                {{ data.item.name }}
                            </template>
                        </v-select>

                    </v-col>
                </v-row>

                <v-row v-if="newCandidate.onboardingSetup.it.laptop">
                    <v-col>
                        <v-select
                            v-model="newCandidate.onboardingTasks.it.laptopSetup"
                            :items="setupOptions.laptop"
                            label="Laptop"
                            :rules="waitRules"
                            prepend-icon="laptop"
                            hide-details
                            
                        />
                    </v-col>
                    <v-col v-if="newCandidate.onboardingTasks.it.laptopSetup === 'existing'">
                        <SearchEndpoints
                            v-model="newCandidate.onboardingTasks.it.assignedLaptop"
                            endpoint-type="laptops"
                            label="Search for laptop by tag #"
                            :rules="waitRules"
                            :hasExistingValue="newCandidate.onboardingTasks.it.assignedLaptop"
                        />
                    </v-col>
                    <v-col v-if="newCandidate.onboardingTasks.it.laptopSetup === 'new'">
                        <v-select
                            v-model="newCandidate.onboardingTasks.it.newLaptop"
                            :items="deviceOptions.laptop"
                            :rules="waitRules"
                            label="Laptop Brand"
                            hide-details
                            return-object
                        >
                            <template v-slot:selection="data">
                                {{ data.item.name }}
                            </template>
                            <template v-slot:item="data">
                                {{ data.item.name }}
                            </template>
                        </v-select>
                    </v-col>
                </v-row>

                <v-row v-if="newCandidate.onboardingSetup.it.printer">
                    <v-col>
                        <v-select
                            v-model="newCandidate.onboardingTasks.it.printerSetup"
                            :items="setupOptions.printer"
                            prepend-icon="mdi-printer"
                            :rules="waitRules"
                            label="Printer"
                            hide-details
                        />
                    </v-col>
                    <v-col v-if="newCandidate.onboardingTasks.it.printerSetup === 'existing' && workLocation.endpointsInRoom.printers && workLocation.endpointsInRoom.printers.length > 1">
                        <v-select
                            v-model="newCandidate.onboardingTasks.it.assignedPrinter"
                            :items="workLocation.endpointsInRoom.printers"
                            :label="workLocationStr('Printers in ')"
                            :rules="waitRules"
                            return-object
                            hide-details
                            item-value="name"
                            item-text="name"
                        >
                            <template v-slot:selection="data">
                                {{ data.item.name }}
                                <div v-if="data.item.spec"> - {{ data.item.spec }}</div>
                            </template>
                            <template v-slot:item="data">
                                {{ data.item.name }}
                                <div v-if="data.item.spec"> - {{ data.item.spec }}</div>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col v-if="newCandidate.onboardingTasks.it.printerSetup === 'existing' && ((newCandidate.onboardingTasks.it.assignedPrinter && newCandidate.onboardingTasks.it.assignedPrinter.value === 'other') || !workLocation.endpointsInRoom.printers || !workLocation.endpointsInRoom.printers.length || workLocation.endpointsInRoom.printers.length === 1)">
                        <SearchEndpoints
                            v-model="newCandidate.onboardingTasks.it.otherPrinter"
                            endpoint-type="printers"
                            :rules="waitRules"
                            label="Search for printer by tag #"
                            :hasExistingValue="newCandidate.onboardingTasks.it.otherPrinter"
                        />
                    </v-col>

                </v-row>

                <v-row v-if="newCandidate.onboardingSetup.it.cellPhone">
                    <v-col>
                        <v-select
                            v-model="newCandidate.onboardingTasks.it.cellPhoneSetup"
                            :items="setupOptions.cellPhone"
                            :rules="waitRules"
                            prepend-icon="mdi-cellphone"
                            label="Cell phone"
                            hide-details
                        />
                    </v-col>
                    <v-col v-if="newCandidate.onboardingTasks.it.cellPhoneSetup === 'new'">
                        <v-select
                            v-model="newCandidate.onboardingTasks.it.newCellPhone"
                            :items="deviceOptions.smartphone"
                            :rules="waitRules"
                            label="Cellphone Brand"
                            hide-details
                            return-object
                        >
                            <template v-slot:selection="data">
                                {{ data.item.name }}
                            </template>
                            <template v-slot:item="data">
                                {{ data.item.name }}
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
            </div>
            <template v-if="newCandidate.onboardingSetup.it.accounts">
                <div class="text-h5">Accounts</div>
                <div class="mb-6">
                    <v-row>
                        <v-col>
                            <v-checkbox
                                v-if="newCandidate.onboardingSetup.it.accounts.mc"
                                v-model="newCandidate.onboardingTasks.it.accounts.mc"
                                label="MC Domain / Exchange Account"
                                class="ma-0"
                                hide-details
                            />
                            <v-checkbox
                                v-model="newCandidate.onboardingTasks.it.accounts.vdi"
                                label="VDI Account"
                                class="ma-0"
                                hide-details
                            />   
                        </v-col>
                    </v-row>
                </div>
            </template>

            <template v-if="newCandidate.onboardingSetup.it.systemAccess">
                <div class="text-h5">Systems Access</div>
                <div class="mb-6">
                    <v-row>
                        <v-col>
                            <v-checkbox
                                v-for="(sys, i) in systemAccessList"
                                v-model="newCandidate.onboardingTasks.it.systemAccess"
                                :label="sys.name"
                                :value="sys.name"
                                :key="i"
                                class="ma-0"
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </div>
            </template>
        </template>
        <template v-if="newCandidate.onboardingSetup.ops">
            <div class="text-h5">Operations</div>
            <div class="mb-6">
                <v-select
                    v-model="newCandidate.onboardingTasks.ops.telecom"
                    :items="setupOptions.telecom"
                    :rules="waitRules"
                    label="Phone line"
                    prepend-icon="mdi-phone"
                />
                <v-text-field
                    v-if="newCandidate.onboardingTasks.ops.telecom === 'existing'"
                    v-model="newCandidate.onboardingTasks.ops.telecomPhoneLine"
                    :rules="waitRules"
                    label="Existing phone line #"
                    placeholder="###-###-####"
                    class="ml-8"
                />
            </div>
        </template>
    </v-container>
</template>
<script>
import { mapGetters } from "vuex";

import SearchEndpoints from "@/components/it/inventory/SearchEndpoints";

export default {
    name: "OnboardingForm",
    computed: {
        ...mapGetters({
            step: "getStep",
            workLocation: "population/getWorkLocation",
            newCandidate: "population/getNewCandidate",
            employeeType: 'population/getEmployeeType',
            systemAccessList: "population/getSystemAccessList",
            deviceOptions: "population/getDeviceOptions"
        }),
        computedDateFormatted() {
            return this.formatDate(this.newCandidate.proposedHireDate);
        },
        waitRules() {
            return this.step === 3 ? this.$rules.required : [];
        }
    },
    components: {
        SearchEndpoints,
    },
    methods: {
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${month}/${day}/${year}`;
        },
        // allowedDates(val) {
        //     const dt = new Date(val);
        //     const days = this.employeeType.fields?.startDaysAllowed || [0];
        //     //return dt.getDay() === 1;
        //     return days.includes(dt.getDay());
        // },
        // getStartOfWeek(dt = null) {
        //     const diffDays = this.employeeType.fields?.startDaysOffset || 21;
        //     dt = dt ? new Date(dt) : new Date();
        //     dt.setDate(dt.getDate() + diffDays);
        //     const dayOfWk = dt.getDay();
        //     const lessDays = dayOfWk === 0 ? 6 : dayOfWk - 1;
        //     let wkStart = new Date(
        //         new Date(dt).setDate(dt.getDate() - lessDays)
        //     );
        //     // const wkEnd = new Date(new Date(wkStart).setDate(wkStart.getDate() + 6));
        //     const offset = wkStart.getTimezoneOffset();
        //     wkStart = new Date(wkStart.getTime() + offset * 60 * 1000);
        //     return wkStart.toISOString().split("T")[0];
        // },
        workLocationStr(s) {
            const workLoc = this.newCandidate.workLocation;
            return `${s + workLoc.building.short_name}-${workLoc.floor}-${workLoc.room}`
        },
        resetNewCandidateDesktopSetup(){
            this.$store.dispatch('population/resetNewCandidateDesktopSetup');
        }
    },
    data() {
        return {
            menu1: false,
            menu2: false,
            loading: false,
            setupOptions: {
                desktop: [
                    {
                        value: "existing",
                        text: "Existing desktop will be used",
                    },
                    { value: "new", text: "Needs new desktop" },
                    { value: "none", text: "No desktop needed" },
                ],
                printer: [
                    {
                        value: "existing",
                        text: "Existing printer will be used",
                    },
                    { value: "new", text: "Needs new printer" },
                    { value: "none", text: "No printer needed" },
                ],
                laptop: [
                    { value: "existing", text: "Existing laptop will be used" },
                    { value: "new", text: "Needs new laptop" },
                    { value: "none", text: "No laptop needed" },
                ],
                cellPhone: [
                    {
                        value: "existing",
                        text: "Existing cell phone will be used",
                    },
                    { value: "new", text: "Needs new cell phone" },
                    { value: "none", text: "No cell phone needed" },
                ],
                telecom: [
                    { value: "existing", text: "Existing phone line" },
                    { value: "new", text: "New phone line" },
                    { value: "none", text: "No phone line" },
                ],
            },
        };
    },
    mounted() {
        this.$store.dispatch('population/loadSystemAccess');
        this.$store.dispatch('population/loadDeviceOptions');
    },
};
</script>