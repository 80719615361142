<template>
    <v-dialog
        v-model="showDialog"
        max-width="700px"
        persistent
        eager
    >
        <template v-slot:activator="{ on: dialog, attrs }">
            <v-tooltip bottom open-delay="750">
                <template v-slot:activator="{on: tooltip}">
                    <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="{...tooltip, ...dialog}"
                        @click="$refs.timeline.loadTimeline();"
                    >mdi-update</v-icon>
                </template>
                <span>View Timeline</span>
            </v-tooltip>
        </template>

        <v-card>
            <v-card-title class="headline">{{ item.key }} Timeline</v-card-title>
            <v-card-text>
                <TimelineWidget :item="item" ref="timeline" />
            </v-card-text>
            <v-card-actions>
                
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="showDialog = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import TimelineWidget from '@/components/population/TimelineWidget';

//import { mapGetters, mapActions } from "vuex";

export default {
    name: "TimelineDialog",
    props: {
        item: {
            type: Object
        }
    },
    components: {
        TimelineWidget
    },
    data() {
        return {
            showDialog: false,
        };
    },
    computed: {
        //...mapGetters({})
    },
    methods: {
        //...mapActions({}),
    }
};
</script>