<template>
    <div v-if="loading">
        <v-progress-linear
            indeterminate
            rounded
            height="6"
            class="mt-4"
        ></v-progress-linear>
    </div>
    <div v-else>
        <div class="mt-n3">
            Created <span :title="statuses.created">{{ getDateDistance(statuses.created) }} ago</span>
        </div>
        <v-container fill-height>
            <v-row align="center">
                <v-col cols="3" style="text-align: center" v-if="statuses.back_statuses.length">
                    <div v-for="(status, i) in statuses.back_statuses" :key="i" class="mt-4">
                        <v-chip
                            label
                            small
                            v-text="status.name"
                        />
                        <div class="caption cap-height">
                            <strong v-if="status.duration">{{ convertToReadableTime({ms: status.duration}) }}</strong>
                            <div v-if="status.duration_all" class="font-italic">({{ convertToReadableTime({ms: status.duration_all}) }} avg)</div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="1" v-if="statuses.back_statuses.length"><v-icon left>mdi-arrow-right</v-icon></v-col>
                <v-col style="text-align: center">
                    <div class="mt-4">
                        <v-chip
                            :color="getHRStatusColor(statuses.current_status.name)"
                            label
                            v-text="statuses.current_status.name"
                            small
                        />
                        <div class="caption cap-height">
                            <div v-if="statuses.current_status.duration">
                                <v-icon small class="mr-1">mdi-clock-outline</v-icon>
                                <strong>{{ convertToReadableTime({ms: statuses.current_status.duration}) }}</strong>
                            </div>
                            <div v-if="statuses.current_status.duration_all" class="font-italic">({{ convertToReadableTime({ms: statuses.current_status.duration_all}) }} avg)</div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="1"><v-icon left>mdi-arrow-right</v-icon></v-col>
                <v-col v-if="statuses.forward_statuses.length" style="text-align: center">
                    <div v-for="(status, i) in statuses.forward_statuses" :key="i" class="mt-4">
                        <v-chip
                            :color="getHRStatusColor(status.name)"
                            label
                            small
                            v-text="status.name"
                        />
                        <div class="caption font-italic cap-height">
                            <div v-if="status.duration_all">
                                ({{ convertToReadableTime({ms: status.duration_all}) }} avg)
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import axios from 'axios';
import { getHRStatusColor } from '@/util/common';
import { formatDistanceToNow, parseISO } from 'date-fns';

//import { mapGetters, mapActions } from "vuex";

export default {
    name: "TimelineWidget",
    props: {
        item: {
            type: Object
        }
    },
    data() {
        return {
            loading: true,
            workflowSteps: {},
            statuses: {}
        };
    },
    computed: {
        //...mapGetters({})
    },
    methods: {
        //...mapActions({}),
        async loadTimeline() {
            const response = await axios.get(`/population/load_timeline/${this.item.key}/`);

            if (!response.data.error) {
                this.statuses = response.data;
            }

            this.workflowSteps = {
                'Division Review': {
                    step: 1
                }
            };
            this.loading = false;
            /*
            await this.$store.dispatch('population/loadIssueTransitions', item.key);
            if (!this.nextTransition) {
                this.showDialog = false;
                this.$store.commit('setErrorSnack', `You are not authorized to any transitions for ${item.key}`);
            }
            */
        },
        getDateDistance(dt) {
            return formatDistanceToNow(parseISO(dt));
        },
        getHRStatusColor: getHRStatusColor
    }
};
</script>
<style scoped>
.cap-height {
    height: 20px
}
</style>