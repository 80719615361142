import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanel,[_c(VExpansionPanelHeader,[_c('div',[(_vm.issueChecklist.hr_completed)?_c(VIcon,{attrs:{"left":"","color":"green"}},[_vm._v("mdi-check-circle-outline")]):_vm._e(),_vm._v(" Core Checklist ")],1)]),_c(VExpansionPanelContent,_vm._l((_vm.issueChecklist.checklist),function(i){return _c(VRow,{key:i.name},[_c(VCol,{attrs:{"cols":"1"}},[_c(VCheckbox,{staticClass:"defCursor ml-3 mt-0 pt-0",attrs:{"color":"success","ripple":false,"disabled":!i.value,"hide-details":"","readonly":""},model:{value:(i.value),callback:function ($$v) {_vm.$set(i, "value", $$v)},expression:"i.value"}})],1),_c(VCol,{staticClass:"ma-0 pb-0"},[_c('h3',{class:("" + (!i.value ? 'grey--text' : ''))},[_vm._v(_vm._s(i.name))]),(i.author)?[_c('span',{attrs:{"title":_vm.parseISO(i.time)}},[_vm._v(_vm._s(_vm.getDateDistance(i.time))+" ago")]),_vm._v(" by "+_vm._s(i.author)+" ")]:_vm._e()],2)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }