<template>
    <div style="display: contents">
        <v-dialog
            v-model="config.showDialog"
            :max-width="`${config.modalWidth}px`"
            scrollable
            persistent
        >
            <template v-slot:activator="{ on: dialog, attrs }">
                <v-tooltip bottom open-delay="750">
                    <template v-slot:activator="{on: tooltip}">
                        <v-badge
                            :value="item.meta.validAttach === false"
                            color="orange"
                            icon="mdi-exclamation-thick"
                            offset-x="17"
                            offset-y="11"
                            style="cursor: pointer"
                            bordered
                            @click.native.stop="loadAttachments"
                        >
                            <v-icon
                                v-bind="attrs"
                                v-on="{...tooltip, ...dialog}"
                                @click="loadAttachments"
                            >mdi-paperclip</v-icon>
                        </v-badge>
                    </template>
                    <span v-text="tooltipText" />
                </v-tooltip>   
            </template>
            <v-card>
                <v-card-title class="headline">{{ item.key }} Attachments</v-card-title>
                <AttachmentAlert
                    v-if="hrTicketType.attachments"
                    :attachments="attachments"
                    :pendingAttachments="pendingAttachments"
                    :attachmentTypes="hrTicketType.attachments"
                    ref="attAlert"
                    class="ml-4 mr-4"
                    @validityChange="config.validAttach = arguments[0]"
                />
                <v-card-text v-if="config.loading">
                    <v-row class="fill-height text-center" align-content="center" justify="center" style="height: 200px">
                        <v-col cols="8">
                            <div class="text-h6 font-weight-light">Loading attachments...</div>
                            <v-progress-linear
                                indeterminate
                                rounded
                                height="3"
                            ></v-progress-linear>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text v-else-if="attachments.length">
                    <AttachmentList
                        :attachments="attachments"
                        :item="item"
                        :attachmentTypes="hrTicketType.attachments"
                        :isValidPending="config.validAttach"
                        @deleteAttachment="updateJiraAttachments"
                        @changeAttachmentType="updateJiraAttachments"
                        @reload="$emit('reload')"
                    />
                </v-card-text>
                <v-card-text v-else-if="attachments === false">
                <v-row class="fill-height text-center" align-content="center" justify="center" style="height: 200px">
                    <v-col cols="8">
                        <div class="text-h6 font-weight-light">You are not authorized to view attachments.</div>
                    </v-col>
                </v-row>
            </v-card-text>
                <v-card-text v-else>
                    There are no files attached to this request
                </v-card-text>
                <v-divider />
                <div class="mt-2">
                    <v-card-text>
                        <v-form
                            v-model="config.formValid"
                            ref="form"
                            lazy-validation
                        >
                            <AttachmentInput
                                :attachmentTypes="hrTicketType.attachments"
                                @pendingAttachments="handlePendingAttachments"
                                ref="attInput"
                            />
                            <MentionWidget
                                class="mt-2"
                                ref="mentionWidget"
                                :limit="7"
                            />
                        </v-form>
                    </v-card-text>
                </div>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
                    <v-btn
                        color="blue darken-1"
                        :loading="config.loading"
                        :disabled="!config.formValid || config.loading || !pendingAttachments.length"
                        @click="addAttachment"
                        text
                    >Add Attachment</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <AttachmentConfirmationDialog 
            ref="confirmDialog" 
            v-if="config.showConfirmationDialog"
            v-model="config.showConfirmationDialog"
            @confirmGoBack="confirmGoBack"
            @confirmCancel="confirmCancel"
        />
    </div>
</template>
<script>
import axios from 'axios';
import { reactive, ref, onMounted, computed } from '@vue/composition-api';

import MentionWidget from '@/components/common/MentionWidget';
import AttachmentList from '@/components/population/attachments/AttachmentList';
import AttachmentInput from '@/components/population/attachments/AttachmentInput';
import AttachmentAlert from '@/components/population/attachments/AttachmentAlert';
import AttachmentConfirmationDialog from '@/components/population/attachments/AttachmentConfirmationDialog';

export default {
    name: 'JiraAttachmentDialog',
    props: {
        item: {
            type: Object
        },
        tooltipText: {
            type: String,
            default: 'Attachments'
        }
    },
    emits: ['reload'],
    components: {
        MentionWidget,
        AttachmentList,
        AttachmentInput,
        AttachmentAlert,
        AttachmentConfirmationDialog
    },
    setup(props, { root, emit, refs }){
        const store = root.$store;

        //  Data

        const config = reactive({
            modalWidth: 800,
            showDialog: false,
            loading: false,
            formValid: true,
            attachmentWarning: null,
            validAttach: true,
            showConfirmationDialog: false
        });

        const attachment = ref(null);
        const attachments = ref([]);
        const attachmentCategory = ref(null);

        const comment = ref('');

        const hrTicketType = ref({});
        const pendingAttachments = ref([]);

        //  Mounted
        onMounted(() => {
            attachmentCategory.value = attachmentTypeList.value[0];
        });

        //  Computed
        const attachmentTypeList = computed(() => {
            let list = [{attachment_name: 'Uncategorized'}]
            if (Object.entries(hrTicketType.value.attachments || {}).length) {
                list = list.concat(Object.entries(hrTicketType.value.attachments).map(x => x[1].attachment_name ? x[1] : x))
            }
            return list;
        }) 

        const user = computed(() => store.getters['user']);
        const approverDivs = computed(() => store.getters['population/getApproverDivs']);
        
        const hasApproverPerm = computed(() => {
            return approverDivs.value.find(x => x.long_name == props.item.division) ? true : false
        })

        //  Methods
        const closeDialog = () => {
            if(pendingAttachments.value.length > 0){
                config.showDialog = false;
                config.showConfirmationDialog = true;
                return;
            }
            config.showDialog = false;
            config.attachmentWarning = null;
            reset();
        }
        const reset = () => {
            comment.value = '';
            pendingAttachments.value = [];
            if (refs.attInput) refs.attInput.reset();
        }
        const loadAttachments = async () => {
            reset();
            config.loading = true;
            config.showDialog = true;
            
            // load new hire to populate conditional fields to check for required attachments if not a term or comp/leave request
            if (['leave-requests', 'compensation-requests'].includes(root.$route.params.view)) {
                if (
                    !hasApproverPerm.value && 
                    !user.value.groups.includes(`portal-${root.$route.params.view}-attachment`)
                ) {
                    attachments.value = false;
                    config.loading = false;
                    return;
                }
            } else if (props.item.issue_type && props.item.issue_type !== 'Termination') {
                await store.dispatch('population/loadNewHireRequest', props.item.key);
            } 

            // if (['leave-requests', 'compensation-requests'].includes(root.$route.params.view) && !hasApproverPerm.value) {
            //     if (!user.value.groups.includes(`portal-${root.$route.params.view}-attachment`)) {
            //         attachments.value = false;
            //         config.loading = false;
            //         return;
            //     }
            // }

            // first load attachments from jira
            let response = await axios.get(`/jira/load_attachments/${props.item.key}/`);
            if (response.data.error) {
                store.commit('setErrorSnack', response.data.message);
            } else {
                attachments.value = response.data.attachments;
            }
            // then load the hr ticket type if necessary
            if (props.item.issue_type) {
                response = await axios.post(`/population/load_hr_ticket_type/`, {issueType: props.item.issue_type});
                if (!response.data.error) {
                    hrTicketType.value = response.data;
                }
            }
            
            config.loading = false;
        }
        const addAttachment = async () => {
            if (refs.form.validate()) {
                config.loading = true;

                // combine attachment data and text data into a FormData object
                let formData = new FormData();

                if (pendingAttachments.value.length) {
                    for (const a of pendingAttachments.value) {
                        formData.append(a.id, a.file, a.filename);
                    }
                }

                formData.append('data', JSON.stringify({
                    comment: refs.mentionWidget.getComment(),
                    item: props.item,
                    pendingAttachments: pendingAttachments.value,
                    isValidPending: config.validAttach
                }));

                const url = `/jira/add_attachment/${props.item.key}/`;

                const response = await axios.post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response.data.error) {
                    store.commit('setErrorSnack', response.data.message);
                } else {
                    store.commit('setSuccessSnack', `Successfully added attachment`);
                    attachments.value = response.data.attachments;
                    reset();
                    refs.mentionWidget.clear();
                    config.showDialog = false;
                    // if (response.data.reload) this.$store.dispatch('population/loadNewHires');
                    if (response.data.reload) emit('reload');
                }
                config.loading = false;
            }
        }


        const focusAddComment = () => {
            root.$nextTick(() => {
                refs.mentionWidget.focus();
            });
        }
        
        const getAttachment = async (att) => {
            const attachmentId = att.id;
            const response = await axios.get(`/jira/get_attachment/${props.item.key}/${attachmentId}/`, {responseType: 'blob'});
            if (!window.navigator.msSaveOrOpenBlob){
                const contentDisposition = response.headers['content-disposition'];
                
                const url = URL.createObjectURL(new Blob([response.data],{type:response.headers['content-type']}));
                const link = document.createElement('a');
                const fileName = contentDisposition.match(/filename=(?<filename>[^,;]+)/)[1];
                
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                alert('Fail: what version of IE are you using?');
            }
        }

        const updateJiraAttachments = (payload) => {
            attachments.value = payload.jiraAttachments;
            root.$nextTick(() => {
                if (refs.attAlert) refs.attAlert.validate();
            });
        }

        const handlePendingAttachments = (payload) => {
            pendingAttachments.value = payload;
        }

        const confirmGoBack = () => {
            config.showDialog = true;
            config.showConfirmationDialog = false;
        }

        const confirmCancel = () => {
            config.showConfirmationDialog = false;
            config.attachmentWarning = null;
            reset();
        }


        return {
            config,
            attachment,
            attachments,
            attachmentCategory,
            comment,
            hrTicketType,
            pendingAttachments,
            confirmGoBack,
            confirmCancel,
            handlePendingAttachments,
            updateJiraAttachments,
            getAttachment,
            focusAddComment,
            // getDateDistance,
            addAttachment,
            loadAttachments,
            reset,
            closeDialog,
            attachmentTypeList

        }

    },
};
</script>