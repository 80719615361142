<template>
    <v-dialog
        v-model="showDialog"
        max-width="600px"
        persistent
    >
        <template v-slot:activator="{ on: dialog, attrs }">
            <v-tooltip bottom open-delay="750">
                <template v-slot:activator="{on: tooltip}">
                    <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="{...tooltip, ...dialog}"
                        color="red lighten-2"
                    >mdi-close-circle</v-icon>
                </template>
                <span v-text="tooltipText" />
            </v-tooltip>
        </template>

        <v-card>
            <v-card-title class="headline">Cancel Request</v-card-title>

            <v-card-text>
                <v-form
                    v-model="formValid"
                    ref="form"
                    lazy-validation
                >
                    Are you sure you want to cancel <strong v-text="item.key" />?
                    <!-- Notes -->
                    <v-textarea
                        v-model="cancelNotes"
                        :rules="$rules.required"
                        label="Cancellation notes"
                    ></v-textarea>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="showDialog = false">Close</v-btn>
                <v-btn
                    color="red darken-1"
                    :loading="loading"
                    :disabled="!formValid || loading"
                    @click="submitCancel"
                    text
                >Cancel {{ item.key }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapGetters, mapActions } from "vuex";

export default {
    name: "CancelDialog",
    props: {
        item: {
            type: Object
        },
        tooltipText: {
            type: String,
            default: 'Cancel'
        }
    },
    data() {
        return {
            showDialog: false,
            loading: false,
            formValid: true,
            cancelNotes: ''
        };
    },
    components: {
    },
    computed: {
        ...mapGetters({
        })
    },
    methods: {
        ...mapActions({
        }),
        async submitCancel() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                const response = await axios.post(`/population/cancel_req/${this.item.key}/`, {notes: this.cancelNotes});
                if (response.data.error) {
                    this.$store.commit('setErrorSnack', response.data.message);
                } else {
                    this.$store.commit('setSuccessSnack', `Successfully canceled ${this.item.key}`);
                    this.$emit('reloadData');
                }
                this.showDialog = false;
                this.loading = false;
            }
        }
    }
};
</script>