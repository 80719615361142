import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"800px","eager":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","outlined":""},on:{"click":_vm.createReq}},'v-btn',attrs,false),on),[_vm._v(" Create New Hire ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))])]),(_vm.dialogLoading)?_c(VCardText,[_c(VProgressLinear,{staticClass:"mt-4",attrs:{"indeterminate":"","rounded":"","height":"6"}})],1):_c(VCardText,[(_vm.showGenericAlert)?_c(VAlert,{attrs:{"dense":"","outlined":"","type":_vm.genericAlert.type},domProps:{"innerHTML":_vm._s(_vm.genericAlert.body)}}):_vm._e(),(_vm.showFinApprovalMsg)?_c(VAlert,{attrs:{"dense":"","outlined":"","type":"warning"}},[_c('ul',{staticClass:"ml-2"},[(_vm.showFinApprovalMsg)?_c('li',[_c('strong',[_vm._v("This request is pending divisional approval")])]):_vm._e()])]):_vm._e(),(_vm.showError)?_c(VAlert,{class:{'shake' : _vm.animated},attrs:{"dense":"","outlined":"","type":"error"}},[_c('ul',{staticClass:"ml-2"},_vm._l((_vm.invalidMessages),function(msg,index){return _c('li',{key:index},[_c('strong',[_vm._v(_vm._s(msg))])])}),0)]):_vm._e(),_c(VSelect,{attrs:{"items":_vm.employeeTypes,"item-text":"name","label":"New Hire Type","return-object":"","disabled":this.newHireRequest.editRequest && this.newHireRequest.status != 'Division Review'},on:{"change":_vm.changeEmployeeType},model:{value:(_vm.newHireRequest.employeeType),callback:function ($$v) {_vm.$set(_vm.newHireRequest, "employeeType", $$v)},expression:"newHireRequest.employeeType"}}),_c('NewHireForm',{ref:"form",on:{"validityChange":_vm.changeValidity}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancel}},[_vm._v("Close")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading,"disabled":!_vm.formValid || !_vm.newHireRequest.employeeType || _vm.loading},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.action))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }