import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"contents"}},[_c(VTooltip,{attrs:{"bottom":"","open-delay":"750"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.options.allowEdit !== false)?_c(VBadge,{staticStyle:{"cursor":"pointer"},attrs:{"value":!_vm.item.termUser,"color":"orange","icon":"mdi-exclamation-thick","offset-x":"22","offset-y":"13","bordered":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('editReq')}}},[_c(VIcon,_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('editReq')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])],1):_vm._e()]}}])},[(!_vm.item.termUser)?_c('span',[_vm._v("Edit (Incomplete)")]):_c('span',[_vm._v("Edit")])]),_c('JiraCommentDialog',{attrs:{"item":_vm.item},on:{"commentAdded":function($event){return _vm.$emit('commentAdded')}}}),_c('JiraAttachmentDialog',{attrs:{"item":_vm.item},on:{"reload":function($event){return _vm.$store.dispatch('population/loadTerms')}}}),(_vm.item.status === 'Pending Termination')?_c('ChecklistDialog',{attrs:{"item":_vm.item,"type":"term"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }