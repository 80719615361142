<template>
    <v-container v-if="!loading && Object.entries(set).length">
        <v-row
            class="mb-6"
            v-for="(values, name, i) in set"
            :key="i"
        >
            <v-col
                class="text-h5 grey--text text--darken-1 font-weight-light"
                v-text="divNameOnly(name)"
            />
            <v-col
                cols="auto"
                class="d-flex align-center"
            >
                <v-chip
                    color="grey"
                    v-text="divPerms.requesters.some(x => name.includes(x.long_name)) ? 'Requester' : 'Approver'"
                    small
                    outlined
                />
            </v-col>
            <v-col class="ma-0 pt-0" cols="12">
                <v-data-table
                    class="elevation-1 ma-0 pa-0"
                    :headers="headers"
                    :items="values"
                    :search="searchQuery"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:item="props">
                        <tr :id="props.item.key === searchHighlight ? 'hlme': false" :class="{'hl-row': props.item.key === searchHighlight}">
                            <td>{{ props.item.key }}</td>
                            <td>{{ props.item.issue_type }}</td>
                            <td>{{ props.item.title }}</td>
                            <td>{{ props.item.candidate.join(' ') }}</td>
                            <td>

                            <v-tooltip 
                                top 
                                open-delay="750"
                                max-width="300"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-chip
                                        v-on="on"
                                        :color="getHRStatusColor(props.item.status)"
                                        label
                                        small
                                        v-text="props.item.status"
                                    />
                                </template>
                                <span> {{ getStatusInfo(props.item) }} </span>
                                
                            </v-tooltip>
                            
                            </td>
                            <td>
                                <NewHireActions
                                    :options="options"
                                    :item="props.item"
                                    @editReq="$emit('editReq', props.item)"
                                    @commentAdded="$emit('commentAdded')"
                                    @reloadData="$emit('reloadData')"
                                />
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else-if="!loading && !Object.entries(set).length">
        <v-alert class="mt-4" outlined type="info">
        Could not find any new hires
        </v-alert>
    </v-container>
    <v-container style="height: 400px" v-else>
        <v-row class="fill-height text-center" align-content="center" justify="center">
            <v-col cols="5">
                <div class="text-h6 font-weight-light">Loading new hires...</div>
                <v-progress-linear
                    indeterminate
                    rounded
                    height="6"
                ></v-progress-linear>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { computed, watch, toRefs } from '@vue/composition-api';
import { getHRStatusColor } from '@/util/common';

import NewHireActions from '@/components/population/newhire/NewHireActions';

import stringHelper from '@/composables/common/string';

export default {
    name: 'NewHireList',
    props: {
        set: Object,
        headers: Array,
        loading: Boolean,
        options: {
            type: Object,
            default() {
                return {}
            }
        },
        searchQuery: {
            type: String,
            default: null
        }
    },
    components: {
        NewHireActions
    },
    setup(props, { root }){
        const store = root.$store;
        
        //  Refs
        const { set } = toRefs(props);

        //  Getters
        const divPerms = computed(() => store.getters['population/getDivPerms']);
        
        //  Computed
        const searchHighlight = computed(() => root.$route.query.hl);

        //  Methods
        const scrollHighlight = async () => {
            await root.$nextTick();
            await root.$nextTick();
            root.$vuetify.goTo('#hlme');
        }
        const { divNameOnly } = stringHelper();

        const getStatusInfo = item => {
            if (item.config) {
                return item.config[item.status] ? item.config[item.status] : 'TBD'
            }
            return 'TBD'
        }

        //  Watch
        watch(searchHighlight, () => scrollHighlight());
        watch(set, () => {
            if (searchHighlight.value) scrollHighlight();
        });

        return {
            divPerms,
            searchHighlight,
            getHRStatusColor,
            scrollHighlight,
            divNameOnly,
            getStatusInfo
        }
    },
};
</script>
<style scoped>
.hl-row {
    background-color: rgba(255, 255, 0, 0.5);
}
</style>