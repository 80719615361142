<template>
    <v-data-table
        :headers="cmpHeaders"
        :items="items"
        :item-key="itemKey"
        :value="value"
        :show-select="showSelect"
        @input="$emit('input', $event)"
        dense
        disable-pagination
        hide-default-footer
    >
        <template v-slot:header.data-table-select v-if="disableSelection"></template>
        <template v-slot:item.data-table-select="{ isSelected, select }">
            <v-simple-checkbox
                :value="isSelected"
                :readonly="disableSelection"
                :disabled="disableSelection"
                :ripple="false"
                @input="select($event)"
            />
        </template>
        <template v-slot:item.division_name="{ item }" v-if="divisionOptions.length">
            <v-row>
                <v-col class="d-flex align-center grey--text">
                    {{ item.old_division_name || item.division_name }}
                </v-col>
                <v-col cols="1" class="d-flex align-center">
                    <v-icon>mdi-arrow-right</v-icon>
                </v-col>
                <v-col>
                    <v-select
                        item-text="long_name"
                        item-value="id"
                        class="mt-2 mb-2 caption font-weight-bold"
                        :value="0"
                        :items="cmpDivisionOptions"
                        @change="updateDivision($event, item)"
                        dense
                        return-object
                        hide-details
                    />
                </v-col>
            </v-row>
        </template>
        <template v-slot:item.comm_groups="{ item }">
            <div class="label-group">
                <div
                    v-for="(g,i) in item.comm_groups"
                    class="white--text rounded grey darken-2 pl-1 pr-1 text-uppercase"
                    :key="i"
                    v-text="g.name"
                />
            </div>
        </template>
    </v-data-table>
</template>
<script>
import { computed } from '@vue/composition-api';

export default {
    name: 'EmployeeDataTable',
    props: {
        headers: {
            type: Array,
            default() { return [] }
        },
        items: {
            type: Array,
            default() { return [] }
        },
        itemKey: {
            type: String,
            default: 'cu_uni'
        },
        value: {
            type: Array,
            default() { return [] }
        },
        showSelect: {
            type: Boolean,
            default: true
        },
        divisionOptions: {
            type: Array,
            default() { return [] }
        },
        disableSelection: {
            type: Boolean,
            default: false
        },
        hideColumns: {
            type: Array,
            default() { return [] }
        }
    },
    setup(props, { emit }) {
        const cmpDivisionOptions = computed(() => {
            return [{id: 0, long_name: '--- Select Division ---'}, ...props.divisionOptions];
        });

        const cmpHeaders = computed(() => {
            return !props.hideColumns.length ? props.headers : props.headers.filter(x => !props.hideColumns.includes(x.value));
        });

        const updateDivision = (evt, item) => {
            const filteredValues = props.value.filter(x => x.cu_uni !== item.cu_uni);

            if (evt.id) {
                if (!item.old_division_name) item.old_division_name = item.division_name
                item.division_name = evt.long_name;
                item.division = evt;
                emit('input', [item, ...filteredValues])
            } else {
                item.division_name = item.old_division_name;
                emit('input', filteredValues);
            }
        };

        return {
            cmpDivisionOptions,
            cmpHeaders,
            updateDivision
        };
    }
};
</script>
<style scoped>
    .label-group {
        display: flex;
        gap: 5px;
        font-size: 10px;
    }
</style>