import { VAlert } from 'vuetify/lib/components/VAlert';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v("Tickets")]),(_vm.issueChecklist.tickets)?_c(VExpansionPanelContent,_vm._l((_vm.issueChecklist.tickets),function(i,k){return _c(VRow,{key:k},[_c(VCol,{staticClass:"ma-0 pt-0",attrs:{"cols":"12"}},[_c('h3',{staticClass:"ma-1 pb-2"},[_vm._v(_vm._s(k))]),_c(VDataTable,{staticClass:"elevation-1 ma-0 pa-0",attrs:{"headers":_vm.headers,"items":i,"items-per-page":5},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":_vm.getHRStatusColor(item.status),"label":"","small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('JiraCommentDialog',{attrs:{"item":item},on:{"commentAdded":function($event){return _vm.$emit('reload')}}}),_c('JiraAttachmentDialog',{attrs:{"item":item},on:{"reload":function($event){return _vm.$emit('reload')}}})]}}],null,true)})],1)],1)}),1):_c(VExpansionPanelContent,[_c(VAlert,{staticClass:"mt-4",attrs:{"outlined":"","type":"info"}},[_vm._v(" Could not find any tickets ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }