<template>
    <v-main>
        <v-container fluid style="max-width: 1400px">
            <div class="text-h4 mb-4">Employee Import</div>
            <v-card
                rounded
                outlined
            >
                <v-card-text class="pa-8">
                    <v-file-input
                        label="PACs / SC Roster File "
                        ref="fileInput"
                        accept=".xlsx"
                        @change="handleAttachment"
                        outlined
                        hide-details
                    ></v-file-input>
                </v-card-text>
            </v-card>

            <v-row class="fill-height text-center" align-content="center" justify="center" style="height: 200px" v-show="isLoading">
                <v-col cols="8">
                    <div class="text-h6 font-weight-light">Loading...</div>
                    <v-progress-linear
                        indeterminate
                        rounded
                        height="3"
                    ></v-progress-linear>
                </v-col>
            </v-row>
            <v-row class="fill-height text-center" align-content="center" justify="center" style="height: 200px" v-show="error">
                <v-col cols="6">
                    <v-alert
                        type="error"
                        class="text-h5 font-weight-light"
                        outlined
                    >
                        {{ error }}
                    </v-alert>
                </v-col>
            </v-row>
            <template v-if="!isLoading && employees">
                <template v-if="employees.issues.division && employees.issues.division.length">
                    <div class="text-h5 font-weight-light mt-6 warning--text">
                        <v-icon color="warning" left>mdi-alert-outline</v-icon>
                        Unknown division for {{ pluralize('Employee', employees.issues.division.length) }}
                    </div>
                    <EmployeeDataTable
                        v-model="fixed.division"
                        :headers="headers"
                        :items="employees.issues.division"
                        :division-options="employees.division_list"
                        :hide-columns="['comm_groups']"
                        disable-selection
                    />
                    <div class="text-center mt-6" v-if="fixed.division && fixed.division.length">
                        
                        <v-btn
                            color="primary"
                            @click="fixDivisionIssue"
                        >Fix {{ pluralize('Employee', fixed.division.length) }}</v-btn>
                    </div>
                </template>
                <div v-show="employees.items.new.length">
                    <div class="text-h5 font-weight-light mt-6">
                        <v-icon left>mdi-account-plus-outline</v-icon>
                        Add {{ pluralize('Employee', employees.items.new.length) }}
                    </div>
                    <div class="grey--text text--darken-2 caption text-uppercase"><v-icon small left>check_box</v-icon>rows will be imported</div>
                    <EmployeeDataTable
                        v-model="selected.new"
                        :headers="headers"
                        :items="employees.items.new"
                    />
                </div>
                <div v-show="employees.items.update.length">
                    <div class="text-h5 mt-6 font-weight-light">
                        <v-icon left>mdi-account-edit-outline</v-icon>
                        Update {{ pluralize('Employee', employees.items.update.length) }}
                    </div>
                    <div class="grey--text text--darken-2 caption text-uppercase"><v-icon small left>check_box</v-icon>rows will be updated from import</div>
                    <EmployeeDataTable
                        v-model="selected.update"
                        :headers="headers"
                        :items="employees.items.update"
                    />
                </div>
                <div v-show="employees.items.conflict.length">
                    <div class="text-h5 mt-6 font-weight-light">
                        <v-icon left>mdi-account-alert-outline</v-icon>
                        Update {{ pluralize('Employee', employees.items.conflict.length) }} (conflicts)
                    </div>
                    <div class="grey--text text--darken-2 caption text-uppercase"><v-icon small left>check_box</v-icon>rows will be updated from import</div>
                    <EmployeeDataTable
                        v-model="selected.conflict"
                        :headers="headers"
                        :items="employees.items.conflict"
                    />
                </div>
                <div v-show="employees.items.skip.length">
                    <div class="text-h5 mt-6 font-weight-light grey--text" style="cursor: pointer" @click="showSkipped = !showSkipped">
                        Skipping {{ pluralize('Employee', employees.items.skip.length) }} (no changes)
                        <v-btn
                            icon
                            color="grey"
                        >
                            <v-icon v-text="showSkipped ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
                        </v-btn>
                    </div>
                    <div v-show="showSkipped">
                        <EmployeeDataTable
                            :headers="headers"
                            :items="employees.items.skip"
                            :show-select="false"
                        />
                    </div>
                </div>
                <div class="text-center mt-8 mb-8" v-show="importLabel">
                    <v-btn
                        color="primary"
                        v-text="importLabel"
                        :disabled="submitLoading"
                        :loading="submitLoading"
                        @click="submitImport"
                        large
                    />
                </div>
            </template>
            <template v-else-if="!isLoading && completed">
                <div v-show="completed.add && completed.add.length">
                    <div class="text-h5 mt-6 font-weight-light success--text"><v-icon color="success" left>mdi-check-circle-outline</v-icon>{{ pluralize('Employee', completed.add.length) }} Added</div>
                    <EmployeeDataTable
                        :headers="headers"
                        :items="completed.add"
                        :show-select="false"
                    />
                </div>
                <div v-show="completed.update && completed.update.length">
                    <div class="text-h5 mt-6 font-weight-light success--text"><v-icon color="success" left>mdi-check-circle-outline</v-icon>{{ pluralize('Employee', completed.update.length) }} Updated</div>
                    <EmployeeDataTable
                        :headers="headers"
                        :items="completed.update"
                        :show-select="false"
                    />
                </div>
                <div class="text-center ma-8">
                    <v-btn
                        class="text-center"
                        large
                        @click="reset(true)"
                    >Done</v-btn>
                </div>
            </template>
        </v-container>
    </v-main>
</template>
<script>
import axios from 'axios';
import { ref, reactive, computed } from '@vue/composition-api';

import EmployeeDataTable from '@/components/population/admin/importer/EmployeeDataTable';
import stringHelper from '@/composables/common/string';

export default {
    components: {
        EmployeeDataTable
    },
    setup(_, { root }) {

        const initialSelected = {
            new: [],
            update: [],
            conflict: [],
        };

        let loading = ref(false);

        const submitLoading = ref(false);
        const employees = ref(null);
        const headers = ref([]);
        const selected = reactive({...initialSelected});
        const fixed = ref({});
        const completed = ref(null);
        const showSkipped = ref(false);
        const error = ref(null);

        const { pluralize } = stringHelper();

        const importLabel = computed(() => {
            let label = [];

            const lengths = {
                new: selected.new.length,
                update: selected.update.length + selected.conflict.length
            };

            const totalLength = lengths.new + lengths.update;

            if (!totalLength) return false;

            if (lengths.new) label.push('Add');
            if (lengths.update) label.push('Update');

            label = label.join(' / ');

            return `${label} ${pluralize('Employee', totalLength)}`;
        });

        const isLoading = computed(() => loading.value || submitLoading.value);

        const reset = (done=false) => {
            employees.value = null;
            Object.assign(selected, initialSelected);
            fixed.value = {};
            showSkipped.value = false;
            error.value = null;
            if (done) completed.value = null;
        };

        const handleAttachment = async (event) => {
            if (event) {
                reset(true);
                
                loading.value = true;

                let formData = new FormData();

                formData.append('export', event, event.name);

                const response = await axios.post('/population/admin/employee_import/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response.data.error) {
                    error.value = response.data.error;
                    loading.value = false;
                    return;
                }

                employees.value = response.data;
                headers.value = response.data.headers;

                // set selected for new and update
                selected.new = employees.value.items.new;
                selected.update = employees.value.items.update;

                loading.value = false;

            }
            
        };

        const submitImport = async () => {
            submitLoading.value = true;

            // scroll to top
            await root.$nextTick();
            root.$vuetify.goTo(0);

            const response = await axios.post('/population/admin/employee_import_submit/', {
                selected: selected
            });

            reset();

            completed.value = response.data;
            submitLoading.value = false;

            
        };

        const fixDivisionIssue = async () => {
            let fixedDivisions = fixed.value.division;

            // send the fixed divisions to server so they can be populated with the correct comm groups
            const response = await axios.post('/population/admin/get_employee_comm_groups/', fixedDivisions);

            fixedDivisions = response.data;

            let removeUnis = [];

            fixedDivisions.forEach((e) => {
                // add to where it needs to go
                employees.value.items[e.action] = employees.value.items[e.action] || [];
                employees.value.items[e.action].push(e);
                selected[e.action] = selected[e.action] || [];
                if (!selected[e.action].some(x => x.cu_uni === e.cu_uni)) selected[e.action].push(e);
                removeUnis.push(e.cu_uni);
            });

            // remove from division issues list
            employees.value.issues.division = employees.value.issues.division.filter(x => !removeUnis.includes(x.cu_uni));

            // remove from division fixed list
            fixed.value.division = fixed.value.division.filter(x => !removeUnis.includes(x.cu_uni));
        };

        return {
            submitLoading,
            employees,
            headers,
            selected,
            fixed,
            completed,
            showSkipped,
            error,
            importLabel,
            isLoading,
            reset,
            handleAttachment,
            submitImport,
            fixDivisionIssue,
            pluralize
        }
    }
}
</script>