import { VAlert } from 'vuetify/lib/components/VAlert';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading && _vm.hasIssues)?_c(VContainer,_vm._l((_vm.set),function(values,name,i){return _c(VRow,{key:i,staticClass:"mb-6"},[_c(VCol,{staticClass:"text-h5 grey--text text--darken-1 font-weight-light",domProps:{"textContent":_vm._s(name)}}),_c(VCol,{staticClass:"ma-0 pt-0",attrs:{"cols":"12"}},[_c(VDataTable,{staticClass:"elevation-1 ma-0 pa-0",attrs:{"headers":_vm.portalView.list_fields.headers,"items":values,"search":_vm.searchQuery,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{class:{'hl-row': props.item.key === _vm.searchHighlight},attrs:{"id":props.item.key === _vm.searchHighlight ? 'hlme': false}},_vm._l((_vm.portalView.list_fields.headers),function(field,i2){return _c('td',{key:i2},[(field.value === 'status')?[_c(VTooltip,{attrs:{"top":"","open-delay":"750","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VChip,_vm._g({attrs:{"color":_vm.getHRStatusColor(props.item.status),"label":"","small":""},domProps:{"textContent":_vm._s(props.item.status)}},on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getStatusInfo(props.item))+" ")])])]:(field.value === 'actions')?[_c('PortalActions',{attrs:{"item":props.item,"actions":_vm.portalView.list_fields.actions,"filter":_vm.filter},on:{"viewIssue":_vm.viewIssue,"reload":function($event){return _vm.$emit('reload')}}})]:[_vm._v(" "+_vm._s(props.item[field.value])+" ")]],2)}),0)]}}],null,true)})],1)],1)}),1):(!_vm.loading && !_vm.hasIssues && _vm.portalView.name)?_c(VContainer,[_c(VAlert,{staticClass:"mt-6",attrs:{"outlined":"","type":"info"}},[_vm._v(" Could not find any "+_vm._s(_vm.portalView.name.toLowerCase())+" ")])],1):_c(VContainer,{staticStyle:{"height":"400px"}},[_c(VRow,{staticClass:"fill-height text-center",attrs:{"align-content":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"5"}},[_c('div',{staticClass:"text-h6 font-weight-light"},[_vm._v("Loading "+_vm._s(_vm.portalName.toLowerCase())+"...")]),_c(VProgressLinear,{attrs:{"indeterminate":"","rounded":"","height":"6"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }