import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline justify-center"},[_vm._v(" Is this a promotion or transfer? ")]),_c(VCardText,[_c(VContainer,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"solo":"","label":"Promotion or Transfer note","hide-details":""},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)],1)],1)],1),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.$emit('setRecruitType', {recruitType: 'promotion', notes: _vm.notes})}}},[_vm._v(" Promotion ")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.$emit('setRecruitType', {recruitType: 'transfer', notes: _vm.notes})}}},[_vm._v(" Transfer ")]),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.$emit('setRecruitType', {recruitType: 'neither', notes: _vm.notes})}}},[_vm._v(" Neither ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }