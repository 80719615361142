import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"bottom":"","open-delay":"750"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red lighten-2"}},'v-icon',attrs,false),Object.assign({}, tooltip, dialog)),[_vm._v("mdi-close-circle")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.tooltipText)}})])]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Cancel Request")]),_c(VCardText,[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_vm._v(" Are you sure you want to cancel "),_c('strong',{domProps:{"textContent":_vm._s(_vm.item.key)}}),_vm._v("? "),_c(VTextarea,{attrs:{"rules":_vm.$rules.required,"label":"Cancellation notes"},model:{value:(_vm.cancelNotes),callback:function ($$v) {_vm.cancelNotes=$$v},expression:"cancelNotes"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("Close")]),_c(VBtn,{attrs:{"color":"red darken-1","loading":_vm.loading,"disabled":!_vm.formValid || _vm.loading,"text":""},on:{"click":_vm.submitCancel}},[_vm._v("Cancel "+_vm._s(_vm.item.key))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }