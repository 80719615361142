<template>
    <v-dialog
        v-model="showDialog"
        max-width="600px"
        persistent
    >
        <template v-slot:activator="{ on: dialog, attrs }">
            <v-menu offset-y>
                <template v-slot:activator="{on: menu}">

                    <v-tooltip bottom open-delay="750">
                        <template v-slot:activator="{on: tooltip}">
                            <v-icon
                                class="mr-2"
                                v-bind="attrs"
                                v-on="{...tooltip, ...menu}"
                                @click="loadTransitions(item)"
                            >mdi-arrow-right-circle</v-icon>
                        </template>
                        <span v-text="tooltipText" />
                    </v-tooltip>
                </template>
                <v-list dense>
                    <v-list-item-group v-if="loadingTransitions">
                        <v-list-item
                            inactive
                            :ripple="false"
                        >
                            <v-list-item-title>
                                <span class="caption">Loading...</span>
                                <v-progress-linear
                                    indeterminate
                                    color="blue"
                                ></v-progress-linear>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                    <v-list-item-group v-else>
                        <template v-if="transitions.length">
                            <v-list-item
                                v-for="(t, i) in transitions"
                                :key="i"
                                v-on="dialog"
                                @click.stop="setTransition(t)"
                            >
                                <v-list-item-title>{{ t.name }}</v-list-item-title>
                            </v-list-item>
                        </template>
                        <template v-else>
                            <v-list-item
                                inactive
                                disabled
                                :ripple="false"
                            >
                                <v-list-item-title>No available transitions</v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </template>

        <v-card>
            <v-card-title class="headline">Transition Issue</v-card-title>

            <v-card-text>
                Are you sure you want to transition <strong v-text="item.key" />?
                <v-divider class="mt-4" />
                <v-container fill-height>
                    <v-row align="start">
                        <v-col style="text-align: center">
                            <v-chip
                                small
                                label
                                v-text="item.status"
                            />
                        </v-col>
                        <v-col style="text-align: center">
                            <v-icon>mdi-arrow-right</v-icon><br>
                            <span v-text="transitionToDo.name" class="caption" />
                        </v-col>
                        <v-col align="start" style="text-align: center" v-if="transitionToDo.to">
                            <v-chip
                                :color="getHRStatusColor(transitionToDo.to.name)"
                                label
                                v-text="transitionToDo.to.name"
                                small
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="showDialog = false">Close</v-btn>
                <v-btn
                    color="blue darken-1"
                    :loading="loading"
                    :disabled="loading"
                    @click="doTransition"
                    text
                >Transition</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapGetters, mapActions } from "vuex";
import { getHRStatusColor } from '@/util/common';


export default {
    name: "JiraTransitionDialog",
    props: {
        item: {
            type: Object
        },
        tooltipText: {
            type: String,
            default: 'Transition ticket'
        }
    },
    data() {
        return {
            showDialog: false,
            loading: false,
            loadingTransitions: true,
            transitions: [],
            transitionToDo: {}
        };
    },
    computed: {
        ...mapGetters({}),
    },
    methods: {
        ...mapActions({}),
        async loadTransitions(item) {
            this.loadingTransitions = true;
            const response = await axios.get(`/jira/get_issue_transitions/${item.key}/`);
            this.transitions = response.data.transitions;
            this.loadingTransitions = false;
        },
        setTransition(item) {
            this.transitionToDo = item;
        },
        async doTransition() {
            this.loading = true;
            const response = await axios.post(`/jira/transition_issue/${this.item.key}/`, this.transitionToDo);
            // TODO: better error trapping?
            if (response.data.success) {
                this.$emit('reload');
                this.showDialog = false;
                this.loading = false;
                this.$store.commit('setSuccessSnack', `Successfully transitioned ${this.item.key}`);
            }
        },
        getHRStatusColor: getHRStatusColor
    },
};
</script>