<template>
    <v-list dense class="pt-0">
        <v-subheader
            v-if="showTitle"
            class="overline"
            v-text="attachmentTitle"
        />
        <v-list-item-group>
            <template v-if="filteredAttachments">
                <v-list-item
                    v-for="(att, i) in filteredAttachments"
                    :key="i"
                    :ripple="false"
                    >
                    <v-list-item-icon>
                        <v-icon
                            @dblclick="getAttachment(att)"
                            v-text="attachmentIcon(att.filename)"
                        />
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title v-text="att.filename" @dblclick="getAttachment(att)"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-row no-gutters>
                            <v-col v-if="loadingAttachment === att.id">
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    size="20"
                                    width="2"
                                ></v-progress-circular>
                            </v-col>
                            <v-col v-else>
                                <v-menu
                                    v-if="attachmentTypeList.length > 1"
                                    v-model="att.menu"
                                    :key="att.id"
                                    absolute
                                    offset-y
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-chip small v-on="on">{{ getFileAttachmentType(att.filename) }}</v-chip>
                                    </template>
                                    <v-list dense>
                                        <v-list-item
                                            v-for="(attType, t) in attachmentTypeList.filter(x => x[1].attachment_name !== getFileAttachmentType(att.filename))"
                                            :key="t"
                                            @click="changeAttachmentType(att, attType)">
                                                <v-list-item-title v-text="attType[1].attachment_name" />
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                            <v-col class="d-flex align-center">
                                <v-icon
                                    v-if="config.attachmentWarning !== att.id"
                                    class="ml-2"
                                    color="red"
                                    @click="config.attachmentWarning = att.id"
                                    left
                                >mdi-close-circle</v-icon>
                                <div
                                    :class="config.attachmentWarning === att.id ? 'd-flex': 'd-none'"
                                >
                                    <v-btn
                                        class="mr-1"
                                        color="red white--text"
                                        :loading="config.loading"
                                        :disabled="config.loading"
                                        @click="deleteAttachment(att)"
                                        x-small
                                    >Delete</v-btn>
                                    <v-btn
                                        x-small
                                        @click="config.attachmentWarning = null"
                                    >Cancel</v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-list-item-action>
                </v-list-item>
            </template>
            <template v-else>
                <v-list-item>
                    <v-list-item-content>
                        There are no files attached to this request
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list-item-group>
    </v-list>
</template>
<script>
import axios from 'axios';

import dateTimeHelper from '@/composables/common/dateTime';
import attachmentHelper from '@/composables/common/attachment';

import { reactive, computed, ref } from '@vue/composition-api';

export default {
    name: 'AttachmentList',
    props: {
        attachments: {
            type: Array,
            default() { return [] }
        },
        filterFiles: {
            type: RegExp,
            default() {
                return /^image.*\.[jpg|gif|png]+/i;
            }
        },
        item: {
            type: Object,
            default() { return {} }
        },
        attachmentTypes: {
            type: Object,
            default() { return {} }
        },
        isValidPending: {
            type: Boolean,
            default: true
        },
        showTitle: {
            type: Boolean,
            default: true
        }
    },
    emits:['changeAttachmentType', 'deleteAttachment', 'reload'],
    components: {},
    setup(props, { root, emit }){
        let store = root.$store;
        //  Data
        const config = reactive({
            loading: false,
            attachmentWarning: null,
            loadingAttachment: null,
        });

        const comment = ref('');
        const loadingAttachment = ref(null);

        const { attachmentIcon } = attachmentHelper();

        //  Computed
        const filteredAttachments = computed(() => {
            return props.attachments.filter(x => !props.filterFiles.test(x.filename));
        });

        const attachmentTypeList = computed(() => {
            let list = [[null, {'attachment_name': 'Uncategorized'}]];
            if (Object.entries(props.attachmentTypes || {}).length) {
                list = list.concat(Object.entries(props.attachmentTypes));
            }
            return list;
        });

        const attachmentTitle = computed(() => {
            if (props.attachments.length) {
                return `${props.attachments.length} ${props.attachments.length === 1 ? 'Attachment' : 'Attachments'}`;
            } else {
                return 'Attachments'
            }
        });   
        

        //  Methods
        const getAttachmentTypes = (returnKey=false) => {
            if (Object.entries(props.attachmentTypes || {}).length) {
                return Object.entries(props.attachmentTypes)[!returnKey ? 'flatMap' : 'map'](x => x[1].attachment_name ?  (!returnKey ? x[1] : x) : []);
            }
            return [];
        }
        
        const getFileAttachmentType = (file, returnName=true, returnKey=false) => {
            for (let ft of getAttachmentTypes(returnKey)) {
                const ftt = !returnKey ? ft : ft[1];
                if (RegExp(ftt.file_regex, 'i').test(file)) {
                    if (returnName) return ftt.attachment_name;
                    if (returnKey) return ft[0];
                }
            }
            return 'Uncategorized';
        }

        const { getDateDistanceIso } = dateTimeHelper();

        const getAttachment = async (att) => {
            const attachmentId = att.id;
            const response = await axios.get(`/jira/get_attachment/${attachmentId}/`, {responseType: 'blob'});
            if (!window.navigator.msSaveOrOpenBlob){
                const contentDisposition = response.headers['content-disposition'];
                
                const url = URL.createObjectURL(new Blob([response.data],{type:response.headers['content-type']}));
                const link = document.createElement('a');
                const fileName = contentDisposition.match(/filename=(?<filename>[^,;]+)/)[1];
                
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                alert('Fail: what version of IE are you using?');
            }
        }

        const deleteAttachment = async (att) => {
            config.loading = true;
            await changeAttachment(att);
            config.loading = false;
        }

        const changeAttachmentType = async (att, attType) => {
            loadingAttachment.value = att.id;
            delete att.menu;
            await changeAttachment(att, attType);
            loadingAttachment.value = null;
        }

        const changeAttachment = async (att, attType=false) => {
            const startValidPending = props.isValidPending;

            let postData = {
                isValidPending: props.isValidPending,
                extra: true
            };

            let response;

            if (attType) {
                // changeAttachmentType (request)
                response = await axios.post(`/jira/rename_attachment/${props.item.key}/${att.id}/`, Object.assign(postData, {
                    filename: att.filename,
                    file_prefix: attType[0] ? attType[1].file_prefix : null,
                    cat_id: attType[1].cat_id || null,
                    cat_name: attType[1].attachment_name || null
                }));
            } else {
                // deleteAttachment (request)
                response = await axios.post(`/jira/delete_attachment/${props.item.key}/${att.id}/`, postData);
            }

            if (response.data.error) {
                store.commit('setErrorSnack', response.data.message || 'Unknown Error');
            } else {
                if (attType) {
                    // changeAttachmentType (response)
                    att['filename'] = response.data.filename;
                    await emit('changeAttachmentType', {
                        att: att,
                        attachmentType: attType[0],
                        jiraAttachments: response.data.attachments
                    });
                } else {
                    // deleteAttachment (response)
                    await emit('deleteAttachment', {
                        jiraAttachments: response.data.attachments,
                        attachmentType: getFileAttachmentType(att.filename, false, true)
                    });
                }

                // common stuff
                await root.$nextTick();
                if (startValidPending !== props.isValidPending) {
                    // trigger another update because isValidPending changed
                    response = await axios.post(`/population/update_hrticketstat/${props.item.key}/`, {
                        isValidPending: props.isValidPending
                    });
                }

                if (response.data.reload) emit('reload');
            }
        }

        return {
            config,
            comment,
            loadingAttachment,
            filteredAttachments,
            attachmentTypeList,
            attachmentTitle,
            getDateDistanceIso,
            getAttachment,
            deleteAttachment,
            changeAttachmentType,
            getFileAttachmentType,
            changeAttachment,
            attachmentIcon
        }
    },
};
</script>