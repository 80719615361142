<template>
    <v-main>
        <v-container>
            <div class="text-h4">HR Stats</div>
            <div class="orange--text text--darken-3 mt-2 mb-2 text-h5 font-weight-light">New Requests</div>
            <v-row v-if="loading">
                <v-col>
                    <v-skeleton-loader
                        type="card-heading, paragraph"
                    ></v-skeleton-loader>
                </v-col>
                <v-col>
                    <v-skeleton-loader
                        type="card-heading, paragraph"
                    ></v-skeleton-loader>
                </v-col>
                <v-col>
                    <v-skeleton-loader
                        type="card-heading, paragraph"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-if="stat.new_hires">
                    <DayWeekMonthWidget title="New Hires" :stat="stat.new_hires.created" />
                </v-col>
                <v-col v-if="stat.new_candidates">
                    <DayWeekMonthWidget title="New Candidates" :stat="stat.new_candidates.saves" />
                </v-col>
                <v-col v-if="stat.terms">
                    <DayWeekMonthWidget title="Terms" :stat="stat.terms.created" />
                </v-col>
            </v-row>

            <div class="orange--text text--darken-3 mt-4 mb-2 text-h5 font-weight-light">Onboarding Times</div>
            <v-row v-if="loading">
                <v-col>
                    <v-skeleton-loader
                        type="card-heading, paragraph"
                    ></v-skeleton-loader>
                </v-col>
                <v-col>
                    <v-skeleton-loader
                        type="card-heading, paragraph"
                    ></v-skeleton-loader>
                </v-col>
                <v-col>
                    <v-skeleton-loader
                        type="card-heading, paragraph"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
            <v-row v-if="stat.onboarding">
                <v-col>
                    <OnboardingTimeWidget title="Officer" :stat="stat.onboarding.officer" />
                </v-col>
                <v-col>
                    <OnboardingTimeWidget title="Service Corp" :stat="stat.onboarding.serv_corp" />
                </v-col>
                <v-col>
                    <OnboardingTimeWidget title="Faculty &amp; Fellow" :stat="stat.onboarding.faculty_fellow" />
                </v-col>
            </v-row>

            <div class="orange--text text--darken-3 mt-4 mb-2 text-h5 font-weight-light">Other Stuff</div>
            <v-row v-if="loading">
                <v-col>
                    <v-skeleton-loader
                        type="card-heading, paragraph"
                    ></v-skeleton-loader>
                </v-col>
                <v-col>
                    <v-skeleton-loader
                        type="card-heading, paragraph"
                    ></v-skeleton-loader>
                </v-col>
                <v-col>
                    <v-skeleton-loader
                        type="card-heading, paragraph"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col>
                    <DayWeekMonthWidget title="Comments" :stat="stat.comments" :last="false" />
                </v-col>
                <v-col>
                    <DayWeekMonthWidget title="Attachments" :stat="stat.attachments" :last="false" />
                </v-col>
                <v-col>
                    <DayWeekMonthWidget title="Edits" :stat="stat.edits" :last="false" />
                </v-col>
            </v-row>
            <div class="orange--text text--darken-3 mt-4 mb-2 text-h5 font-weight-light">Top Users</div>
            <v-row v-if="loading">
                <v-col>
                    <v-skeleton-loader
                        type="paragraph"
                    />
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col>
                    <ApexChart
                        v-if="stat.users"
                        type="bar"
                        height="350"
                        :options="userChartOptions"
                        :series="stat.users.series"
                    />
                </v-col>
            </v-row>

            <div class="orange--text text--darken-3 mt-4 mb-2 text-h5 font-weight-light">Jira Conversion (Last 90 Days)</div>
            <v-row v-if="loading">
                <v-col><v-skeleton-loader type="paragraph" /></v-col>
                <v-col><v-skeleton-loader type="paragraph" /></v-col>
                <v-col><v-skeleton-loader type="paragraph" /></v-col>
            </v-row>
            <v-row class="mb-10" v-else>
                <v-col>
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col class="text-center">
                                    <div class="text-h2">{{ stat.jira_convert.requests.total }}</div>
                                    <div class="overline grey--text">Requests</div>
                                </v-col>
                                <v-col class="text-center">
                                    <div class="text-h2">{{ Math.round((stat.jira_convert.requests.connect / stat.jira_convert.requests.total * 100) || 0) }}%</div>
                                    <div class="overline grey--text">Connect</div>
                                </v-col>
                                <v-col class="text-center">
                                    <div class="text-h2">{{ Math.round((stat.jira_convert.requests.jira / stat.jira_convert.requests.total * 100) || 0) }}%</div>
                                    <div class="overline grey--text">Jira</div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="text-center">
                                    <div class="text-h2">{{ stat.jira_convert.onboarding.total }}</div>
                                    <div class="overline grey--text">New Hires Onboarded</div>
                                </v-col>
                                <v-col class="text-center">
                                    <div class="text-h2">{{ Math.round((stat.jira_convert.onboarding.connect / stat.jira_convert.onboarding.total * 100) || 0) }}%</div>
                                    <div class="overline grey--text">w/ Connect</div>
                                </v-col>
                                <v-col class="text-center">
                                    <div class="text-h2">{{ Math.round((stat.jira_convert.onboarding.jira / stat.jira_convert.onboarding.total * 100) || 0) }}%</div>
                                    <div class="overline grey--text">w/o Connect</div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="text-center">
                                    <div class="text-h2">{{ stat.jira_convert.terms.total }}</div>
                                    <div class="overline grey--text">Terms</div>
                                </v-col>
                                <v-col class="text-center">
                                    <div class="text-h2">{{ Math.round((stat.jira_convert.terms.with_connect_term / stat.jira_convert.terms.total * 100) || 0) }}%</div>
                                    <div class="overline grey--text">w/ Connect</div>
                                </v-col>
                                <v-col class="text-center">
                                    <div class="text-h2">{{ Math.round((stat.jira_convert.terms.jira / stat.jira_convert.terms.total * 100) || 0) }}%</div>
                                    <div class="overline grey--text">w/o Connect</div>
                                </v-col>
                                <!--
                                <v-col class="text-center">
                                    <div class="text-h2">{{ Math.round((stat.jira_convert.terms.with_connect_term / stat.jira_convert.terms.total * 100) || 0) }}%</div>
                                    <div class="overline grey--text">w/ Offboarding Tasks</div>
                                </v-col>
                                -->
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <ApexChart
                v-if="stat.jira_convert"
                type="bar"
                height="350"
                class="mt-5"
                :options="typeChartOptions"
                :series="stat.jira_convert.types.series"
            />
        </v-container>
    </v-main>
</template>
<script>
import { ref, computed, onMounted } from '@vue/composition-api';
import axios from 'axios';
import DayWeekMonthWidget from '@/components/population/admin/stats/DayWeekMonthWidget';
import OnboardingTimeWidget from '@/components/population/admin/stats/OnboardingTimeWidget';

export default {
    components: {
        DayWeekMonthWidget,
        OnboardingTimeWidget
    },
    setup() {
        onMounted(async () => {
            const response = await axios.get(`/population/admin/load_stats/`);
        
            if (typeof response.data.new_hires.created.last == 'string') {
                response.data.new_hires.created.last = JSON.parse(response.data.new_hires.created.last);
            }

            if (typeof response.data.terms.created.last == 'string') {
                response.data.terms.created.last = JSON.parse(response.data.terms.created.last);
            }
            stat.value = response.data;
            loading.value = false;
        });

        const stat = ref({});
        const loading = ref(true);

        const stackedBarChartOptions = {
            chart: {
                type: 'bar',
                stacked: true,
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: true
                }
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            yaxis: {
                title: {
                    text: undefined
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'top',
                horizontalAlign: 'center',
                //offsetX: 40
            }
        };

        const userChartOptions = computed(() => {
            const categories = stat.value.users ? stat.value.users.categories : [];
            return {
                ...stackedBarChartOptions,
                xaxis: {
                    categories: categories
                },
            }
        });

        const typeChartOptions = computed(() => {
            const categories = stat.value.jira_convert ? stat.value.jira_convert.types.categories : [];
            return {
                ...stackedBarChartOptions,
                xaxis: {
                    categories: categories
                },
            }
        });

        return {
            stat,
            loading,
            userChartOptions,
            typeChartOptions
        };
    }
}
</script>