<template>
    <div>
        <v-alert
            v-if="attachmentIssues.length"
            dense
            outlined
            :type="type"
        >
            <strong class="font-weight-medium">The following attachments are required before this request can be submitted:</strong>
            <ul>
                <li v-for="(attIssue, i) in attachmentIssues" :key="i">
                    <span :class="{'text-decoration-line-through': pendingAttachments.some(x => x.type.attachment_name === attIssue.name)}">{{ attIssue.name }}</span>
                </li>
            </ul>
        </v-alert>
        <v-alert
            v-else-if="attachmentIssues.length === 0 && Object.keys(attachmentTypes).length"
            dense
            type="success"
            outlined
        >
            All required attachments have been uploaded
        </v-alert>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "AttachmentAlert",
    props: {
        type: {
            type: String,
            default: "warning"
        },
        conditionalModelName: {
            type: String,
            default: 'newHireRequest'
        },
        conditionalModel: {
            type: Object,
            default() { return this.newHireRequest }
        },
        conditionalOnly: {
            type: Boolean,
            default: false
        },
        attachmentTypes: {
            type: Object,
            default() { return {} }
        },
        attachments: {
            type: Array,
            default() { return [] }
        },
        pendingAttachments: {
            type: Array,
            default() { return [] }
        },
        hideOnValid: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            attachmentIssues: [],
            watchers: {}
        };
    },
    computed: {
        ...mapGetters({
            authorizedDivisionList: 'population/getAuthorizedDivisionList',
            employeeTypes: 'population/getEmployeeTypes',
            newHireRequest: 'population/getNewHireRequest'
        }),
        isValid() {
            return !this.attachmentIssues.length;
        },
        isValidPending() {
            return !this.attachmentIssues.some(x => !this.pendingAttachments.some(y => y.type.attachment_name === x.name));
        }
    },
    watch: {
        attachmentTypes() {
            this.validate();
        },
        pendingAttachments() {
            this.validate();
        },
        isValidPending() {
            this.$emit('validityChange', this.isValidPending, this.attachmentIssues);
        }
    },
    methods: {
        validate() {

            const typeList = Object.entries(this.attachmentTypes);
            let issues = [];
            for (const attTypeEntry in typeList) {
                let attType = typeList[attTypeEntry];
                let attTypeVal = attType[1];
                let conditionalModel = this.conditionalModel || this.newHireRequest;

                if (attTypeVal.required === true && !this.conditionalOnly) {
                    // check if file exists in attachments list
                    if (!this.attachments.some(x => RegExp(attTypeVal.file_regex, 'i').test(x.filename))) {
                        issues.push({
                            key: attType[0],
                            name: attTypeVal.attachment_name
                        });
                    }
                    continue;
                } else if (typeof(attTypeVal.required || false) === 'object') {
                    
                    let conditional = attTypeVal.required.conditional || {};
                    let field_name = conditional.field_name.split('-');

                    // check if this attachment is not required based on a field
                    let hide = conditional.hide || {}
                    
                    if (Object.keys(hide).length !== 0) {
                        let hFieldName = hide.field_name.split('-')
                        if (hFieldName.length > 1) {
                            if (conditionalModel && conditionalModel[field_name[0]][field_name[1]] == hide.field_value) {
                                this.watchers[hide.field_name] = this.$watch(`${this.conditionalModelName}.${hFieldName[0]}.${hFieldName[1]}`, function() {
                                    this.validate();
                                });
                                continue;
                            }
                        } else {
                            if (conditionalModel && conditionalModel[hide.field_name] == hide.field_value) {
                                this.watchers[hide.field_name] = this.$watch(`${this.conditionalModelName}.${hide.field_name}`, function() {
                                    this.validate();
                                });
                                continue;
                            }
                        }
                    }

                    if (field_name.length > 1){
                        if (conditionalModel && conditionalModel[field_name[0]][field_name[1]] == conditional.field_value) {
                            if (!this.attachments.some(x => RegExp(attTypeVal.file_regex, 'i').test(x.filename))) {
                                issues.push({
                                    key: attType[0],
                                    name: attTypeVal.attachment_name
                                });
                            }
                            
                            // add a watcher for subsequent updates
                            this.watchers[conditional.field_name] = this.$watch(`${this.conditionalModelName}.${field_name[0]}.${field_name[1]}`, function() {
                                this.validate();
                            });
                        }
                    } else {
                        if (conditionalModel && conditionalModel[conditional.field_name] == conditional.field_value) {
                            if (!this.attachments.some(x => RegExp(attTypeVal.file_regex, 'i').test(x.filename))) {
                                issues.push({
                                    key: attType[0],
                                    name: attTypeVal.attachment_name
                                });
                            }
                            
                            // add a watcher for subsequent updates
                            this.watchers[conditional.field_name] = this.$watch(`${this.conditionalModelName}.${conditional.field_name}`, function() {
                                this.validate();
                            });
                        }
                    }
                }
            }
            this.attachmentIssues = issues;
        },
    },
    mounted() {
        this.validate();
    }
};
</script>