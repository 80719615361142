<template>
    <v-form
        ref="form"
        lazy-validation
        v-model="formValid"
    >
        <v-container>
            <v-select
                v-model="newTermRequest.termAccessAction"
                :items="accessOptions"
                label="Action"
            ></v-select>
        </v-container>
    </v-form>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "SysAccessForm",
    data() {
        return {
            loading: false,
            accessOptions: ['Terminate All Access', 'Retain All Access', 'Retain Partial Access'],
            formValid: true
        };
    },
    components: {
    },
    computed: {
        ...mapGetters({
            newTermRequest: 'population/getNewTermRequest',
        })
    },
    methods: {
        ...mapActions({
        }),
    },
    watch:{
        formValid(val){
            this.$emit('validityChange', val);
        }
    }
};
</script>