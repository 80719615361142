<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title class="headline justify-center">
        Is this a promotion or transfer?
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row no-gutters>
              <v-col cols="12">
                  
                  <v-textarea
                      v-model="notes"
                      solo
                      label="Promotion or Transfer note"
                      hide-details
                  ></v-textarea>
              </v-col>
          </v-row>
          </v-container>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="green darken-1"
          text
          @click="$emit('setRecruitType', {recruitType: 'promotion', notes: notes})"
        >
          Promotion
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="$emit('setRecruitType', {recruitType: 'transfer', notes: notes})"
        >
          Transfer
        </v-btn>
        <v-btn
          color="red darken-1"
          text
          @click="$emit('setRecruitType', {recruitType: 'neither', notes: notes})"
        >
          Neither
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
    name: "RecruitTypeDialog",
    props: {
      value: Boolean,
    },
    data() {
      return {
        notes: ''
      };
    },
    computed: {
      show: {
        get () {
          return this.value;
        }
      }
    },
};
</script>