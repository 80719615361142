<template>
	<v-dialog v-model="show" persistent max-width="800px">
		<v-card>
			<v-card-title>
				<h3 v-if="newTermRequest.cuUni">
					Termination Summary for {{ newTermRequest.firstName }} {{ newTermRequest.lastName }} ({{ newTermRequest.cuUni.toUpperCase() }})
				</h3>
			</v-card-title>
			<v-card-subtitle class="pt-2">
				<v-chip label class="mr-2">
					{{ termType }}
				</v-chip>
				<v-chip label>
					{{ newTermRequest.typeOfEmployee }}
				</v-chip>
			</v-card-subtitle>
			<v-card-text>
				<v-row>
					<v-col v-if="newTermRequest.termDate">
						<div class="overline"><v-icon class="mr-2">mdi-calendar</v-icon>Term Date</div>
						<div class="ml-8">
							<strong>{{ format(parseISO(newTermRequest.termDate), 'MMM do yyyy') }}</strong>
							<small style="display: block" class="grey--text mt-n1">
								{{ formatDistanceToNow(parseISO(newTermRequest.termDate)) }} {{ tense }}
							</small>
						</div>
					</v-col>
					<v-col v-if="newTermRequest.termAccessAction">
						<div class="overline"><v-icon class="mr-2">mdi-application</v-icon>System Access</div>
						<div class="ml-8"><strong>{{ newTermRequest.termAccessAction }}</strong></div>
					</v-col>
				</v-row>
				<v-divider class="mb-6 mt-4" v-if="showDivider"></v-divider>
				<template v-if="Object.keys(newTermRequest.workAssets).length > 0">
					<h3>Work Assets</h3>
					<v-row>
						<v-col v-for="item in newTermRequest.workAssets" :key="item.name" md="12" lg="6">
							<v-list-item> 
								<v-list-item-icon>
									<v-icon>{{ assetIcon(item) }}</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>
										{{ item.name }}
									</v-list-item-title>
									<v-list-item-subtitle>
										{{ item.spec }}
									</v-list-item-subtitle>
									<div class="text-truncate">
										<strong class="orange--text text--accent-4">{{ dispositionText(item.disposition) }}</strong>
									</div>
								</v-list-item-content>
							</v-list-item>
						</v-col>
					</v-row>
				</template>
					<template v-if="newTermRequest.pendingAttachments.length">
						<h3 class="mt-3">Attachments Pending Upload</h3>
						<v-row>
							<v-col v-for="att in newTermRequest.pendingAttachments" :key="att.id" md="6" lg="6">
								<div class="overline">
									<v-icon class="mr-2">mdi-paperclip</v-icon>{{ att.type.attachment_name }}
								</div>
								<div class="ml-8 text-truncate"><strong>{{ att.filename }}</strong></div>
							</v-col>
						</v-row>
				</template>
				<template v-if="newTermRequest.attachments && newTermRequest.attachments.jira">
					<h3>Attachments Uploaded</h3>
					<v-row>
						<v-col v-for="att in newTermRequest.attachments.jira" :key="att.id" md="6" lg="6">
							<div class="text-truncate">
								<v-icon class="mr-2">mdi-paperclip</v-icon>
								<strong>{{ att.filename }}</strong>
							</div>
							<div class="ml-8">
								<small style="display: block" class="grey--text mt-n1">
									{{ formatDistanceToNow(parseISO(att.created)) }} ago
								</small>
							</div>
						</v-col>
					</v-row>
				</template>
				<template v-if="newTermRequest.notes">
					<h3 class="mt-2">Notes</h3>
					<blockquote class="blockquote">
						<p style="font-size: 1rem">{{ newTermRequest.notes }}</p>
					</blockquote>
				</template>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" text @click.stop="show=false">Back</v-btn>
				<v-btn 
					color="primary" 
					text 
					:loading="loading"
					:disabled="loading"
					@click.stop="$emit('submitTerm')"
				>{{ action }}</v-btn>
			</v-card-actions>
		</v-card>
    </v-dialog>
</template>
<script>
    import { mapGetters } from 'vuex';
    import { formatDistanceToNow, parseISO, format } from 'date-fns';
	
	export default {
		name: 'TermSummary',
		props: {
			value: Boolean,
			action: String
		},
		data() {
			return {
				loading: false
			}
		},
		computed: {
			...mapGetters({
				newTermRequest: 'population/getNewTermRequest',
			}),
			show: {
				get () {
					return this.value;
				},
				set (value) {
					this.$emit('cancelSummary', value);
				}
			},
			termType(){
				if (!this.newTermRequest.termTransferType) return;
				const type = this.termTransferOptions.find(x => x.value === this.newTermRequest.termTransferType).text;
				switch (this.newTermRequest.termTransferType) {
					case 'dept':
						return `Transfer from ${this.newTermRequest.division.name} to ${this.newTermRequest.toDivision.name}`;
					case 'org':
						return `Transfer to ${this.newTermRequest.toDepartment.name}`;
					default: 
						return `${type}`;
				}
			},
			showDivider(){
				if (Object.keys(this.newTermRequest.workAssets).length > 0 || this.newTermRequest.attachments.att1 != undefined || this.newTermRequest.notes){
					return true;
				}
				return false;
			},
			tense(){
				if (new Date(this.newTermRequest.termDate) > new Date()){
					return "from today"
				}else{
					return "ago"
				}
			}
		},
		methods: {
			parseISO: parseISO,
			formatDistanceToNow: formatDistanceToNow,
			format: format,
			assetIcon(item){
				let text = ''
				switch (item.type){
					case 'Computer':
						text = 'mdi-desktop-tower-monitor'
						break;
					case 'Printer / MFP':
						text = 'mdi-printer'
						break;
				}
				return text;
			},
			dispositionText(val){
				switch (val){
					case 'employeeKeep':
						return 'Employee will keep';
					case 'discard':
						return 'Discard / Retire';
					case 'reassign':
						return 'Reassign';
				}
			},
			toggleLoading(){
				this.loading = !this.loading;
			}
		},


}
</script>